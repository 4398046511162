import React, {useEffect, useState} from "react";
import {
    MinusCircleOutlined,
    PlusOutlined,
    ExclamationCircleOutlined,
} from "@ant-design/icons";
import {Button, Card, Form, Flex, Modal, Select, Row, Col, InputNumber, Checkbox, Switch, Input} from "antd";

import "./UraItemForm.css";
import {axiosInstance} from "../../../api/axiosInstance";
import {toast} from "react-toastify";
import {validate} from "uuid";

const onFinish = (values) => {
};

const UraItemForm = ({
                         form,
                         initialValues,
                         projects,
                         expenseSubTypes,
                         mode,
                         handleFieldsChange,
                         uraItemFormErrors,
                         setUraItemFormErrors,
                     }) => {
    const {confirm} = Modal;

    const [showTaxDeductibleItems, setShowTaxDeductibleItems] = useState(new Map());

    const destroyAll = () => {
        Modal.destroyAll();
    };

    const validateFields = () => {
        try {
            form.validateFields();
        } catch (ex) {
            setUraItemFormErrors(ex.errorFields);
            console.log(ex.errorFields);
        }
    };

    useEffect(() => {
        console.log(initialValues, 'initi')
        form.setFieldsValue(initialValues);
        let map = new Map();
        initialValues.items?.forEach((item, index) => {
            if (item?.taxDeductibleItem) {
                map.set(index, item.taxDeductibleItem);
            }
        })
        setShowTaxDeductibleItems(map);
    }, [initialValues])

    useEffect(() => {
        if(mode === 'insert'){
            console.log(initialValues, 'expenseSubTypes changed')
            let map = new Map();
            setShowTaxDeductibleItems(map);
        }
    }, [expenseSubTypes])

    const showConfirm = (remove, fieldName, fields) => {
        let mapCopy = new Map(showTaxDeductibleItems);
        mapCopy.delete(fieldName)

        const itemId =
            form.getFieldValue("items")[fields[fieldName]?.name]?.itemId;
        confirm({
            icon: <ExclamationCircleOutlined/>,
            centered: true,
            title: "Upozorenje",
            content: "Jeste li sigurni da želite obrisati stavku računa?",
            okText: "Potvrdi",
            cancelText: "Odustani",
            onOk() {
                if (itemId) {
                    axiosInstance
                        .delete(`/api/v1/ura/item/${itemId}`)
                        .then((res) => {
                            remove(fieldName);
                            setShowTaxDeductibleItems(mapCopy);
                        })
                        .catch((err) => {
                            toast.error("Dogodila se greška prilikom brisanja polja.");
                        });
                } else {
                    remove(fieldName);
                    setShowTaxDeductibleItems(mapCopy);
                }
            },
            onCancel() {
            },
        });
    };

    const ellipsisStyle = {
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        maxWidth: "100%",
    };
    const twoPointDecimal = (number) => Number(number).toFixed(2);

    const Project = ({name, restField}) => {
        const errorMessage = uraItemFormErrors.filter(x => x.name[1] === name && x.name[2] === 'projectId')[0]?.errors[0]
        return (
            <Form.Item
                validateStatus={errorMessage ? "error" : ""}
                help={errorMessage ? errorMessage : ''}
                label={`Posao:`}
                {...restField}
                name={[name, "projectId"]}
                rules={[
                    {
                        required: false,
                        message: "Projekt je obavezno polje",
                    },
                ]}
            >
                <Select
                    showSearch
                    dropdownRender={(menu) => (
                        <div>
                            {React.cloneElement(menu, {
                                style: {...menu.props.style}, // Set the minimum width as needed
                            })}
                        </div>
                    )}
                    placeholder="Posao"
                    popupMatchSelectWidth={false}
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                        (option?.label ?? "").includes(input)
                    }
                    options={projects}
                    disabled={mode === "preview"}
                    onChange={() => {
                        validateFields();
                    }}
                />
            </Form.Item>
        );
    };

    const ExpenseSubType = ({name, restField}) => {
        const errorMessage = uraItemFormErrors.filter(x => x.name[1] === name && x.name[2] === 'expenseSubTypeId')[0]?.errors[0]
        return (
            <Form.Item
                validateStatus={errorMessage ? "error" : ""}
                help={errorMessage ? errorMessage : ''}
                label={`Podvrsta troška:`}
                {...restField}
                name={[name, "expenseSubTypeId"]}
                rules={[
                    {
                        required: false,
                        message: "Vrsta podtroška je obavezno polje",
                    },
                ]}
            >
                <Select
                    showSearch
                    dropdownRender={(menu) => (
                        <div>
                            {React.cloneElement(menu, {
                                style: {...menu.props.style}, // Set the minimum width as needed
                            })}
                        </div>
                    )}
                    placeholder="Podvrsta troška"
                    popupMatchSelectWidth={false}
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                        (option?.label ?? "").includes(input)
                    }
                    filterSort={(optionA, optionB) =>
                        (optionA?.label ?? "")
                            .toLowerCase()
                            .localeCompare((optionB?.label ?? "").toLowerCase())
                    }
                    options={expenseSubTypes}
                    disabled={mode === "preview"}
                    onChange={() => {
                        validateFields();
                    }}
                />
            </Form.Item>
        );
    };

    const PDV = ({name, restField}) => {
        const errorMessage = uraItemFormErrors.filter(x => x.name[1] === name && x.name[2] === 'pdv')[0]?.errors[0]
        return (<Form.Item
                name={[name, "pdv"]}
                {...restField}
                validateStatus={errorMessage ? "error" : ""}
                help={errorMessage ? errorMessage : ''}
                label={`PDV (%):`}
                rules={[
                    {
                        required: true,
                        message: "PDV je obavezno polje!",
                    },
                ]}
            >
                <InputNumber
                    onChange={(value) => {
                        form.setFieldValue(["items",name, 'pdv'], twoPointDecimal(value));
                    }}
                    precision={2}
                    step={0.1}
                    style={{width: "100%"}}
                    placeholder="PDV"
                />
            </Form.Item>
        );
    }

    const toggleMapCopy = (name) => {
        const mapCopy = new Map(showTaxDeductibleItems);
        const oldValue = mapCopy.get(name);
        if(!oldValue){
            mapCopy.set(name, true);
        } else {
            mapCopy.set(name, !oldValue);
        }
        setShowTaxDeductibleItems(mapCopy);
    }

    const TaxDeductibleItem = ({name, restField}) => {

        return (<Form.Item
                name={[name, "taxDeductibleItem"]}
                valuePropName={"checked"}
                label={"Porezno priznata stavka"}
            >
                <Switch onChange={() => {
                    toggleMapCopy(name)
                }}/>
            </Form.Item>
        );
    }

    const PriceWithoutPdv = ({name, restField}) => {
        const errorMessage = uraItemFormErrors.filter(x => x.name[1] === name && x.name[2] === 'priceWithoutPdv')[0]?.errors[0]
        return (<Form.Item
                name={[name, "priceWithoutPdv"]}
                {...restField}
                validateStatus={errorMessage ? "error" : ""}
                help={errorMessage ? errorMessage : ''}
                label={`Iznos (€):`}
                rules={[
                    {
                        required: true,
                        message: "Iznos je obavezno polje!",
                    },
                ]}
            >
                <InputNumber
                    onChange={(value) => {
                        form.setFieldsValue({
                            price: twoPointDecimal(value),
                        });
                    }}
                    precision={2}
                    step={0.1}
                    style={{width: "100%"}}
                    placeholder="Iznos"
                />
            </Form.Item>
        );
    }

    const generateFormRow = (key, leftColumn, rightColumn) => {
        return (
            <Row gutter={16} key={key}>
                {rightColumn ? (
                    <>
                        <Col span={12} xs={24} sm={12}>
                            {leftColumn}
                        </Col>
                        <Col span={12} xs={24} sm={12}>
                            {rightColumn}
                        </Col>
                    </>
                ) : (
                    <Col span={24}>{leftColumn}</Col>
                )}
            </Row>
        );
    };

    const projectField = (name, restField, errors) => (
        <Project name={name} restField={restField} errors={errors}/>
    );
    const expenseSubTypeField = (name, restField, errors) => (
        <ExpenseSubType name={name} restField={restField} errors={errors}/>
    );
    const pdvField = (name, restField, errors) => (
        <PDV name={name} restField={restField} errors={errors}/>
    );

    const priceField = (name, restField, errors) => (
        <PriceWithoutPdv name={name} restField={restField} errors={errors}/>
    );

    const taxDeductibleItemField = (name, restField, errors) => (
        <TaxDeductibleItem name={name} restField={restField} errors={errors}/>
    );

    return (
        <Form
            form={form}
            initialValues={initialValues}
            name="uraItemForm"
            onFinish={onFinish}
            autoComplete="off"
            onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
            }}
            labelCol={{span: 24}}
            wrapperCol={{span: 24}}
            onValuesChange={(changedValues, allValues) => {
                handleFieldsChange("uraItemForm", changedValues, allValues);
                console.log(allValues, 'allValues')
                if(allValues?.items){
                    for(let i=0; i<allValues.items.length; i++){
                        const item = allValues.items[i];
                        if(item?.taxDeductibleItem){
                            if(item?.priceWithoutPdv){
                                const priceWithoutPdv = item?.priceWithoutPdv ? twoPointDecimal(item.priceWithoutPdv) : twoPointDecimal(0);
                                const taxDeductiblePrice = twoPointDecimal(priceWithoutPdv) / twoPointDecimal(2);
                                form.setFieldValue(["items", i, "taxDeductiblePrice"], twoPointDecimal(taxDeductiblePrice))

                                if(item?.pdv){
                                    const pdv = item?.pdv ? twoPointDecimal(item.pdv) / twoPointDecimal(100) : twoPointDecimal(0);
                                    const taxDeductiblePdvPrice = (priceWithoutPdv * pdv) / 2
                                    const nonTaxableItemPrice = (priceWithoutPdv * pdv) + taxDeductiblePrice
                                    form.setFieldValue(["items", i, "taxDeductiblePdvPrice"], twoPointDecimal(taxDeductiblePdvPrice))
                                    form.setFieldValue(["items", i, "nonTaxableItemPrice"], twoPointDecimal(nonTaxableItemPrice))
                                } else {
                                    form.setFieldValue(["items", i, "taxDeductiblePdvPrice"], twoPointDecimal(0))
                                    form.setFieldValue(["items", i, "nonTaxableItemPrice"], twoPointDecimal(taxDeductiblePrice));
                                }
                            } else {
                                form.setFieldValue(["items", i, "taxDeductiblePrice"], twoPointDecimal(0))
                                form.setFieldValue(["items", i, "taxDeductiblePdvPrice"], twoPointDecimal(0))
                                form.setFieldValue(["items", i, "nonTaxableItemPrice"], twoPointDecimal(0))
                            }
                        } else {
                            form.setFieldValue(["items", i, "taxDeductiblePrice"], twoPointDecimal(0))
                            form.setFieldValue(["items", i, "taxDeductiblePdvPrice"], twoPointDecimal(0))
                            form.setFieldValue(["items", i, "nonTaxableItemPrice"], twoPointDecimal(0))
                        }
                    }
                }
            }}
        >
            <Form.List name="items">
                {(fields, {add, remove}, {errors}) => (
                    <>
                        {fields.map(({key, name, restField, getFieldValue}) => (
                            <div
                                key={key}
                                style={{
                                    marginTop: "16px",
                                    padding: "16px",
                                    background: "rgba(0, 0, 0, 0.02)",
                                }}
                            >
                                {generateFormRow(
                                    "1",
                                    projectField(name, restField, errors),
                                    expenseSubTypeField(name, restField, errors)
                                )}
                                {generateFormRow(
                                    "2",
                                    pdvField(name, restField, errors),
                                    priceField(name, restField, errors)
                                )}
                                {generateFormRow(
                                    "3",
                                    taxDeductibleItemField(name, restField, errors),
                                    null
                                )}
                                {showTaxDeductibleItems.get(name) ?
                                <Form.Item shouldUpdate>
                                    {form => {
                                        const taxDeductibleItem = form.getFieldsValue().items[name]?.taxDeductibleItem;
                                        if (taxDeductibleItem) {
                                            return (
                                                <div>
                                                    <Form.Item name={[name, "taxDeductiblePrice"]}
                                                               label={"Porezno priznati iznos"}
                                                               labelCol={{ span: 24 }}
                                                               style={{width: '100%'}}
                                                    >
                                                        <Input disabled={true} style={{width: '100%'}} />
                                                    </Form.Item>
                                                    <Form.Item name={[name, "taxDeductiblePdvPrice"]}
                                                               label={"Iznos porezno priznatog PDV-a"}
                                                               style={{width: '100%'}}
                                                               labelCol={{ span: 24 }}
                                                    >
                                                        <Input disabled={true} style={{width: '100%'}} />
                                                    </Form.Item>
                                                    <Form.Item name={[name, "nonTaxableItemPrice"]}
                                                               label={"Porezno nepriznati iznos"}
                                                               style={{width: '100%'}}
                                                               labelCol={{ span: 24 }}
                                                    >
                                                        <Input disabled={true} style={{width: '100%'}} />
                                                    </Form.Item>
                                                </div>
                                            )
                                        }
                                    }}
                                </Form.Item> : <></>
                                }
                                <Col>
                                    <Button
                                        danger
                                        icon={<MinusCircleOutlined/>}
                                        onClick={() => showConfirm(remove, name, fields)}
                                        style={{width: "100%", marginTop: "16px"}}
                                        disabled={mode === "preview"}
                                    >
                                        Obriši stavku
                                    </Button>
                                </Col>
                            </div>
                        ))}
                        <Form.Item>
                            <Button
                                type="dashed"
                                onClick={() => {
                                    const lastKeyInMap = form.getFieldsValue().items.length;
                                    add({ taxDeductibleItem: true, pdv: 25.00 });
                                    toggleMapCopy(lastKeyInMap);
                                }}
                                block
                                icon={<PlusOutlined/>}
                                style={{marginTop: "16px"}}
                                disabled={mode === "preview"}
                            >
                                Dodaj novu stavku
                            </Button>
                        </Form.Item>
                    </>
                )}
            </Form.List>
        </Form>
    );
};

export default UraItemForm;
