import React, {useEffect, useState} from "react";
import {Button, Flex, Form, Select, Space, theme, DatePicker} from "antd";
import {DownOutlined} from "@ant-design/icons";

import "./IraFilter.css";
import {axiosInstance} from "../../../api/axiosInstance";

const IraFilter = ({
                       form,
                       onFormFilterSubmit,
                       owners,
                       investors,
                       companies,
                       statuses,
                   }) => {
    const {token} = theme.useToken();
    const [expand, setExpand] = useState(false);

    const formStyle = {
        maxWidth: "none",
        background: token.colorFillAlter,
        borderRadius: token.borderRadiusLG,
        padding: 24,
        borderColor: "rgba(0, 0, 0, 0.03)",
        borderStyle: "solid",
        borderWidth: "thin",
    };


    return (
        <Form
            form={form}
            name="iraFilter"
            style={formStyle}
            onFinish={onFormFilterSubmit}
            layout={"vertical"}
            initialValues={{
                ownerId: '',
                investorId: '',
                companyId: '',
                statusId: '',
                debtorSort: '',
                dateFrom: '',
                dateTo: '',
            }}
        >
            <Flex vertical>
                {expand ?
                    <React.Fragment>
                        <Form.Item
                            name={`ownerId`}
                            label={`Nositelj:`}
                            rules={[
                                {
                                    required: false,
                                    message: "Nositelj je obavezno polje!",
                                },
                            ]}
                        >
                            <Select
                                options={owners}
                                showSearch
                                optionLabelProp="label"
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    (option?.label ?? "").includes(input)
                                }
                                optionRender={(option) => <Space>{option.data.label}</Space>}
                            ></Select>
                        </Form.Item>
                        <Form.Item
                            name={`companyId`}
                            label={`Tvrtka:`}
                            rules={[
                                {
                                    required: false,
                                    message: "Tvrtka je obavezno polje!",
                                },
                            ]}
                        >
                            <Select
                                options={companies}
                                showSearch
                                optionLabelProp="label"
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    (option?.label ?? "").includes(input)
                                }
                                optionRender={(option) => <Space>{option.data.label}</Space>}
                            ></Select>
                        </Form.Item>
                        <Form.Item
                            name={`investorId`}
                            label={`Investitor:`}
                            rules={[
                                {
                                    required: false,
                                    message: "Investitor je obavezno polje!",
                                },
                            ]}
                        >
                            <Select
                                options={investors}
                                showSearch
                                optionLabelProp="label"
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    (option?.label ?? "").includes(input)
                                }
                                optionRender={(option) => <Space>{option.data.label}</Space>}
                            >
                            ></Select>
                        </Form.Item>
                        <Form.Item
                            name={`statusId`}
                            label={`Status:`}
                            rules={[
                                {
                                    required: false,
                                    message: "Status je obavezno polje!",
                                },
                            ]}
                        >
                            <Select
                                options={statuses}
                                showSearch
                                optionLabelProp="label"
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    (option?.label ?? "").includes(input)
                                }
                                optionRender={(option) => <Space>{option.data.label}</Space>}
                            ></Select>
                        </Form.Item>
                        <Form.Item
                            name={`dateFrom`}
                            label={`Datum od:`}
                            rules={[
                                {
                                    required: false,
                                    message: "Datum od je obavezno polje!",
                                },
                            ]}
                        >
                            <DatePicker onChange={(date) => {
                            }}
                                        style={{width: '100%'}}
                                        placeholder={"Datum od"}
                                        format={"DD.MM.YYYY."}
                            />
                        </Form.Item>
                        <Form.Item
                            name={`dateTo`}
                            label={`Datum do:`}
                            rules={[
                                {
                                    required: false,
                                    message: "Datum do je obavezno polje!",
                                },
                            ]}
                        >
                            <DatePicker onChange={(date) => {
                            }}
                                        style={{width: '100%'}}
                                        placeholder={"Datum do"}
                                        format={"DD.MM.YYYY."}
                            />
                        </Form.Item>
                    </React.Fragment>
                    : <Form.Item
                        name={`ownerId`}
                        label={`Nositelj:`}
                        rules={[
                            {
                                required: false,
                                message: "Nositelj je obavezno polje!",
                            },
                        ]}
                    >
                        <Select
                            options={owners}
                            showSearch
                            optionLabelProp="label"
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                                (option?.label ?? "").includes(input)
                            }
                            optionRender={(option) => <Space>{option.data.label}</Space>}
                        ></Select>
                    </Form.Item>
                }

                <Flex justify="end" align="center" style={{marginBottom: '32px'}}>
                    <a
                        style={{fontSize: 12}}
                        onClick={() => {
                            setExpand(!expand);
                        }}
                    >
                        <DownOutlined rotate={expand ? 180 : 0}/> {expand ? "Skupi filter" : "Proširi filter"}
                    </a>
                </Flex>
                <Flex
                    justify="end"
                    align="center"
                    className="button-container"
                    gap={"4px"}
                >
                    <Button
                        type="primary"
                        htmlType="submit"
                        style={{minWidth: "120px"}}
                    >
                        Pretraži
                    </Button>
                    <Button
                        onClick={() => {
                            form.resetFields();
                        }}
                        style={{minWidth: "120px"}}
                    >
                        Poništi filtere
                    </Button>
                </Flex>
            </Flex>
        </Form>
    );
};

export default IraFilter;
