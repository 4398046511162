import React, {useEffect, useState} from "react";
import {useLocation, useNavigate} from 'react-router-dom';

import {Button, Empty, Flex, FloatButton, Form, Modal, Pagination, Select, theme} from "antd";
import PostWithComments from "../../components/Noticeboard/PostWithComments/PostWithComments";
import {
    ExclamationCircleOutlined,
    MessageOutlined,
    ReloadOutlined,
    ArrowLeftOutlined,
    EyeOutlined, EyeFilled
} from "@ant-design/icons";
import ModalComponent from "../../components/ModalComponent/ModalComponent";
import {useForm} from "antd/lib/form/Form";
import dayjs from "dayjs";
import {axiosInstance} from "../../api/axiosInstance";
import {useDispatch, useSelector} from "react-redux";
import {toast} from "react-toastify";
import PostSubmitForm from "../../components/Noticeboard/PostSubmitForm/PostSubmitForm";
import './NoticeboardPage.css'
import {setUnreadPostCount} from "../../slices/userDataSlice";

const localeData = require('dayjs/plugin/localeData');
const localizedFormat = require('dayjs/plugin/localizedFormat');
const hr = require('dayjs/locale/hr');

dayjs.extend(localeData);
dayjs.extend(localizedFormat);
dayjs.locale(hr);

const NoticeboardPage = ({userData}) => {

    const location = useLocation();

    const [isInitialRender, setIsInitialRender] = useState(true);

    const [form] = useForm()
    const [posts, setPosts] = useState([]);
    const [users, setUsers] = useState([]);
    const [comments, setComments] = useState(new Map());
    const [projects, setProjects] = useState([])
    const [selectedProject, setSelectedProject] = useState(location?.state?.projectId ? location.state.projectId : -1);
    const [newMessageModalOpened, setNewMessageModalOpened] = useState(false);
    const [loading, setLoading] = useState(false);
    //const [pageNumber, setPageNumber] = useState(1);
    //const [pageSize, setPageSize] = useState(5);
    const [totalRows, setTotalRows] = useState(0);

    const [showOnlyUnread, setShowOnlyUnread] = useState(false);

    const dispatch = useDispatch();
    const navigate = useNavigate();


    useEffect(() => {
        Promise.all([fetchUserProjectsByUserId(userData.userId), fetchPosts(), fetchUsers()]);
    }, []);

    useEffect(() => {
        if (isInitialRender) {
            setIsInitialRender(false);
        } else {
            fetchPosts();
            setComments(new Map())
            console.log('2')
        }
    }, [selectedProject]);

    useEffect(() => {
        if (isInitialRender) {
            setIsInitialRender(false);
        } else {
            fetchPosts()
        }
        // }, [pageNumber, pageSize, showOnlyUnread])
    }, [showOnlyUnread])


    const fetchUserProjectsByUserId = (userId) => {
        const isAdminRole = userData?.authorities?.includes('ROLE_ADMINISTRATOR');
        return axiosInstance.get(isAdminRole ? `/api/v1/projects/find-all` : `/api/v1/projects/find-all-by-user/${userId}`).then((res) => {
            const userProjects = res.data?.map((x) => {
                return {
                    value: x.id,
                    label: `${x.name} - ${x.investor.name}`,
                };
            });
            setProjects([{value: -1, label: 'Nepročitane poruke'}, {value: '', label: 'Javne poruke'}].concat(userProjects));
        });
    }

    const fetchPosts = async () => {
        //axiosInstance.post(`/api/v1/noticeboard/posts/find-by?page=${pageNumber - 1}&size=${pageSize}`, {
        axiosInstance.post(`/api/v1/noticeboard/posts/find-by`, {
            projectId: selectedProject,
            showOnlyUnread: showOnlyUnread
        })
            .then(res => {
                if (res.data) {
                    setPosts(res.data.content)
                    setTotalRows(res?.data?.totalElements);
                }
            })
    }

    const fetchUsers = (projectId) => {
        let url = projectId ? `/api/v1/users/find-all-by-project/${projectId}` : `/api/v1/users/find-all`;
        return axiosInstance.get(url).then(res => {
            const data = res.data.filter(x => x.id !== userData.userId).map((x) => {
                return {value: x.id, label: `${x.firstName} ${x.lastName}`, jobTitle: x.jobTitle};
            });
            setUsers([{value: '', label: "Svi djelatnici"}].concat(data));
        }).catch(ex => {
            console.log('Error while calling fetchUsers().')
        });
    };

    const handleSubmitPost = async (values) => {
        const response = await axiosInstance.post("/api/v1/noticeboard/post", {
            message: values.message,
            project: {id: values.projectId},
            recipientIds: values.recipientId,
        })
        setSelectedProject(values.projectId);
        fetchPosts();
        form.resetFields();
        setNewMessageModalOpened(false);
    }

    const {token} = theme.useToken();

    const pageStyle = {
        maxWidth: "none",
        background: token.colorFillAlter,
        borderRadius: token.borderRadiusLG,
        padding: 24,
        height: "100%",
    };

    const fetchComments = (postId) => {
        axiosInstance.get(`/api/v1/noticeboard/find-comments-by-post/${postId}`)
            .then(res => {
                const newMap = new Map(comments);
                newMap.set(postId, res.data);
                setComments(newMap)
            })
    }

    const handlePostDelete = (postId) => {
        Modal.confirm({
            title: "Potvrda",
            icon: <ExclamationCircleOutlined/>,
            content: "Jeste li sigurni da želite obrisati objavu?",
            okText: "Da",
            cancelText: "Ne",
            centered: true,
            onOk: () => {
                axiosInstance.delete(`/api/v1/noticeboard/post/${postId}`)
                    .then(async res => {
                        toast.success("Objava je uspješno obrisana!");
                        await fetchPosts();
                    }).catch(ex => {
                    toast.error(`${ex.response.data.errorMessage}`);
                })
            },
        });
    }

    return (<>
            {!userData.uiDisabled ?
                <div className={"noticeboard-page"} style={pageStyle}>
                    <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}>

                        <div style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "flex-start",
                            alignItems: 'center'
                        }}>
                            {location?.state?.projectId ?
                                <ArrowLeftOutlined onClick={() => {
                                    navigate("/projects");
                                }}/> : <></>
                            }
                            <h3 style={{marginLeft: '16px'}}>{`Oglasna ploča`}</h3>
                        </div>
                        <Select style={{width: '200px', alignSelf: 'center'}}
                                placeholder={"Odaberite posao"}
                                value={selectedProject}
                                options={projects}
                                onChange={(value) => {
                                    console.log(value, 'value')
                                    setSelectedProject(value)
                                }}
                                showSearch
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
                                }
                        ></Select>
                    </div>
                    <div className={"post-with-comments-container"}>
                        {posts && posts.length > 0 ? posts.map((post) => {

                                const commentsData = comments.get(post.id);

                                return (
                                    <PostWithComments
                                        mode={"insert"}
                                        key={post.id}
                                        post={post}
                                        comments={commentsData ? commentsData : []}
                                        selectedProject={selectedProject}
                                        setPosts={setPosts}
                                        userId={post.user.id}
                                        message={post.message}
                                        username={`${post.user.nameStr}`}
                                        createdAt={post.tstamp}
                                        numberOfComments={post.numberOfComments}
                                        fetchComments={(postId) => fetchComments(postId)}
                                        handlePostDelete={(postId) => handlePostDelete(postId)}
                                        previewMode={!!location?.state?.projectId}
                                    />)
                            })
                            :
                            <div style={{
                                display: 'flex',
                                marginRight: 'auto',
                                marginLeft: 'auto',
                                marginTop: '20%'
                            }}>

                                <Empty
                                    description={`Nije pronađena niti jedna ${!showOnlyUnread ? "" : "nepročitana"} objava.`}
                                />
                            </div>
                        }
                    </div>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        minHeight: '50vh',
                    }}>
                        {/*                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        marginTop: '32px',
                    }}>
                        <Pagination
                            //showSizeChanger
                            //pageSizeOptions={["5", "10"]}
                            current={pageNumber}
                            pageNumber={pageNumber}
                            pageSize={pageSize}
                            total={totalRows}
                            onChange={async (page, pageSize) => {
                                setPageNumber(page);
                                setPageSize(pageSize)
                            }}
                        />
                    </div>*/}
                    </div>
                    <FloatButton
                        icon={showOnlyUnread ? <EyeFilled/> : <EyeOutlined/>}
                        onClick={() => {
                            //if (pageNumber !== 1) {
                            //    setPageNumber(1);
                            //}
                            setShowOnlyUnread(!showOnlyUnread);
                        }}
                        type={"primary"}
                        style={{height: '50px', width: '50px', marginRight: '32px', marginBottom: '64px'}}
                        tooltip={() => (<>{showOnlyUnread ? "Prikaži sve objave" : "Prikaži nepročitane objave"}</>)}

                    />
                    {/*                <FloatButton
                    icon={<ReloadOutlined/>}
                    onClick={() => {
                        setShowOnlyUnread(false);
                    }}
                    type={"primary"}
                    style={{height: '50px', width: '50px', marginRight: '32px', marginBottom: '64px'}}
                    tooltip={() => (<>Prikaži sve objave</>)}
                />*/}
                    <FloatButton
                        icon={<MessageOutlined/>}
                        onClick={() => setNewMessageModalOpened(true)}
                        type={"primary"}
                        style={{height: '50px', width: '50px', marginRight: '32px'}}
                        tooltip={() => (<>Napiši objavu</>)}
                    />
                </div>
                : <></>
            }
            <ModalComponent
                title={"Nova poruka"}
                show={newMessageModalOpened}
                onOk={() => {
                }}
                onCancel={() => {
                    setNewMessageModalOpened(false);
                }}
                loading={loading}
                width={"580px"}
                centered={false}
                showFooter={false}
            >
                <PostSubmitForm
                    selectedProject={selectedProject}
                    form={form}
                    projects={projects}
                    users={users}
                    fetchUsers={(projectId) => fetchUsers(projectId)}
                    handleSubmitPost={(values) => handleSubmitPost(values)}
                />
            </ModalComponent>
        </>
    )
}

export default NoticeboardPage;