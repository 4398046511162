import React, { useEffect } from "react";
import { Table } from "antd";

const twoPointDecimal = (number) => Number(number).toFixed(2);

const RecapitulationTable = ({recapitulationData}) => {
    return (
        <span onClick={(e) => { e.preventDefault(); e.stopPropagation() }}  >
            <Table
                bordered
                style={{
                    wordBreak: "break-all",
                    width: "100%",
                    tableLayout: "fixed",
                }}
                pagination={false}
                columns={[
                    {
                        title: "Dogovoreni iznos",
                        dataIndex: "agreedPrice",
                        render: (value => (<>{twoPointDecimal(value)}</>))
                    },
                    {
                        title: "Ukupni troškovi",
                        dataIndex: "totalExpenses",
                        render: (value => (<>{twoPointDecimal(value)}</>))
                    },
                    {
                        title: "Trenutne uplate",
                        dataIndex: "totalPayments",
                        render: (value => (<>{twoPointDecimal(value)}</>))
                    },
                    {
                        title: "Trenutna dobit",
                        dataIndex: "currentProfit",
                        render: (value => (<>{twoPointDecimal(value)}</>))
                    },
                    {
                        title: "Planirana dobit",
                        dataIndex: "plannedProfit",
                        render: (value => (<>{twoPointDecimal(value)}</>))
                    },
                ]}
                dataSource={[].concat(recapitulationData)}
                size="small"
                scroll={{x: 700}}
            />
        </span>);
}
export default RecapitulationTable;