import React from "react";

import {Form, Input} from "antd";
import {axiosInstance} from "../../../../api/axiosInstance";
import {toast} from "react-toastify";

const PasswordChangeForm = ({form, rowData}) => {

    const handleChangePassword = (values) => {
        axiosInstance.post("/api/v1/users/change-password-by-admin", {
            id: rowData?.id,
            password: values.password
        }).then(res => {
            toast.success("Lozinka uspješno promijenjena");
            form.resetFields();
        }).catch(ex => {
            toast.error("Došlo je do greške prilikom promjene lozinke.")
        })
    }

    return (
        <Form
            form={form}
            name={"passwordChangeForm"}
            onFinish={(values) => {
                handleChangePassword(values);
            }}
            initialValues={{password: ''}}
        >
            <Form.Item
                name={`password`}
                label={`Nova lozinka:`}
                rules={[{required: true, message: 'Lozinka je obavezno polje'},
                    { min: 8, message: 'Lozinka mora imati duljinu od minimalno 8 znakova' },
                    {
                        pattern: new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[@$!%*?&])[A-Za-z\\d@$!%*?&]{8,}$'),
                        message: 'Lozinka mora sadržavati bar jedno malo i veliko slovo, broj i posebni znak'
                    }]}
            >
                <Input.Password
                    placeholder="Lozinka"
                />
            </Form.Item>
        </Form>
    );

}

export default PasswordChangeForm;