import React, { useEffect } from "react";
import {
    Button,
    Upload,
    Modal
} from "antd";
import { UploadOutlined } from '@ant-design/icons';

import {axiosInstance} from '../../../api/axiosInstance';
import {
    API_URL
} from "../../../constants/constants";
import { toast } from "react-toastify";
import {
    ExclamationCircleOutlined,
} from "@ant-design/icons";

const UploadForm = ({ mode, fileList, setUploadedFiles, setFileList }) => {

    const { confirm } = Modal;

    const deleteFile = (file) => {
        confirm({
            title: "Potvrda",
            icon: <ExclamationCircleOutlined />,
            content: "Jeste li sigurni da želite obrisati datoteku?",
            okText: "Da",
            cancelText: "Ne",
            centered: true,
            onOk: () => {
                console.log(file, 'file')
                if (file && file.uuid && mode === 'edit' && (file.status === 'removed' || file.status === 'uploading')) {
                    axiosInstance.delete(`${API_URL}/api/v1/files/projects/${file.uuid}`)
                        .then(res => {
                            setUploadedFiles((oldArray) => oldArray.filter(item => item.uuid !== file.uuid));
                            setFileList((oldArray) => oldArray.filter(item => item.uuid !== file.uuid))
                        }).catch(ex => {
                            toast.error("Dogodila se greška prilikom brisanja datoteke!");
                        })
                } else {
                    setUploadedFiles((oldArray) => oldArray.filter(item => item.uuid !== file.uuid));
                    setFileList((oldArray) => oldArray.filter(item => item.uuid !== file.uuid))
                }
            },
        });
    };


    return (<Upload
        accept=".pdf,.doc,.docx"
        listType="text"
        fileList={fileList}
        multiple
        onClick={(e) => {
            e.stopPropagation();
        }}
        customRequest={(files) => {
            setUploadedFiles(oldArray => [...oldArray, files.file]);
            setFileList(oldArray => [...oldArray, { name: files.file.name, status: 'uploading' }])
        }}
        onRemove={(file) => {
            if (mode === 'preview') {
                alert('Brisanje datoteke nije moguće na pregledu posla.')
                return;
            } else {
                deleteFile(file)
            }
        }}
        onPreview={(file) => {
            axiosInstance.get(`${API_URL}/api/v1/files/projects/download-file/${file.uuid}`, { responseType: 'blob' })
                .then(response => {
                    const href = URL.createObjectURL(response.data);
                    const link = document.createElement('a');
                    link.href = href;
                    link.setAttribute('download', file.name);
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                    URL.revokeObjectURL(href);
                });
        }}
    >
        <Button
            icon={<UploadOutlined />}
            onClick={(e) => {
                e.preventDefault();
            }}
            disabled={mode === 'preview'}
        >Učitajte datoteke</Button>
    </Upload>);
}

export default UploadForm;