import React, {
    Fragment,
    useCallback,
    useEffect,
    useMemo,
    useState,
    useRef
} from "react";
import PropTypes from "prop-types";
import {Calendar, Views, DateLocalizer} from "react-big-calendar";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop/withDragAndDrop";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import ".//CustomCalendar.css";
import CustomToolbar from "./CustomToolbar";
import moment from "moment";
import {Modal} from "antd";
import {ExclamationCircleOutlined} from "@ant-design/icons";
import {useMediaQuery} from 'react-responsive';

import CustomEvent from "./CustomEvent";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import {axiosInstance} from "../../api/axiosInstance";
import {toast} from "react-toastify";
import CustomAgenda from './CustomAgenda';
import timeTrackingForm from "./TimeTrackingForm";


dayjs.extend(customParseFormat);

const CustomCalendar = ({
                            date,
                            setDate,
                            onNavigate,
                            selectedUser,
                            timeTrackingForm,
                            timeTrackingItemForm,
                            userData,
                            localizer,
                            events,
                            fetchByRange,
                            setMode,
                            setEvent,
                            deleteEvent,
                            newEvent,
                            setEvents,
                            setShowModal,
                            eventActionLoading,
                            setEventActionLoading,
                            setWorkTypeId,
                            fetchTimeTable,
                            setSelectedMonth
                        }) => {

    const [draggedEvent, setDraggedEvent] = useState();
    const [displayDragItemInCell, setDisplayDragItemInCell] = useState(true);

    const [view, setView] = useState(Views.WEEK);
    const isMobile = useMediaQuery({maxWidth: 768}); // Change the max width as needed

    const clickRef = useRef(null)

    const minTime = new Date();
    minTime.setHours(7, 0, 0);
    const maxTime = new Date();
    maxTime.setHours(17, 0, 0);

    const defaultDate = useMemo(() => new Date(), []);

    useEffect(() => {
        setView(isMobile ? Views.AGENDA : Views.WEEK)
        return () => {
            window.clearTimeout(clickRef?.current)
        }
    }, []);

    const DragAndDropCalendar = withDragAndDrop(Calendar);

    const Card = ({children, className, style}) => {
        return (
            <div className={`${className || ""} card`} style={style}>
                {children}
            </div>
        );
    };

    const handleDragStart = useCallback((event) => {
        setDraggedEvent(event);
    }, []);

    const dragFromOutsideItem = useCallback(() => draggedEvent, [draggedEvent]);

    const customOnDragOver = useCallback(
        (dragEvent) => {
            // check for undroppable is specific to this example
            // and not part of API. This just demonstrates that
            // onDragOver can optionally be passed to conditionally
            // allow draggable items to be dropped on cal, based on
            // whether event.preventDefault is called

            if (draggedEvent !== "undroppable") {
                dragEvent.preventDefault();
            }
        },
        [draggedEvent]
    );

    const handleDeleteEvent = (eventToDelete) => {
        setEventActionLoading(true);
        deleteEvent(eventToDelete);
    };

    const confirm = (event) => {
        Modal.confirm({
            title: "Potvrda",
            icon: <ExclamationCircleOutlined/>,
            content: "Jeste li sigurni da želite obrisati utrošak?",
            okText: "Da",
            cancelText: "Ne",
            centered: true,
            onOk: () => {
                handleDeleteEvent(event);
            },
        });
    };

    const moveEvent = useCallback(
        ({event, start, end, isAllDay: droppedOnAllDaySlot = false}) => {
            const {allDay} = event;
            if (!allDay && droppedOnAllDaySlot) {
                event.allDay = true;
            }
            setEventActionLoading(true);

            console.log(start, 'start');
            console.log(end, 'end')

            axiosInstance
                .patch("/api/v1/time-tracking/event", {
                    id: event.id,
                    regularHours: event.regularHours,
                    overtimeHours: event.overtimeHours,
                    totalHours: event.totalHours,
                    projectId: event.projectId,
                    workTypeId: event.workTypeId,
                    description: event.description,
                    start: start,
                    end: end,
                    allDay: true,
                })
                .then((res) => {
                    setEvents((prev) => {
                        const existing = prev.find((ev) => ev.id === event.id) ?? {};
                        const filtered = prev.filter((ev) => ev.id !== event.id);
                        return [...filtered, {...existing, start, end, allDay}];
                    });
                }).catch(ex => {
                toast.error(ex.response.data.errorMessage);
            }).finally(onFinally => {
                setEventActionLoading(false);
            });
        },
        [setEvents]
    );


    const onDropFromOutside = useCallback(
        ({start, end}) => {
            setDraggedEvent(null);
            newEvent({
                title: draggedEvent.title,
                start,
                end,
                projectId: draggedEvent.projectId,
                workTypeId: draggedEvent.workTypeId,
                regularHours: draggedEvent.regularHours,
                overtimeHours: draggedEvent.overtimeHours,
                description: draggedEvent.description,
            });
        },
        [draggedEvent, setDraggedEvent, newEvent]
    );

    const resizeEvent = useCallback(
        ({event, start, end}) => {
            setEventActionLoading(true);
            axiosInstance
                .patch("/api/v1/time-tracking/event", {
                    id: event.id,
                    overtime: event.overtime,
                    projectId: event.projectId,
                    description: event.description,
                    start: start,
                    end: end,
                    allDay: true,
                })
                .then((res) => {
                    setEvents((prev) => {
                        const existing = prev.find((ev) => ev.id === event.id) ?? {};
                        const filtered = prev.filter((ev) => ev.id !== event.id);
                        return [...filtered, {...existing, start, end}];
                    });
                }).catch(ex => {
                toast.error(ex.response.data.errorMessage);
            }).finally(onFinally => {
                setEventActionLoading(false);
            });
        },
        [setEvents]
    );

    const eventPropGetter = (event, start, end, isSelected) => {

        const colorPalette = [
            "#1F306E", // Redovan rad
            "#1F306E", // Bolovanje do 42 dana
            "#1F306E", // Bolovanje od 42 dana
            "#1F306E", // Godišnji odmor
            "#1F306E", // Plaćeni dopust
            "#1F306E", // Plaćeni dopust
            "#1F306E"  // Praznik
        ];


        const style = {
            backgroundColor: colorPalette[event.workTypeId - 1],
            borderRadius: "5px",
            opacity: 0.8,
            color: "white",
            border: "0px",
            display: "block",
        };


        if (view === Views.AGENDA) {
            style.backgroundColor = "white"
            style.color = "black"
        }

        return {
            style,
        };
    };

    const handleEditEvent = (event) => {
        setMode("edit");
        setEvent(event);
        setWorkTypeId(event.workTypeId);
        setShowModal(true);
    };

    const onSelectSlot = useCallback((slotInfo) => {
        /**
         * Here we are waiting 250 milliseconds (use what you want) prior to firing
         * our method. Why? Because both 'click' and 'doubleClick'
         * would fire, in the event of a 'doubleClick'. By doing
         * this, the 'click' handler is overridden by the 'doubleClick'
         * action.
         */
        window.clearTimeout(clickRef?.current)
        clickRef.current = window.setTimeout(() => {
            let dayOfWeek = slotInfo.start.getDay();
            let isWeekend = (dayOfWeek === 6) || (dayOfWeek  === 0); // 6 = Saturday, 0 = Sunday

            if(!isWeekend){
                timeTrackingForm.setFieldsValue({timeRange: dayjs(slotInfo.start)});
                timeTrackingForm.setFieldsValue({workTypeId: 1})
                setMode('insert');
                timeTrackingItemForm.setFieldValue(["items"], [])
                setWorkTypeId(1)
                setShowModal(true);
            } else {
                toast.error("Nije moguće unositi sate za vikend.");
            }

        }, 250)
    }, [])


    return (
        <Fragment>
            <Card className="dndOutsideSource">
                <div className="inner">
                    {/*  {items.map((item) => (
            <Button
              draggable="true"
              key={item.title}
              onDragStart={() =>
                handleDragStart({
                  title: item.title,
                  projectId: item.projectId,
                })
              }
            >
              {item.title}
            </Button>
          ))} */}
                </div>
            </Card>
            <div className="height600">
                <DragAndDropCalendar
                    view={view}
                    setView={(view) => setView(view)}
                    date={view !== Views.AGENDA ? date : moment().startOf('month').toDate()}
                    length={31} // 30 dana u agenda view-u od početnog datuma
                    defaultDate={defaultDate}
                    views={{month: true, week: true, day: false, agenda: CustomAgenda}}
                    min={minTime}
                    max={maxTime}
                    messages={{
                        date: 'Datum',
                        time: 'Vrijeme',
                        event: 'Posao',
                        noEventsInRange: 'Ne postoji niti jedan utrošak za prikaz.'
                    }}
                    dragFromOutsideItem={
                        displayDragItemInCell ? dragFromOutsideItem : null
                    }
                    events={events}
                    showAllEvents
                    localizer={localizer}
                    onDropFromOutside={onDropFromOutside}
                    onDragOver={customOnDragOver}
                    onEventDrop={moveEvent}
                    //onEventResize={resizeEvent}
                    onDoubleClickEvent={(event) => {
                        handleEditEvent(event);
                    }}
                    onSelectSlot={onSelectSlot}
                    onNavigate={onNavigate}
                    onRangeChange={range => {
                        console.log(range, 'range')
                        fetchByRange({userId: selectedUser, dateFrom: range[0], dateTo: range[range.length - 1]});
                    }}
                    eventPropGetter={eventPropGetter}
                    resizable
                    selectable
                    formats={{
                        dayFormat: "ddd LL.",
                        dayRangeHeaderFormat: ({start, end}) =>
                            `${moment(start).format("MMMM DD.")} - ${moment(end).format(
                                "MMMM DD."
                            )}`,
                        agendaTimeRangeFormat: ({start, end}) =>
                            `${moment(start).format("h:mm A")} - ${moment(end).format(
                                "h:mm A"
                            )}`,
                        agendaDateFormat: "ddd D.M.",
                        agendaTimeFormat: "h:mm A",

                    }}
                    components={{
                        agenda: {
                            date: ({label, day}) => {
                                return <div>{<b>{label}</b>}</div>
                            },
                            time: () => (<div>cijeli dan</div>),
                        },
                        event: (customEventProps) => {
                            return (
                                <CustomEvent
                                    {...customEventProps}
                                    handleEditEvent={() =>
                                        handleEditEvent(customEventProps.event)
                                    }
                                    handleDeleteEvent={() =>
                                        confirm(customEventProps.event)
                                    }
                                    eventActionLoading={eventActionLoading}
                                ></CustomEvent>
                            );
                        },
                        toolbar: (toolbarProps) => {
                            return (
                                <CustomToolbar
                                    {...toolbarProps}
                                    selectedUser={selectedUser}
                                    userData={userData}
                                    setShowModal={setShowModal}
                                    setMode={setMode}
                                    setEvent={setEvent}
                                    fetchEventsByRange={(range) => fetchByRange(range)}
                                    fetchTimeTable={fetchTimeTable}
                                    view={view}
                                    setView={(view) => setView(view)}
                                    setSelectedMonth={setSelectedMonth}
                                />
                            );
                        },
                        timeGutterHeader: () => null,
                        timeGutter: () => null,
                    }}
                />

            </div>
        </Fragment>
    );
};
CustomCalendar.propTypes = {
    localizer: PropTypes.instanceOf(DateLocalizer),
};

export default CustomCalendar;
