import React, { useEffect, useState } from "react";

import {axiosInstance} from "../../api/axiosInstance";

import { toast } from "react-toastify";

import { Button, Form, Flex, theme } from "antd";
import { PlusOutlined } from "@ant-design/icons";

import ModalComponent from "../../components/ModalComponent/ModalComponent";

import "./SalaryPage.css";
import SalaryFilter from "../../components/Salary/SalaryFilter/SalaryFilter";
import SalaryTable from "../../components/Salary/SalaryTable/SalaryTable";
import SalaryForm from "../../components/Salary/SalaryForm/SalaryForm";
import dayjs from "dayjs";
import salaryFilter from "../../components/Salary/SalaryFilter/SalaryFilter";

const SalaryPage = (props) => {

    const [mode, setMode] = useState("insert");

    const [filterForm] = Form.useForm();
    const [salaryForm] = Form.useForm();
    const [rowData, setRowData] = useState(null);

    const [tableData, setTableData] = useState([]);
    const [pageNumber, setPageNumber] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalRows, setTotalRows] = useState(0);
    const [users, setUsers] = useState(false);

    const [userId, setUserId] = useState(null);

    const [loading, setLoading] = useState(false);
    const [salaryAdministrationModalOpened, setSalaryAdministrationModalOpened] =
        useState(false);

    const { token } = theme.useToken();

    const initValues = {
        userId: '',
        year: dayjs().year(),
        month: '',
        healthInsurance: 0,
        pensionInsuranceSecondPillar: 0,
        pensionInsuranceFirstPillar: 0,
        surtax: 0,
        salary: 0,
        fieldWork: 0,
        transport: 0,
        meal: 0,
        rewards: 0,
        other: 0,
        total: 0,
        hourlyPrice: 0,
        totalHours: 0,
    };

    const [initialValues, setInitialValues] = useState(initValues);

    const pageStyle = {
        maxWidth: "none",
        background: token.colorFillAlter,
        borderRadius: token.borderRadiusLG,
        padding: 24,
        height: "100%",
    };

    const fetchUsers = async (values) => {
        try {
            const res = await axiosInstance.get(`/api/v1/users/find-all`);
            const data = res.data.map((x) => {
                return { value: x.id, label: `${x.firstName} ${x.lastName}`, jobTitle: x.jobTitle };
            });
            setUsers([{ value: '', label: "Odaberite zaposlenika" }].concat(data));
        } catch (ex) {
            console.log('Error while calling fetchUsers().')
        }
    };

    const fetchSalaries = async (userId, year) => {

        try {
            const response = await axiosInstance.post(
                `/api/v1/salaries/find-by?page=${pageNumber - 1}&size=${pageSize}`,
                {
                    userId: userId,
                    year: year
                }
            );

            setTableData(
                response?.data?.content.map((element) => {
                    return {
                        ...element,
                        key: element.id,
                    };
                })
            );

            setTotalRows(response?.data?.totalElements);
        } catch (ex) {
            console.log('Error while calling fetchSalaries().')
        }

    };

    useEffect(() => {
        fetchUsers();
        fetchSalaries(userId, dayjs(filterForm.getFieldValue("year")).year());
    }, [pageNumber, pageSize]);

    const onFormFilterSubmit = async (values) => {
        fetchSalaries(values.userId, dayjs(values.year).year());
    };

    const onDataSubmit = (values) => {
        if (mode === "insert") {
            axiosInstance
                .post("/api/v1/salaries", {...values})
                .then((res) => {
                    setLoading(false);
                    setSalaryAdministrationModalOpened(false);
                    salaryForm.resetFields();
                    toast.success("Plaća uspješno spremljena!");
                    fetchSalaries(values.userId, values.year);
                })
                .catch((ex) => {
                    toast.error("Došlo je do greške prilikom spremanja plaće. " + ex?.response?.data?.errorMessage);
                    setLoading(false);
                });
        } else if (mode === "edit") {
            axiosInstance
                .patch("/api/v1/salaries", {...values})
                .then((res) => {
                    setLoading(false);
                    setSalaryAdministrationModalOpened(false);
                    salaryForm.resetFields();
                    toast.success("Plaća uspješno ažurirana!");
                    fetchSalaries(values.userId, values.year);
                })
                .catch((ex) => {
                    toast.error("Došlo je do greške prilikom ažuriranja plaće!");
                });
        }
        filterForm.setFieldValue(["userId"], values.userId);
    };

    const onFilterChanged = async (pagination, filters, sorter) => {
        setPageNumber(pagination.current);
        setPageSize(pagination.pageSize);
    };

    const handleSubmitSalary= async () => {
        try {
            const validationResult = await salaryForm.validateFields();
            if (!validationResult.errorFields) {
                salaryForm.submit();
                setLoading(true);
            }
        } catch (ex) {
            console.log(ex);
        } finally {
            setUserId(null);
        }
    };

    const salaryDataFormTitle = () => {
        if (mode === "insert") {
            return "Unos plaće";
        } else if (mode === "edit") {
            return "Uređivanje plaće";
        } else {
            return "Pregled detalja plaće";
        }
    };


    return (
        <div className={"salary-page"} style={pageStyle}>
            <h3>Plaće</h3>
            <SalaryFilter
                form={filterForm}
                onFormFilterSubmit={onFormFilterSubmit}
                users={users}
                setUserId={setUserId}
            ></SalaryFilter>
            <Flex vertical={false} justify="right" className="add-salary-wrapper">
                <Button
                    type="dashed"
                    icon={<PlusOutlined />}
                    style={{ marginTop: "32px" }}
                    onClick={async () => {
                        setInitialValues(initValues)
                        salaryForm.resetFields();
                        setMode("insert");
                        setSalaryAdministrationModalOpened(true);
                    }}
                >
                    Unesi plaću
                </Button>
            </Flex>
            <SalaryTable
                tableData={tableData}
                onFilterChanged={(pagination, filters, sorter) =>
                    onFilterChanged(pagination, filters, sorter)
                }
                setMode={setMode}
                setSalaryAdministrationModalOpened={setSalaryAdministrationModalOpened}
                setRowData={setRowData}
                setUserId={setUserId}
                style={{ marginTop: "32px" }}
                pageNumber={pageNumber}
                pageSize={pageSize}
                totalRows={totalRows}
                bordered
                refreshData={(userId, year) =>
                    fetchSalaries(userId, year)
                }
            />
            <ModalComponent
                title={salaryDataFormTitle()}
                show={salaryAdministrationModalOpened}
                onOk={handleSubmitSalary}
                onCancel={() => {
                    setSalaryAdministrationModalOpened(false);
                    setInitialValues(initValues);
                    setUserId(null);
                }}
                loading={loading}
                showFooter={mode !== "preview"}
                width={"580px"}
            >
                <SalaryForm
                    form={salaryForm}
                    mode={mode}
                    onFinish={onDataSubmit}
                    initialValues={mode === "insert" ? initialValues : rowData}
                    setInitialValues={setInitialValues}
                    disabled={mode === "preview"}
                    users={users}
                    userId={userId}
                    setUserId={setUserId}
                ></SalaryForm>
            </ModalComponent>
        </div>
    );
};

export default SalaryPage;
