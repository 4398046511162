import React, {useState, useEffect, useMemo} from "react";

import {
    Button,
    Flex,
    Form,
    theme,
} from "antd";
import {DeleteOutlined, ExclamationCircleOutlined, PlusOutlined} from "@ant-design/icons";

import "../IraPage/IraPage.css";
import ModalComponent from "../../components/ModalComponent/ModalComponent";

import "../../api/axiosInstance";
import {axiosInstance} from "../../api/axiosInstance";
import {toast} from "react-toastify";

import {useLocation} from "react-router-dom";
import dayjs from "dayjs";
import IraFilter from "../../components/Ira/IraFilter/IraFilter";
import IraTable from "../../components/Ira/IraTable/IraTable";
import IraForm from "../../components/Ira/IraForm/IraForm";
import IraPaymentForm from "../../components/Ira/IraPaymentForm/IraPaymentForm";
import IraPaymentModal from "../../components/Ira/IraPaymentModal/IraPaymentModal";
import iraItemForm from "../../components/Ira/IraItemForm/IraItemForm";

const IraPage = (props) => {
    const [mode, setMode] = useState("insert");
    const [activeKey, setActiveKey] = useState(["1", "2"]);

    const [filterForm] = Form.useForm();
    const [generalDataForm] = Form.useForm();
    const [iraItemForm] = Form.useForm();
    const [addPaymentForm] = Form.useForm();
    const [pdvDisabled, setPdvDisabled] = useState(false);

    const [generalFormInitialValues, setGeneralFormInitialValues] = useState({
        invoiceNumber: "",
        owner: {id: ""},
        company: {id: ""},
        investor: {id: ""},
        creationDate: dayjs(new Date()),
        creationTime: dayjs(new Date()),
        delivery: dayjs(new Date()),
        invoiceDueTime: 15,
        paymentType: {id: 1},
        taxLiabilityType: {id: 1},
        pdv: 25.00,
        remark: ''
    });

    const [iraItemFormInitialValues, setIraItemFormInitialValues] = useState({
        items: [{
            projectId: '',
            jmId: 1,
            amount: 1,
            rebate: 0.00
        }]
    });
    const [iraItemFormErrors, setIraItemFormErrors] = useState([]);

    const [rowData, setRowData] = useState();

    const [loading, setLoading] = useState(false);

    const [iraModalOpened, setIraModalOpened] = useState(false);
    const [iraPaymentModalOpened, setIraPaymentModalOpened] = useState(false);
    const [iraAddPaymentModalOpened, setIraAddPaymentModalOpened] = useState(false);

    const [companyFieldDisabled, setCompanyFieldDisabled] = useState(true);

    const [tableData, setTableData] = useState([]);
    const [pageNumber, setPageNumber] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalRows, setTotalRows] = useState(0);

    const [owners, setOwners] = useState([]);
    const [investors, setInvestors] = useState([]);
    const [companies, setCompanies] = useState([]);
    const [statuses, setStatuses] = useState([]);
    const [paymentTypes, setPaymentTypes] = useState([]);
    const [taxLiabilityTypes, setTaxLiablityTypes] = useState([]);
    const [jm, setJm] = useState([]);

    const [projects, setProjects] = useState([]);

    const [payments, setPayments] = useState([]);

    const [forceUpdate, setForceUpdate] = useState(false);

    const {token} = theme.useToken();

    let routeState = useLocation();

    const pageStyle = {
        maxWidth: "none",
        background: token.colorFillAlter,
        borderRadius: token.borderRadiusLG,
        padding: 24,
        height: "100%",
    };

    const fetchIras = async (values) => {
        try {
            const response = await axiosInstance.post(
                `/api/v1/ira/find-by?page=${pageNumber - 1}&size=${pageSize}`,
                {
                    ownerId: values?.ownerId,
                    investorId: values?.investorId,
                    companyId: values?.companyId,
                    statusId: values?.statusId,
                    creationDateSort: values?.creationDateSort ? values.creationDateSort : null,
                    debtorSort: values?.paymentPercentageSort ? values.paymentPercentageSort : null,
                    priceWithoutPdvSort: values?.priceWithoutPdvSort ? values.priceWithoutPdvSort : null,
                    priceWithPdvSort: values?.priceWithPdvSort ? values.priceWithPdvSort : null,
                    pdvPriceSort: values?.pdvPriceSort ? values.pdvPriceSort : null,
                    dateFrom: values?.dateFrom ? dayjs(values?.dateFrom).startOf('day').format('YYYY-MM-DD') : null,
                    dateTo: values?.dateTo ? dayjs(values?.dateTo).startOf('day').format('YYYY-MM-DD') : null,
                }
            );

            setTableData(
                response?.data?.content.map((element) => {
                    return {
                        ...element,
                        key: element.id,
                    };
                })
            );
            setTotalRows(response?.data?.totalElements);
        } catch (ex) {
            console.log('Error while calling fetchIras().')
        }

    };


    const fetchCodebooks = async () => {
        console.log('asdadasdadadadadasd')
        fetchIras()
        fetchStatuses();
        fetchPaymentTypes();
        fetchJm();
        Promise.all([fetchOwners(), fetchInvestors(), fetchCompanies(), fetchProjects(), fetchTaxLiabilityTypes()]);
    };


    useEffect(() => {
        fetchCodebooks()
    }, [pageNumber, pageSize])

    const fetchOwners = (values) => {
        return axiosInstance.get(`/api/v1/users/find-all`).then(res => {
            const data = res.data.map((x) => {
                return {value: x.id, label: `${x.firstName} ${x.lastName}`, jobTitle: x.jobTitle};
            });
            setOwners([{
                value: "",
                label: "Prikaži sve"
            }].concat(data.filter(x => x.jobTitle === 'direktor')))
        }).catch(ex => {
            console.log('Error while calling fetchUsers().')
        });
    };
    const fetchInvestors = () => {
        return axiosInstance.get("/api/v1/investors/find-all")
            .then(res => {
                const data = res.data.map((x) => {
                    return {value: x.id, label: x.name};
                });
                setInvestors([{value: "", label: "Prikaži sve"}].concat(data));
            }).catch(ex => {
                console.log('Error while calling fetchInvestors().')
            })
    };

    const fetchProjects = (investorId) => {

        console.log(investorId, 'oasds')

        let tInvestorId = null;
        if(mode === 'edit'){
            console.log(rowData?.investor?.id, 'investore')
            tInvestorId = investorId ? investorId : rowData?.investor?.id;
        } else {
            tInvestorId = investorId;
        }

        let url = tInvestorId ? `/api/v1/projects/find-all-by-investor/${tInvestorId}` : `/api/v1/projects/find-all`

        return axiosInstance.get(url).then(res => {
            const data = res.data.map((x) => {
                return {value: x.id, label: `${x.name}`, price: x.price};
            });
            setProjects([{value: "", label: "Prikaži sve"}].concat(data));
        }).catch(ex => {
            console.log('Error while calling fetchProjects().')
        });
    };

    const fetchCompanies = (values) => {
        return axiosInstance.get(`/api/v1/companies/find-all`).then(res => {
            const data = res.data.map((x) => {
                return {value: x.id, label: `${x.name}`};
            });
            setCompanies([{value: "", label: "Prikaži sve"}].concat(data));
        }).catch(ex => {
            console.log('Error while calling fetchCompanies().')
        });
    };

    const fetchStatuses = (values) => {
        setStatuses([{value: "", label: "Prikaži sve"}, {value: 1, label: "Otvoren"}, {value: 2, label: "Zatvoren"}, {value: 3, label: "Storniran"}]);
    };

    const fetchPaymentTypes = (values) => {
        setPaymentTypes([{value: 1, label: "Transakcijski"}]);
    };

    const fetchJm = (values) => {
        setJm([{value: "", label: "Prikaži sve"}, {value: 1, label: "kom"}]);
    };

    const fetchTaxLiabilityTypes = (values) => {
        return axiosInstance.get(`/api/v1/tax-liability-types`).then(res => {
            const data = res.data.map((x) => {
                return {value: x.id, label: `${x.name}`};
            });
            setTaxLiablityTypes(data);
        }).catch(ex => {
            console.log('Error while calling fetchTaxLiabilityTypes().')
        });
    };

    const fetchPayments = (id) => {
        return axiosInstance.get(`/api/v1/ira/${id}/payments`).then(res => {
            setPayments(res.data);
        }).catch(ex => {
            console.log('Error while calling fetchPayments().')
        });
    };
    const fetchIraItems = async (id) => {
        try {
            const res = await axiosInstance.get(`api/v1/ira/items/${id}`)
            if (res) {
                setIraItemFormInitialValues({items: res.data});
                iraItemForm.resetFields();
            }
        } catch (ex) {
            console.log('Error while calling fetchIraItems().')
        }
    }
    const generalDataFormTitle = () => {
        if (mode === "insert") {
            return "Novi izlazni račun";
        } else if (mode === "edit") {
            return "Uređivanje izlaznog računa";
        } else {
            return "Pregled izlaznog računa";
        }
    };

    const onIraSubmit = ({generalData}) => {

        const mappedValues = {
            ...generalData,
            id: rowData?.id,
            creationDate: dayjs(generalData.creationDate).startOf('day').format('YYYY-MM-DD'),
            creationTime: dayjs(generalData.creationTime).format("HH:mm"),
            owner: {...generalData.owner, id: generalData?.owner?.id},
            company: {...generalData.company, id: generalData?.company?.id},
            investor: {...generalData.investor, id: generalData?.investor?.id},
            paymentType: {...generalData.paymentType, id: generalData?.paymentType?.id},
            taxLiabilityType: {...generalData.taxLiabilityType, id: generalData?.taxLiabilityType?.id},
            items: iraItemForm.getFieldsValue().items
        };

        console.log(mappedValues, 'mappedValues')

        if (mode === "insert") {

            axiosInstance
                .post("/api/v1/ira", mappedValues)
                .then((res) => {
                    setLoading(false);
                    setIraModalOpened(false);
                    iraItemForm.resetFields();
                    generalDataForm.resetFields();
                    toast.success("Račun uspješno spremljen!");
                    fetchIras()
                })
                .catch((ex) => {
                    console.log(ex, "ex");
                    toast.error("Došlo je do greške prilikom spremanja računa!");
                });
        } else if (mode === "edit") {


            axiosInstance
                .patch(`/api/v1/ira`, mappedValues)
                .then((res) => {
                    setLoading(false);
                    setIraModalOpened(false);
                    filterForm.resetFields();
                    generalDataForm.resetFields();
                    iraItemForm.resetFields();
                    toast.success("Račun uspješno ažuriran!");
                    fetchIras()
                })
                .catch((ex) => {
                    toast.error("Došlo je do greške prilikom ažuriranja računa. " + ex.response.data.errorMessage);
                });
        }
    };

    const handleSubmitProject = async () => {
        let validationResultGeneralForm;
        let validationResultIraItemForm;

        let tGeneralFormHasErrors = false;
        let tIraItemFormHasErrors = false;

        try {
            validationResultGeneralForm = await generalDataForm.validateFields();
            if (!validationResultGeneralForm.errorFields) {
                generalDataForm.submit();
                //setLoading(true);
            }
        } catch (ex) {
            console.log(ex);
            tGeneralFormHasErrors = true;
        }

        try {
            validationResultIraItemForm = await iraItemForm.validateFields();
            if (!validationResultIraItemForm.errorFields) {
                iraItemForm.submit();
                //setLoading(true);
            }
        } catch (ex) {
            console.log(ex, 'aaaa');
            setIraItemFormErrors(ex.errorFields)
            tIraItemFormHasErrors = true;
        }

        const iraItemFormValues = iraItemForm.getFieldsValue()?.items;

        console.log(iraItemFormValues, 'iraItemValues')

        if (tGeneralFormHasErrors || tIraItemFormHasErrors || iraItemFormValues?.length === 0) {
            if (iraItemFormValues?.length === 0) {
                toast.error("Račun nema niti jednu stavku.");
            }
        } else {
            onIraSubmit({
                generalData: generalDataForm.getFieldsValue(),
            });
        }
    };

    const onFormFilterSubmit = async (values) => {
        fetchIras(values);
    };

    const handleFieldsChange = async (formName, changedValues, allValues) => {
        if (formName === 'generalDataForm') {
            if (!allValues.owner.id) {
                setCompanyFieldDisabled(true);
            } else {
                setCompanyFieldDisabled(false);
            }
        }

        if (formName === 'iraItemForm') {
            const changedField = Object.keys(changedValues.items)[0];
            console.log(changedField, 'changedValues')
            if (changedValues?.items[changedField]?.projectId) {
                console.log(changedValues?.items[changedField]?.projectId, 'projectId');
                axiosInstance.get(`/api/v1/ira-item-template/project/${allValues?.items[changedField]?.projectId}`)
                    .then((res) => {
                        if (res.data) {
                            console.log(res.data, 'templateValue')
                            iraItemForm.setFieldValue(["items", changedField, "name"], res.data);
                        } else {
                            iraItemForm.setFieldValue(["items", changedField, "name"], "");
                        }
                    }).finally((onFinally) => {
                    setForceUpdate(!forceUpdate);
                })
            }
        }
    }
    const onFilterChanged = async (pagination, filters, sorter) => {
        setPageNumber(pagination.current);
        setPageSize(pagination.pageSize);

        console.log(sorter)

        if (Array.isArray(sorter)) {
            const fetchColumn = (columnName) => sorter.filter(x => x.columnKey === columnName)[0];

            const creationDateField = fetchColumn("creationDate");
            const priceWithoutPdvField = fetchColumn("priceWithoutPdv");
            const priceWithPdvField = fetchColumn("priceWithPdv")
            const pdvPriceField = fetchColumn("pdvPrice");
            const paymentPercentageField = fetchColumn("paymentPercentage");

            let creationDateSort;
            if(creationDateField?.order){
                creationDateSort = creationDateField.order === 'descend' ? "NEWEST" : "OLDEST"
            }

            let priceWithoutPdvSort;
            if(priceWithoutPdvField?.order){
                priceWithoutPdvSort = priceWithoutPdvField.order === 'descend' ? "DESC" : "ASC";
            }

            let priceWithPdvSort;
            if(priceWithPdvField?.order){
                priceWithPdvSort = priceWithPdvField.order === 'descend' ? "DESC" : "ASC";
            }

            let pdvPriceSort;
            if(pdvPriceField?.order){
                pdvPriceSort = pdvPriceField.order === 'descend' ? "DESC" : "ASC";
            }

            let paymentPercentageSort;
            if(paymentPercentageField?.order){
                paymentPercentageSort = paymentPercentageField.order === 'descend' ? "BIGGEST" : "SMALLEST";
            }

            fetchIras({
                ...filterForm.getFieldsValue(),
                creationDateSort: creationDateSort,
                priceWithoutPdvSort: priceWithoutPdvSort,
                priceWithPdvSort: priceWithPdvSort,
                pdvPriceSort: pdvPriceSort,
                paymentPercentageSort: paymentPercentageSort
            })

        } else {
            if (sorter?.columnKey === 'creationDate') {
                fetchIras({
                    ...filterForm.getFieldsValue(),
                    creationDateSort: sorter.order === 'descend' ? "NEWEST" : "OLDEST"
                })
            }
            if (sorter?.columnKey === 'priceWithoutPdv') {
                if(sorter.order === 'descend'){
                    fetchIras({...filterForm.getFieldsValue(), priceWithoutPdvSort: "DESC"})
                } else if(sorter.order === 'ascend'){
                    fetchIras({...filterForm.getFieldsValue(), priceWithoutPdvSort: "ASC"})
                } else {
                    fetchIras({...filterForm.getFieldsValue(), priceWithoutPdvSort: null})
                }
            }
            if (sorter?.columnKey === 'priceWithPdv') {
                if(sorter.order === 'descend'){
                    fetchIras({...filterForm.getFieldsValue(), priceWithPdvSort: "DESC"})
                } else if(sorter.order === 'ascend'){
                    fetchIras({...filterForm.getFieldsValue(), priceWithPdvSort: "ASC"})
                } else {
                    fetchIras({...filterForm.getFieldsValue(), priceWithPdvSort: null})
                }
            }
            if (sorter?.columnKey === 'pdvPrice') {
                if(sorter.order === 'descend'){
                    fetchIras({...filterForm.getFieldsValue(), pdvPriceSort: "DESC"})
                } else if(sorter.order === 'ascend'){
                    fetchIras({...filterForm.getFieldsValue(), pdvPriceSort: "ASC"})
                } else {
                    fetchIras({...filterForm.getFieldsValue(), pdvPriceSort: null})
                }
            }
            if (sorter?.columnKey === 'paymentPercentage') {
                if(sorter.order === 'descend'){
                    fetchIras({...filterForm.getFieldsValue(), paymentPercentageSort: "SMALLEST"})
                } else if(sorter.order === 'ascend'){
                    fetchIras({...filterForm.getFieldsValue(), paymentPercentageSort: "BIGGEST"})
                } else {
                    fetchIras({...filterForm.getFieldsValue(), paymentPercentageSort: null})
                }
            }
        }
    };

    return (
        <div className={"ira-page"} style={pageStyle}>
            <h3>Izlazni računi</h3>
            <IraFilter
                form={filterForm}
                owners={owners}
                investors={investors}
                companies={companies}
                statuses={statuses}
                onFormFilterSubmit={onFormFilterSubmit}
            ></IraFilter>
            <Flex vertical={false} justify="right" className="add-ira-wrapper">
                <Button
                    disabled={props?.userData?.authorities?.includes('ROLE_ZAPOSLENIK')}
                    type="dashed"
                    icon={<PlusOutlined/>}
                    style={{marginTop: "32px"}}
                    onClick={() => {
                        setIraItemFormInitialValues({items: [{projectId: '', jmId: 1, amount: 1, rebate: 0.00}]})
                        generalDataForm.resetFields();
                        iraItemForm.resetFields();
                        setPdvDisabled(false);
                        setIraItemFormErrors([]);
                        setProjects([])
                        setMode("insert");
                        setRowData(null);
                        setIraModalOpened(true);
                    }}
                >
                    Kreiraj izlazni račun
                </Button>
            </Flex>
            <IraTable
                mode={mode}
                setMode={setMode}
                setRowData={setRowData}
                totalRows={totalRows}
                pageNumber={pageNumber}
                pageSize={pageSize}
                setIraModalOpened={setIraModalOpened}
                tableData={tableData}
                setIraPaymentModalOpened={setIraPaymentModalOpened}
                fetchPayments={(id) => fetchPayments(id)}
                fetchIraItems={(id) => fetchIraItems(id)}
                onFilterChanged={(pagination, filters, sorter) => onFilterChanged(pagination, filters, sorter)}
                refreshData={() =>
                    fetchIras({
                        ownerId: null,
                        investorId: null,
                        companyId: null,
                        statusId: null,
                        dateFrom: null,
                        dateTo: null,
                    })}
                fetchProjects={(investorId) => fetchProjects(investorId)}
            />
            <ModalComponent
                title={generalDataFormTitle()}
                show={iraModalOpened}
                onOk={handleSubmitProject}
                onCancel={() => {
                    generalDataForm.resetFields();
                    iraItemForm.resetFields();
                    setCompanyFieldDisabled(true);
                    setIraItemFormInitialValues({
                        items: [{
                            projectId: '',
                            jmId: 1,
                            amount: 1,
                            rebate: 0.00
                        }]
                    })
                    setRowData(null);
                    setIraModalOpened(false);
                }}
                loading={loading}
                showFooter={mode !== "preview"}
                width={"800px"}
            >
                <IraForm
                    mode={mode}
                    owners={owners}
                    companies={companies}
                    investors={investors}
                    projects={projects}
                    paymentTypes={paymentTypes}
                    taxLiabilityTypes={taxLiabilityTypes}
                    jm={jm}
                    generalDataForm={generalDataForm}
                    iraItemForm={iraItemForm}
                    rowData={rowData}
                    userData={props?.userData}
                    initialValues={
                        mode === "insert" ? generalFormInitialValues : rowData
                    }
                    iraItemFormInitialValues={
                        iraItemFormInitialValues
                    }
                    handleFieldsChange={(formName, changedValues, allValues) => handleFieldsChange(formName, changedValues, allValues)}
                    activeKey={activeKey}
                    setActiveKey={setActiveKey}
                    iraItemFormErrors={iraItemFormErrors}
                    setIraItemFormErrors={setIraItemFormErrors}
                    companyFieldDisabled={companyFieldDisabled}
                    forceUpdate={forceUpdate}
                    fetchProjects={(investorId) => fetchProjects(investorId)}
                    pdvDisabled={pdvDisabled}
                    setPdvDisabled={setPdvDisabled}

                ></IraForm>
            </ModalComponent>
            <IraPaymentModal
                iraPaymentModalOpened={iraPaymentModalOpened}
                setIraPaymentModalOpened={setIraPaymentModalOpened}
                setIraAddPaymentModalOpened={setIraAddPaymentModalOpened}
                loading={loading}
                mode={mode}
                payments={payments}
                fetchPayments={(id) => fetchPayments(id)}
                fetchIras={() => fetchIras()}
            />

            <IraPaymentForm
                addPaymentForm={addPaymentForm}
                iraAddPaymentModalOpened={iraAddPaymentModalOpened}
                setIraAddPaymentModalOpened={setIraAddPaymentModalOpened}
                rowData={rowData}
                fetchPayments={(id) => fetchPayments(id)}
                fetchIras={() => fetchIras()}
                loading={loading}
                mode={mode}
            />
        </div>);
};

export default IraPage;
