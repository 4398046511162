import React, {useMemo, useState} from "react";
import {Table, Button, Flex, Tooltip, Modal, Space, Empty} from "antd";
import {
    EditOutlined,
    SearchOutlined,
    DeleteOutlined,
    ExclamationCircleOutlined,
} from "@ant-design/icons";

import {toast} from "react-toastify";
import {axiosInstance} from "../../../api/axiosInstance";

const SalaryTable = ({
                         tableData,
                         pageNumber,
                         pageSize,
                         totalRows,
                         onFilterChanged,
                         setMode,
                         setSalaryAdministrationModalOpened,
                         setRowData,
                         setUserId,
                         refreshData
                     }) => {
    const [arrow, setArrow] = useState("Show");

    const [modal, contextHolder] = Modal.useModal();

    const confirm = (id, userId, year) => {
        modal.confirm({
            title: "Potvrda",
            icon: <ExclamationCircleOutlined/>,
            content: "Jeste li sigurni da želite obrisati plaću?",
            okText: "Da",
            cancelText: "Ne",
            centered: true,
            onOk: () => {
                axiosInstance.delete(`/api/v1/salaries/${id}`)
                    .then(res => {
                        toast.success("Plaća je uspješno obrisana!");
                        refreshData(userId, year);
                    }).catch(ex => {
                    toast.error(`${ex.response.data.errorMessage}`);
                })
            },
        });
    };

    const mergedArrow = useMemo(() => {
        if (arrow === "Hide") {
            return false;
        }

        if (arrow === "Show") {
            return true;
        }

        return {
            pointAtCenter: true,
        };
    }, [arrow]);
    const twoPointDecimal = (number) => Number(number).toFixed(2);

    const tableColumns = () => {
        return [
            {
                title: "Mjesec",
                dataIndex: "monthStr",
                key: "monthStr",
            },
            {
                title: "Zdravstveno",
                dataIndex: "healthInsurance",
                key: "healthInsurance",
                render: (value => (<>{twoPointDecimal(value)} €</>))
            },
            {
                title: "MO 2 stup",
                dataIndex: "pensionInsuranceSecondPillar",
                key: "pensionInsuranceSecondPillar",
                render: (value => (<>{twoPointDecimal(value)} €</>))
            },
            {
                title: "MO 1 stup",
                dataIndex: "pensionInsuranceFirstPillar",
                key: "pensionInsuranceFirstPillar",
                render: (value => (<>{twoPointDecimal(value)} €</>))
            },
            {
                title: "Prirez",
                dataIndex: "surtax",
                key: "surtax",
                render: (value => (<>{twoPointDecimal(value)} €</>))
            },
            {
                title: "Plaća",
                dataIndex: "salary",
                key: "salary",
                render: (value => (<>{twoPointDecimal(value)} €</>))
            },
            {
                title: "Terenske",
                dataIndex: "fieldWork",
                key: "fieldWork",
                render: (value => (<>{twoPointDecimal(value)} €</>))
            },
            {
                title: "Prijevoz",
                dataIndex: "transport",
                key: "transport",
                render: (value => (<>{twoPointDecimal(value)} €</>))
            },
            {
                title: "Topli obrok",
                dataIndex: "meal",
                key: "meal",
                render: (value => (<>{twoPointDecimal(value)} €</>))
            },
            {
                title: "Nagrade",
                dataIndex: "rewards",
                key: "rewards",
                render: (value => (<>{twoPointDecimal(value)} €</>))
            },
            {
                title: "Ostalo",
                dataIndex: "other",
                key: "other",
                render: (value => (<>{twoPointDecimal(value)} €</>))
            },
            {
                title: "Ukupno",
                dataIndex: "total",
                key: "total",
                render: (value => (<>{twoPointDecimal(value)} €</>))
            },
            {
                title: "Broj sati",
                dataIndex: "totalHours",
                key: "totalHours",
                render: (value => (<>{value} h</>))
            },
            {
                title: "Cijena sata",
                dataIndex: "hourlyPrice",
                key: "hourlyPrice",
                render: (value => (<>{twoPointDecimal(value)} €</>))
            },
            {
                title: "",
                key: "operation",
                //fixed: "right",
                width: "150px",
                render: (text, record, index) => (
                    <Flex vertical={false} gap={"4px"}>
                        <Tooltip
                            placement="top"
                            title={<span>Pregled</span>}
                            arrow={mergedArrow}
                        >
                            <Button
                                type="text"
                                size="large"
                                icon={<SearchOutlined/>}
                                onClick={() => {
                                    setMode("preview");
                                    setRowData(record);
                                    setSalaryAdministrationModalOpened(true);
                                }}
                            />
                        </Tooltip>
                        <Tooltip
                            placement="top"
                            title={<span>Uređivanje</span>}
                            arrow={mergedArrow}
                        >
                            <Button
                                type="text"
                                size="large"
                                icon={<EditOutlined/>}
                                onClick={() => {
                                    setMode("edit");
                                    setRowData(record);
                                    setUserId(record.userId)
                                    setSalaryAdministrationModalOpened(true);
                                }}
                            />
                        </Tooltip>
                        <Tooltip
                            placement="top"
                            title={<span>Brisanje</span>}
                            arrow={mergedArrow}
                        >
                            <Space>
                                <Button
                                    type="text"
                                    size="large"
                                    danger
                                    icon={<DeleteOutlined/>}
                                    onClick={() => confirm(record.id, record.userId, record.year)}
                                />
                            </Space>
                        </Tooltip>
                    </Flex>
                ),
            },
        ];
    };

    return (
        <>
            <Table
                columns={tableColumns()}
                dataSource={tableData}
                onChange={(pagination, filters, sorter) =>
                    onFilterChanged(pagination, filters, sorter)
                }
                style={{marginTop: "32px"}}
                pageNumber={pageNumber}
                pageSize={pageSize}
                pagination={{
                    defaultPageSize: 10,
                    showSizeChanger: true,
                    pageSizeOptions: ['10', '20', '30'],
                    total: totalRows,
                    showTotal: (total, range) => `Ukupan broj rezultata: ${totalRows}`
                }}
                bordered
                scroll={{x: 700}}
                locale={{emptyText: <Empty description="Nije pronađena niti jedna plaća."/>}}
                summary={(data) => (
                    data && data.length > 0 ?
                        <Table.Summary>
                            <Table.Summary.Row>
                                <Table.Summary.Cell index={0} colSpan={11}>
                                    <b>SUMA</b>
                                </Table.Summary.Cell>
                                <Table.Summary.Cell index={11} colSpan={1}>
                                    {twoPointDecimal(data[0]?.rangeTotal) + ' €'}
                                </Table.Summary.Cell>
                                <Table.Summary.Cell index={12} colSpan={1}>
                                    {twoPointDecimal(data[0]?.numberOfHoursTotal) + ' h'}
                                </Table.Summary.Cell>
                            </Table.Summary.Row>
                        </Table.Summary> : <></>
                )}
            />
            {contextHolder}
        </>
    );
};
export default SalaryTable;
