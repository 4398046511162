import React from "react";
import { Button, Flex, Form, Select, Input, Space, theme } from "antd";

import "./CompanyAdministrationFilter.css";

const CompanyAdministrationFilter = ({ form, onFormFilterSubmit }) => {
  const { token } = theme.useToken();

  const formStyle = {
    maxWidth: "none",
    background: token.colorFillAlter,
    borderRadius: token.borderRadiusLG,
    padding: 24,
    borderColor: "rgba(0, 0, 0, 0.03)",
    borderStyle: "solid",
    borderWidth: "thin",
  };

  return (
    <Form
      form={form}
      name="companyAdministrationPageFilter"
      style={formStyle}
      onFinish={onFormFilterSubmit}
      layout={"vertical"}
      initialValues={{
        searchTerm: "",
      }}
    >
      <Flex vertical>
        <Form.Item
          name={`searchTerm`}
          label={`Pretraži:`}
          rules={[
            {
              required: false,
              message: "Pojam pretrage je obavezno polje!",
            },
          ]}
        >
          <Input placeholder="Naziv ili OIB" />
        </Form.Item>
   
        <Flex
          justify="end"
          align="center"
          className="button-container"
          gap={"4px"}
        >
          <Button
            type="primary"
            htmlType="submit"
            style={{ minWidth: "120px" }}
          >
            Pretraži
          </Button>
          <Button
            onClick={() => {
              form.resetFields();
            }}
            style={{ minWidth: "120px" }}
          >
            Poništi filtere
          </Button>
        </Flex>
      </Flex>
    </Form>
  );
};

export default CompanyAdministrationFilter;
