export const API_URL = process.env.REACT_APP_API_URL;
export const FRONTEND_URL = process.env.REACT_APP_FRONTEND_URL;
export const AUTHORIZATION_SERVICE_URL =
  process.env.REACT_APP_AUTHORIZATION_SERVICE_URL;
export const AUTH_ENDPOINT = AUTHORIZATION_SERVICE_URL + "/oauth2/v1/authorize";
export const TOKEN_ENDPOINT = AUTHORIZATION_SERVICE_URL + "/oauth2/v1/token";
export const TOKEN_REVOCATION_ENDPOINT =
  AUTHORIZATION_SERVICE_URL + "/oauth2/v1/revoke";
export const CLIENT_NAME = "oidc-client";
export const CLIENT_SECRET = "secret";


const cityArr = [
  "Bakar",
  "Beli Manastir",
  "Belišće",
  "Benkovac",
  "Biograd na Moru",
  "Bjelovar",
  "Buje-Buie",
  "Buzet",
  "Čabar",
  "Čakovec",
  "Čazma",
  "Cres",
  "Crikvenica",
  "Đakovo",
  "Daruvar",
  "Delnice",
  "Donja Stubica",
  "Donji Miholjac",
  "Drniš",
  "Dubrovnik",
  "Duga Resa",
  "Dugo Selo",
  "Đurđevac",
  "Garešnica",
  "Glina",
  "Gospić",
  "Grubišno Polje",
  "Hrvatska Kostajnica",
  "Hvar",
  "Ilok",
  "Imotski",
  "Ivanec",
  "Ivanić-Grad",
  "Jastrebarsko",
  "Karlovac",
  "Kastav",
  "Kaštela",
  "Klanjec",
  "Knin",
  "Komiža",
  "Koprivnica",
  "Korčula",
  "Kraljevica",
  "Krapina",
  "Križevci",
  "Krk",
  "Kutina",
  "Kutjevo",
  "Labin",
  "Lepoglava",
  "Lipik",
  "Ludbreg",
  "Makarska",
  "Mali Lošinj",
  "Metković",
  "Mursko Središće",
  "Našice",
  "Nin",
  "Nova Gradiška",
  "Novalja",
  "Novi Marof",
  "Novi Vinodolski",
  "Novigrad-Cittanova",
  "Novska",
  "Obrovac",
  "Ogulin",
  "Omiš",
  "Opatija",
  "Opuzen",
  "Orahovica",
  "Oroslavje",
  "Osijek",
  "Otočac",
  "Otok",
  "Ozalj",
  "Pag",
  "Pakrac",
  "Pazin",
  "Petrinja",
  "Pleternica",
  "Ploče",
  "Popovača",
  "Poreč-Parenzo",
  "Požega",
  "Pregrada",
  "Prelog",
  "Pula-Pola",
  "Rab",
  "Rijeka",
  "Rovinj-Rovigno",
  "Samobor",
  "Senj",
  "Šibenik",
  "Sinj",
  "Sisak",
  "Skradin",
  "Slatina",
  "Slavonski Brod",
  "Slunj",
  "Solin",
  "Split",
  "Stari Grad",
  "Supetar",
  "Sveta Nedelja",
  "Sveti Ivan Zelina",
  "Trilj",
  "Trogir",
  "Umag-Umago",
  "Valpovo",
  "Varaždin",
  "Varaždinske Toplice",
  "Velika Gorica",
  "Vinkovci",
  "Virovitica",
  "Vis",
  "Vodice",
  "Vodnjan-Dignano",
  "Vrbovec",
  "Vrbovsko",
  "Vrgorac",
  "Vrlika",
  "Vukovar",
  "Zabok",
  "Zadar",
  "Zagreb",
  "Zaprešić",
  "Zlatar",
  "Županja",
];

const countyArr = [
  "Bjelovarsko-Bilogorska Županija",
  "Brodsko-Posavska Županija",
  "Dubrovacko-Neretvanska Županija",
  "Istarska Županija",
  "Karlovačka Županija",
  "Koprivničko-Križevacka Županija",
  "Krapinsko-Zagorska Županija",
  "Ličko-Senjska Županija",
  "Međimurska Županija",
  "Osječko-Baranjska Županija",
  "Požeško-Slavonska Županija",
  "Primorsko-Goranska Županija",
  "Šibensko-Kninska Županija",
  "Sisačko-Moslavačka Županija",
  "Splitsko-Dalmatinska Županija",
  "Varaždinska Županija",
  "Virovitičko-Podravska Županija",
  "Vukovarsko-Srijemska Županija",
  "Zadarska Županija",
  "Zagreb",
  "Zagrebačka Županija",
];

const countyDefaultValue = [{ value: "", label: "Odaberite županiju" }];
const mappedCounties = countyArr.map((item) => {
  return { value: item, label: item };
});
export const counties = countyDefaultValue.concat(mappedCounties);

const cityDefaultValue = [{ value: "", label: "Odaberite grad" }];
const mappedCities = cityArr.map((item) => {
    return { value: item, label: item };
  });
  export const cities = cityDefaultValue.concat(mappedCities);
  
