import React from "react";
import UploadForm from "../UploadForm/UploadForm";
import {Col, DatePicker, Form, Input, InputNumber, Row, Select} from "antd";
import dayjs from "dayjs";
import TextArea from "antd/es/input/TextArea";

const GeneralDataForm = ({
                             form,
                             formStyle,
                             disabled,
                             initialValues,
                             handleFieldsChange,
                             userData,
                             suppliers,
                             owners,
                             projects,
                             companies,
                             expenseTypes,
                             expenseSubTypes,
                             companyFieldDisabled,
                             coefficientFieldDisabled,
                             supplierFieldDisabled,
                             mode,
                             fileList,
                             setFileList,
                             setUploadedFile,
                             expenseSubTypeFieldDisabled
                         }) => {

    // Add refs for other input fields as needed

    const focusNextInput = (ref) => {
        if (ref.current) {
            ref.current.focus();
        }
    };

    const handleKeyDown = (e, ref) => {
        if (e.key === "Tab") {
            e.preventDefault();
            focusNextInput(ref);
        }
    };
    const onePointDecimal = (number) => Number(number).toFixed(1);
    const twoPointDecimal = (number) => Number(number).toFixed(2);

    const generateFormRow = (key, leftColumn, rightColumn) => {
        return (<Row gutter={16} key={key}>
                <Col span={12} xs={24} sm={12}>
                    {leftColumn}
                </Col>
                <Col span={12} xs={24} sm={12}>
                    {rightColumn}
                </Col>
            </Row>
        );
    }

    const isUserRole = userData?.authorities?.includes('ROLE_ZAPOSLENIK');
    const isAdminRole = userData?.authorities?.includes('ROLE_ADMINISTRATOR');

    const receiptNameField = (<Form.Item
        name={`name`}
        label={`Oznaka URA-e:`}
        rules={[
            {
                required: false,
                message: "Oznaka URA-e je obavezno polje!",
            },
        ]}
    >
        <Input placeholder="Oznaka URA-e"/>
    </Form.Item>);

    const supplierField = (<Form.Item
        name={["supplier", "id"]}
        label={`Dobavljač`}
        rules={[
            {
                required: true,
                message: "Dobavljač je obavezno polje!",
            },
        ]}
    >
        <Select
            options={suppliers}
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) =>
                (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
            disabled={supplierFieldDisabled}
        ></Select>
    </Form.Item>);


    const ownerField = (<Form.Item
        name={["owner", "id"]}
        label={`Nositelj`}
        rules={[
            {
                required: true,
                message: "Nositelj je obavezno polje!",
            },
        ]}
    >
        <Select options={owners} popupMatchSelectWidth={false}
        ></Select>
    </Form.Item>);

    const projectField = (<Form.Item
        name={["project", "id"]}
        label={`Posao`}
        rules={[
            {
                required: false,
                message: "Posao je obavezno polje!",
            },
        ]}
    >
        <Select
            options={projects}
            popupMatchSelectWidth={false}
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) =>
                (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
        ></Select>
    </Form.Item>);

    const expenseTypeField = (<Form.Item
        name={["expenseType", "id"]}
        label={`Vrsta troška`}
        rules={[
            {
                required: true,
                message: "Vrsta troška je obavezno polje!",
            },
        ]}
    >
        <Select
            options={expenseTypes}
            popupMatchSelectWidth={false}
        ></Select>
    </Form.Item>);

    const expenseSubTypeField = (<Form.Item
        shouldUpdate
        name={["expenseSubType", "id"]}
        label={`Podvrsta troška`}
        rules={[
            {
                required: false,
                message: "Podvrsta troška je obavezno polje!",
            },
        ]}
    >
        <Select options={expenseSubTypes} popupMatchSelectWidth={false}
                disabled={mode === "insert" && expenseSubTypeFieldDisabled}
        ></Select>
    </Form.Item>);

    const companyField = (<Form.Item
        name={["company", "id"]}
        label={`Tvrtka`}
        rules={[
            {
                required: true,
                message: "Tvrtka je obavezno polje!",
            },
        ]}
    >
        <Select
            options={companies}
            disabled={mode === "insert" && companyFieldDisabled}
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) =>
                (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
        ></Select>
    </Form.Item>);


    const receiptCreationDate = (<Form.Item
        name={"receiptCreationDate"}
        label={`Datum kreiranja računa:`}
        rules={[
            {
                required: true,
                message: "Datum kreiranja računa je obavezno polje!",
            },
        ]}
    >
        <DatePicker
            style={{width: "100%"}}
            getValueProps={(e) => ({
                value: e ? dayjs(e).add(1, "hour") : "",
            })}
            placeholder="Odaberite datum"
            format={"DD.MM.YYYY."}
            disabledTime={true}
            onChange={(value) => {
                form.setFieldsValue({proposedDeadline: value});
            }}
        />
    </Form.Item>);

    const receiptPaymentDate = (<Form.Item
        name={"receiptPaymentDate"}
        label={`Datum plaćanja računa:`}
        rules={[
            {
                required: false,
                message: "Datum plaćanja računa je obavezno polje!",
            },
        ]}
    >
        <DatePicker
            style={{width: "100%"}}
            getValueProps={(e) => ({
                value: e ? dayjs(e).add(1, "hour") : "",
            })}
            placeholder="Odaberite datum"
            format={"DD.MM.YYYY."}
            disabledTime={true}
            onChange={(value) => {
                form.setFieldsValue({proposedDeadline: value});
            }}
        />
    </Form.Item>);


    const coefficientField = (<Form.Item
            name={`coefficient`}
            label={`Koeficijent:`}
            rules={[
                {
                    required: true,
                    message: "Koeficijent je obavezno polje!",
                },
            ]}
        >
            <InputNumber
                onChange={(value) => {
                    form.setFieldsValue({
                        coefficient: twoPointDecimal(value),
                    });
                }}
                step={0.1}
                min={0.1}
                max={1.0}
                style={{width: "100%"}}
                placeholder="Koeficijent"
                disabled={coefficientFieldDisabled}
            />
        </Form.Item>
    );

    const remark = () => (
        <Form.Item
            name={`remark`}
            label={`Napomena:`}
            rules={[
                {
                    required: false,
                    message: "Napomena je obavezno polje!",
                },
            ]}
        >
            <TextArea rows={1} placeholder="Maksimalna dužina je 255 znakova" maxLength={255} style={{height: '50px'}}/>
        </Form.Item>
    )

    return (
        <Form
            form={form}
            name="generalDataForm"
            style={formStyle}
            onFinish={(values) => {
            }}
            layout={"vertical"}
            disabled={disabled}
            initialValues={initialValues}
            onClick={(e) => e.stopPropagation()}
            onValuesChange={(changedValues, allValues) => {
                handleFieldsChange('generalDataForm', changedValues, allValues);
            }}
            labelCol={{span: 24}}
            wrapperCol={{span: 24}}
            autoComplete="off"
        >
            {generateFormRow(1, receiptNameField, receiptCreationDate)}
            {generateFormRow(2, ownerField, companyField)}
            {generateFormRow(3, expenseTypeField, supplierField)}
            {generateFormRow(4, coefficientField, receiptPaymentDate)}
            {remark()}
            <UploadForm
                mode={mode}
                fileList={fileList}
                setFileList={setFileList}
                setUploadedFile={setUploadedFile}
            />
        </Form>
    );
}

export default GeneralDataForm;