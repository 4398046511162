import React, {useEffect} from "react";
import {Table} from "antd";


const EmployeeSalaryRecapitulation = ({salaryData}) => {

    const twoPointDecimal = (number) => Number(number).toFixed(2);

    return (<>
            <span onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation()
              }}>
                <Table
                    rowKey="employeeName"
                    bordered
                    size="small"
                    pagination={false}
                    columns={[{
                        title: "Ime i prezime djelatnika", dataIndex: ["employeeName"],
                    }, {
                        title: "Cijena radnog sata (prosjek)",
                        dataIndex: ["hourlyPrice"],
                        render: (value => (<>{twoPointDecimal(value)} €</>))
                    }, {
                        title: "Utrošeni sati", dataIndex: ["numberOfHours"],
                    }, {
                        title: "Ukupno", dataIndex: ["totalPrice"], render: (value => (<>{twoPointDecimal(value)} €</>))
                    },]}
                    dataSource={salaryData}
                    scroll={{x: 700}}
                    summary={() => (<Table.Summary>
                        <Table.Summary.Row>
                            <Table.Summary.Cell index={0} colSpan={3}>
                                <b>SUMA</b>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell index={0} colSpan={3}>
                                {twoPointDecimal(salaryData[0]?.totalMonthly) + ' €'}
                            </Table.Summary.Cell>
                        </Table.Summary.Row>
                    </Table.Summary>)}
                ></Table>
              </span>
        </>
    );
}

export default EmployeeSalaryRecapitulation;