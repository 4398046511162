import React, {useEffect, useState} from "react";
import {theme, Collapse, Table} from "antd";
import {CaretRightOutlined} from "@ant-design/icons";

import "./ProjectsForm.css";

import UserMapSubForm from "../UserMapForm/UserMapSubForm";
import UploadForm from "../UploadForm/UploadForm";
import GeneralDataForm from "../GeneralDataForm/GeneralDataForm";
import SpecificDataForm from "../SpecificDataForm/SpecificDataForm";
import InvestorContactTable from "../InvestorContactTable/InvestorContactTable";
import InputCostsTable from "../InputCostsTable/InputCostsTable";
import OutputCostsTable from "../OutputCostsTable/OutputCostsTable";
import RecapitulationTable from "../RecapitulationTable/RecapitulationTable";
import {axiosInstance} from "../../../api/axiosInstance";
import EmployeeSalaryRecapitulation from "../EmployeeSalaryRecapitulation/EmployeeSalaryRecapitulation";

const ProjectsForm = ({
                          userData,
                          generalDataForm,
                          specificDataForm,
                          userMapDataForm,
                          mode,
                          rowData,
                          uraData,
                          iraData,
                          recapitulationData,
                          salaryData,
                          investorContactTableData,
                          generalFormInitialValues,
                          specificFormInitialValues,
                          userMapInitialValues,
                          investors,
                          owners,
                          companies,
                          locations,
                          statuses,
                          mapTypes,
                          mapStatuses,
                          projectSubTypes,
                          disabled,
                          users,
                          usersOnProject,
                          activeKey,
                          setActiveKey,
                          handleFieldsChange,
                          projectTypeId,
                          selectedSubProjectId,
                          isMainProjectForm,
                          isAllProjects,
                          locationFieldDisabled,
                          companyFieldDisabled,
                          setUploadedFiles,
                          fileList,
                          setFileList,
                          userMapsErrors,
                          setUserMapsErrors,
                          showSpecificFormSection
                      }) => {
    const {Panel} = Collapse;

    const {token} = theme.useToken();
    const panelStyle = {
        marginBottom: 0,
        background: "white",
        borderRadius: token.borderRadiusLG,
        border: "none",
    };

    const isUserRole = userData?.authorities?.includes('ROLE_ZAPOSLENIK');
    const isAdminRole = userData?.authorities?.includes('ROLE_ADMINISTRATOR');


    const formStyle = {
        maxWidth: "none",
        background: token.colorFillAlter,
        borderRadius: token.borderRadiusLG,
        padding: 24,
    };

    useEffect(() => {
        if (mode === "insert") {
            generalDataForm.resetFields();
            specificDataForm.resetFields();
            userMapDataForm.resetFields();
        } else {
            console.log(rowData, 'rowdata')
        }
        setActiveKey(["1", "4"]);
    }, [mode]);

    useEffect(() => {
        generalDataForm.setFieldsValue({
            ...generalFormInitialValues,
            usersOnProject:
                mode === "insert" ? [] : usersOnProject.map((item) => item.value),
        });
        specificDataForm.setFieldsValue(specificFormInitialValues);
    }, [users, usersOnProject]);

    const handleSetActiveKey = (selectedKey) => {
        let activeKeyCopy = [...activeKey];
        const keyAlreadyExists = activeKeyCopy.includes(selectedKey);
        if (keyAlreadyExists) {
            const index = activeKeyCopy.indexOf(selectedKey);
            if (index > -1) {
                activeKeyCopy.splice(index, 1);
            }
        } else {
            activeKeyCopy.push(selectedKey);
        }
        setActiveKey(activeKeyCopy);
    };


    return (
        <Collapse
            bordered={false}
            expandIcon={({isActive}) => (
                <CaretRightOutlined
                    rotate={isActive ? 90 : 0}
                    style={{
                        background: token.colorBgContainer,
                    }}
                />
            )}
            style={{marginTop: "4px"}}
            activeKey={activeKey}
            ghost
        >
            <Panel
                forceRender={true}
                key="1"
                header="Opći podaci"
                style={{...panelStyle, background: "white"}}
                onClick={() => handleSetActiveKey("1")}
            >
                <GeneralDataForm
                    userData={userData}
                    mode={mode}
                    form={generalDataForm}
                    isMainProjectForm={isMainProjectForm}
                    formStyle={formStyle}
                    initialValues={generalFormInitialValues}
                    handleFieldsChange={handleFieldsChange}
                    projectSubTypes={projectSubTypes}
                    owners={owners}
                    locations={locations}
                    locationFieldDisabled={locationFieldDisabled}
                    investors={investors}
                    companies={companies}
                    companyFieldDisabled={companyFieldDisabled}
                    statuses={statuses}
                    users={users}
                    disabled={disabled}
                />
            </Panel>
            {isAdminRole && (isMainProjectForm || isAllProjects) ? (
                <Panel
                    forceRender={true}
                    key="4"
                    header={`Mape`}
                    style={{...panelStyle, background: "white"}}
                    onClick={() => handleSetActiveKey("4")}
                >
                    <UserMapSubForm
                        form={userMapDataForm}
                        initialValues={userMapInitialValues}
                        users={users}
                        mapTypes={mapTypes}
                        mapStatuses={mapStatuses}
                        mode={mode}
                        userMapsErrors={userMapsErrors}
                        setUserMapsErrors={setUserMapsErrors}
                        handleFieldsChange={handleFieldsChange}
                    />
                </Panel>
            ) : (
                <></>
            )}
            {isAdminRole && (isAllProjects || showSpecificFormSection) ? (
                <Panel
                    forceRender={true}
                    key="2"
                    header="Specifični podaci"
                    style={{...panelStyle, background: "white"}}
                    onClick={() => handleSetActiveKey("2")}
                >
                    <SpecificDataForm
                        form={specificDataForm}
                        formStyle={formStyle}
                        disabled={disabled}
                        initialValues={specificFormInitialValues}
                        projectTypeId={projectTypeId}
                        selectedSubProjectId={selectedSubProjectId}
                        handleFieldsChange={handleFieldsChange}
                    />
                </Panel>
            ) : (
                <></>
            )}
            {mode === 'edit' || mode === 'preview' ?
                <Panel
                    forceRender={true}
                    key="5"
                    header={`Kontakt investitora`}
                    style={{...panelStyle, background: "white"}}
                    onClick={() => handleSetActiveKey("5")}
                >
                    <InvestorContactTable investorContactTableData={investorContactTableData}/>
                </Panel>
                : <></>}
            {isAdminRole && (!isMainProjectForm && !isAllProjects) ? (
                <Panel
                    forceRender={true}
                    key="3"
                    header={`Obrasci`}
                    style={{...panelStyle, background: "white"}}
                    onClick={() => handleSetActiveKey("3")}
                >
                    <UploadForm
                        mode={mode}
                        fileList={fileList}
                        setFileList={setFileList}
                        setUploadedFiles={setUploadedFiles}
                    />
                </Panel>
            ) : (
                <></>
            )}
            {isAdminRole && (mode === 'edit' || mode === 'preview') ?
                <Panel
                    forceRender={true}
                    key="6"
                    header={`Ulazni troškovi`}
                    style={{...panelStyle, background: "white"}}
                    onClick={() => handleSetActiveKey("6")}
                >
                    <InputCostsTable data={uraData}/>
                </Panel> : <></>}

            {isAdminRole && (mode === 'edit' || mode === 'preview') ?
                <Panel
                    forceRender={true}
                    key="9"
                    header={`Troškovi zaposlenika`}
                    style={{...panelStyle, background: "white"}}
                    onClick={() => handleSetActiveKey("9")}
                >
                    <EmployeeSalaryRecapitulation salaryData={salaryData}/>
                </Panel> : <></>}

            {isAdminRole && (mode === 'edit' || mode === 'preview') ?
                <Panel
                    forceRender={true}
                    key="7"
                    header={`Izlazni račun`}
                    style={{...panelStyle, background: "white"}}
                    onClick={() => handleSetActiveKey("7")}
                >
                    <OutputCostsTable iraData={iraData}/>
                </Panel> : <></>}
            {isAdminRole && (mode === 'edit' || mode === 'preview') ?
                <Panel
                    forceRender={true}
                    key="8"
                    header={`Rekapitulacija`}
                    style={{...panelStyle, background: "white"}}
                    onClick={() => handleSetActiveKey("8")}
                >
                    <RecapitulationTable
                        recapitulationData={recapitulationData}
                    />
                </Panel> : <></>}
        </Collapse>
    );
};

export default ProjectsForm;
