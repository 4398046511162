import {createSlice} from "@reduxjs/toolkit";
import {cleanCookies} from "universal-cookie/es6/utils";

export const userDataSlice = createSlice({
    name: "userData",
    initialState: {
        user: "",
        firstAndLastName: "",
        userId: null,
        unreadPostIds: [],
        unreadPostCount: 0,
        authorities: [],
        uiDisabled: false,
    },
    reducers: {
        // add your non-async reducers here
        setAll: (state, action) => {
            state.userId = action.payload.userId;
            state.user = action.payload.user;
            state.firstAndLastName = action.payload.firstAndLastName;
            state.authorities = action.payload.authorities;
            state.unreadPostCount = action.payload.unreadPostCount;
            state.unreadPostIds = action.payload.unreadPostIds;
            state.uiDisabled = action.payload.uiDisabled
        },
        setUser: (state, action) => {
            state.user = action.payload.user;
        },
        setAuthorities: (state, action) => {
            state.authorities = action.payload.authorities;
        },
        setUnreadPostCount: (state, action) => {
            state.unreadPostCount = action.payload.unreadPostCount;
            state.unreadPostIds = action.payload.unreadPostIds;
        },
        setUiDisabled: (state, action) => {
          state.uiDisabled = action.payload.uiDisabled;
        },
        cleanStore(state, payload) {
            cleanCookies();
            localStorage.removeItem("accessToken");
            localStorage.removeItem("refreshToken");
            state.userId = null;
            state.user = null;
            state.firstAndLastName = "";
            state.authorities = null;
            state.unreadPostCount = 0;
            state.unreadPostIds = [];
            state.uiDisabled = false;
        },
    },
    //extraReducers: {
    // add your async reducers here
    //}
});

// Action creators
export const {setUser, setAuthorities, setAll, setUnreadPostCount, setUiDisabled, cleanStore} =
    userDataSlice.actions;

export default userDataSlice.reducer;
