import React from "react";
import {Button, Flex, Form, Select, Input, Space, theme, DatePicker} from "antd";

import "./SalaryFilter.css";
import dayjs from "dayjs";

const SalaryFilter = ({form, users, onFormFilterSubmit, setUserId}) => {
    const {token} = theme.useToken();

    const formStyle = {
        maxWidth: "none",
        background: token.colorFillAlter,
        borderRadius: token.borderRadiusLG,
        padding: 24,
        borderColor: "rgba(0, 0, 0, 0.03)",
        borderStyle: "solid",
        borderWidth: "thin",
    };

    return (
        <Form
            form={form}
            name="locationAdministrationPageFilter"
            style={formStyle}
            onFinish={onFormFilterSubmit}
            layout={"vertical"}
            initialValues={{
                userId: "",
                year: dayjs(),
            }}
        >
            <Flex vertical>
                <Form.Item
                    name={"userId"}
                    label={`Zaposlenik`}
                    rules={[
                        {
                            required: true,
                            message: "Zaposlenik je obavezno polje!",
                        },
                    ]}
                >
                    <Select
                        options={users}
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                            (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
                        }
                        onChange={(value) => setUserId(value)}
                    ></Select>
                </Form.Item>
                <Form.Item
                    name={"year"}
                    label={`Godina`}
                    rules={[
                        {
                            required: false,
                            message: "Godina je obavezno polje!",
                        },
                    ]}
                >
                    <DatePicker
                        picker="year"
                        style={{width: '100%'}}
                    />
                </Form.Item>

                <Flex
                    justify="end"
                    align="center"
                    className="button-container"
                    gap={"4px"}
                >
                    <Button
                        type="primary"
                        htmlType="submit"
                        style={{minWidth: "120px"}}
                    >
                        Pretraži
                    </Button>
                    <Button
                        onClick={() => {
                            form.resetFields();
                        }}
                        style={{minWidth: "120px"}}
                    >
                        Poništi filtere
                    </Button>
                </Flex>
            </Flex>
        </Form>
    );
};

export default SalaryFilter;
