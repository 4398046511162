import React, { useEffect, useState } from "react";
import { Flex, Form, Input, Select, theme } from "antd";

import "./LocationAdministrationForm.css";
import {axiosInstance} from "../../../../api/axiosInstance.js";

import { cities, counties } from "../../../../constants/constants.js";

const LocationAdministrationForm = ({
  form,
  mode,
  initialValues,
  onFinish,
  disabled,
}) => {
  const { token } = theme.useToken();

  const formStyle = {
    maxWidth: "none",
    background: token.colorFillAlter,
    borderRadius: token.borderRadiusLG,
    padding: 24,
  };

  useEffect(() => {
    form.setFieldsValue(initialValues);
  }, [initialValues]);

  useEffect(() => {
    if(mode === 'insert'){
      form.resetFields();
    }
  }, [mode]);

  return (
    <Form
      form={form}
      name="locationAdministrationForm"
      style={formStyle}
      onFinish={(values) =>
        onFinish({
          ...values,
          id: initialValues?.id,
          address: { ...values.address, id: initialValues?.address?.id },
        })
      }
      initialValues={initialValues}
      layout={"vertical"}
      disabled={disabled}
    >
      <Form.Item
        name={`cadastralParcel`}
        label={`Katastarska čestica:`}
        rules={[
          {
            required: true,
            message: "Katastarska čestica je obavezno polje!",
          },
        ]}
      >
        <Input placeholder="Katastarska čestica" />
      </Form.Item>
      <Form.Item
        name={`cadastralMunicipality`}
        label={`Katastarska općina:`}
        rules={[
          {
            required: true,
            message: "Katastarska čestica je obavezno polje!",
          },
        ]}
      >
        <Input placeholder="Katastarska općina" />
      </Form.Item>
      <Form.Item
        name={["address", "street"]}
        label={`Ulica:`}
        rules={[
          {
            required: false,
            message: "Ulica je obavezno polje!",
          },
        ]}
      >
        <Input placeholder="Ulica lokacije" />
      </Form.Item>
      <Form.Item
        name={["address", "houseNumber"]}
        label={`Kućni broj:`}
        rules={[
          {
            required: false,
            message: "Kućni broj je obavezno polje!",
          },
        ]}
      >
        <Input placeholder="Kućni broj lokacije" />
      </Form.Item>
      <Form.Item
        name={["address", "postNumber"]}
        label={`Poštanski broj:`}
        rules={[
          {
            required: false,
            message: "Poštanski broj je obavezno polje!",
          },
        ]}
      >
        <Input placeholder="Poštanski broj lokacije" />
      </Form.Item>
      <Form.Item
        name={["address", "city"]}
        label={`Grad:`}
        rules={[
          {
            required: false,
            message: "Grad je obavezno polje!",
          },
        ]}
      >
        <Select
          options={cities}
          showSearch
          optionFilterProp="children"
          filterOption={(input, option) =>
            (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
          }
        ></Select>
      </Form.Item>
      <Form.Item
        name={"county"}
        label={`Županija:`}
        rules={[
          {
            required: true,
            message: "Županija je obavezno polje!",
          },
        ]}
      >
        <Select
          options={counties}
          showSearch
          optionFilterProp="children"
          filterOption={(input, option) =>
            (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
          }
        ></Select>
      </Form.Item>
    </Form>
  );
};

export default LocationAdministrationForm;
