import React, {useEffect, useState} from "react";
import {Collapse, theme} from "antd";
import {CaretRightOutlined} from "@ant-design/icons";
import GeneralDataForm from "../GeneralDataForm/GeneralDataForm";
import IraItemForm from "../IraItemForm/IraItemForm";

const IraForm = ({
                     mode,
                     userData,
                     owners,
                     companies,
                     investors,
                     projects,
                     paymentTypes,
                     taxLiabilityTypes,
                     jm,
                     generalDataForm,
                     initialValues,
                     iraItemFormInitialValues,
                     handleFieldsChange,
                     iraItemForm,
                     rowData,
                     activeKey,
                     setActiveKey,
                     iraItemFormErrors,
                     setIraItemFormErrors,
                     companyFieldDisabled,
                     forceUpdate,
                     fetchProjects,
                     pdvDisabled,
                     setPdvDisabled
                 }) => {

    useEffect(() => {
        generalDataForm.resetFields();
    }, [rowData])

    const {Panel} = Collapse;

    const {token} = theme.useToken();
    const panelStyle = {
        marginBottom: 0,
        background: "white",
        borderRadius: token.borderRadiusLG,
        border: "none",
    };
    const handleSetActiveKey = (selectedKey) => {
        let activeKeyCopy = [...activeKey];
        const keyAlreadyExists = activeKeyCopy.includes(selectedKey);
        if (keyAlreadyExists) {
            const index = activeKeyCopy.indexOf(selectedKey);
            if (index > -1) {
                activeKeyCopy.splice(index, 1);
            }
        } else {
            activeKeyCopy.push(selectedKey);
        }
        setActiveKey(activeKeyCopy);
    };


    return (
        <>
            <Collapse
                bordered={false}
                expandIcon={({isActive}) => (
                    <CaretRightOutlined
                        rotate={isActive ? 90 : 0}
                        style={{
                            background: token.colorBgContainer,
                        }}
                    />
                )}
                style={{marginTop: "4px"}}
                activeKey={activeKey}
                ghost
            >
                <Panel
                    forceRender={true}
                    key="1"
                    header="Opći podaci"
                    style={{...panelStyle, background: "white"}}
                    onClick={() => handleSetActiveKey("1")}
                >
                    <GeneralDataForm
                        form={generalDataForm}
                        initialValues={initialValues}
                        handleFieldsChange={handleFieldsChange}
                        userData={userData}
                        owners={owners}
                        companies={companies}
                        investors={investors}
                        paymentTypes={paymentTypes}
                        taxLiabilityTypes={taxLiabilityTypes}
                        companyFieldDisabled={companyFieldDisabled}
                        mode={mode}
                        pdvDisabled={pdvDisabled}
                        setPdvDisabled={setPdvDisabled}
                        fetchProjects={(investorId) => {
                            fetchProjects(investorId);
                            if(mode === 'edit'){
                                // todo: this should iterate over all fields instead of editing just one
                                iraItemForm.setFieldValue(["items", 0, "projectId"], null);
                            }
                        }}
                    >
                    </GeneralDataForm>
                </Panel>
                <Panel
                    forceRender={true}
                    key="2"
                    header="Stavka računa"
                    style={{...panelStyle, background: "white"}}
                    onClick={() => handleSetActiveKey("2")}
                >
                    <IraItemForm
                        form={iraItemForm}
                        iraItemFormInitialValues={iraItemFormInitialValues}
                        mode={mode}
                        projects={projects}
                        jm={jm}
                        handleFieldsChange={handleFieldsChange}
                        uraItemFormErrors={iraItemFormErrors}
                        setUraItemFormErrors={setIraItemFormErrors}
                        forceUpdate={forceUpdate}
                    ></IraItemForm>
                </Panel>
            </Collapse>
        </>
    )
        ;
}

export default IraForm;