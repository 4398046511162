import React from 'react';
import { PropTypes } from 'prop-types';
import { accessor as get } from 'react-big-calendar/lib/utils/accessors';
import TimeGrid from 'react-big-calendar/lib/TimeGrid';
import Agenda from './CustomAgendaHelper';

import { navigate } from 'react-big-calendar/lib/utils/constants';

const CustomAgenda = ({ events, date, ...rest }) => {
  const { start, end } = range(date);

  return (
      <Agenda {...rest} events={events} date={date} />
  );
};

CustomAgenda.propTypes = {
  events: PropTypes.array.isRequired,
  date: PropTypes.instanceOf(Date).isRequired,
};

CustomAgenda.defaultProps = TimeGrid.defaultProps;

CustomAgenda.navigate = (date, action) => {
  switch (action) {
    case navigate.PREVIOUS:
      return new Date(date.getFullYear(), date.getMonth(), date.getDate() - 7);
    case navigate.NEXT:
      return new Date(date.getFullYear(), date.getMonth(), date.getDate() + 7);
    default:
      return date;
  }
};

const range = date => {
  const start = new Date(date.getFullYear(), date.getMonth(), date.getDate());
  const end = new Date(date.getFullYear(), date.getMonth(), date.getDate() + 1);
  return { start, end };
};

CustomAgenda.title = date => {
  return `Agenda - ${date.toLocaleDateString()}`;
};

export default CustomAgenda;
