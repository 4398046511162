import "./App.css";
import { useEffect, useState } from "react";
import './config.js';

import React from "react";
import "./index.css";
import { useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
import LoginPage from "./pages/LoginPage/LoginPage";
import Navigation from "./components/Navigation/Navigation";

function App() {

  const userData = useSelector((state) => state.userData);
  const [searchParams, setSearchParams] = useSearchParams();
  const [forgottenPassword, setForgottenPassword] = useState(searchParams.get("forgottenPassword") ? searchParams.get("forgottenPassword") : false);
  const [forgottenPasswordToken, setForgottenPasswordToken] = useState(searchParams.get("forgottenPasswordToken") ? searchParams.get("forgottenPasswordToken") : false);

  useEffect(() => {
    console.log(userData, 'userDataChanged')
  }, [userData])

  return (
    <div className="app">
      {userData && userData.user ? (
        <Navigation userData={userData}></Navigation>
      ) : (
        <div className="login-page">
          <LoginPage
            forgottenPassword={forgottenPassword}
            forgottenPasswordToken={forgottenPasswordToken}
            setForgottenPassword={setForgottenPassword}
            setForgottenPasswordToken={setForgottenPasswordToken}
          />
        </div>
      )}
    </div>
  );
}

export default App;
