import React, {useEffect, useState} from "react";

import {axiosInstance} from "../../api/axiosInstance";

import {toast} from "react-toastify";

import {Button, Form, Flex, theme} from "antd";
import {PlusOutlined} from "@ant-design/icons";

import ModalComponent from "../../components/ModalComponent/ModalComponent";

import "./IraItemNameTemplateAdministrationPage.css";
import IraItemNameTemplateFilter
    from "../../components/Administration/IraItemNameTemplates/IraItemNameTemplateFilter/IraItemNameTemplateFilter";
import IraItemNameTemplateTable
    from "../../components/Administration/IraItemNameTemplates/IraItemNameTemplateTable/IraItemNameTemplateTable";
import IraItemNameTemplateForm
    from "../../components/Administration/IraItemNameTemplates/IraItemNameTemplateForm/IraItemNameTemplateForm";

const IraItemNameTemplateAdministrationPage = (props) => {

    const [mode, setMode] = useState("insert");

    const [filterForm] = Form.useForm();
    const [dataForm] = Form.useForm();
    const [rowData, setRowData] = useState(null);

    const [projectTypes, setProjectTypes] = useState([]);

    const [tableData, setTableData] = useState([]);
    const [pageNumber, setPageNumber] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalRows, setTotalRows] = useState(0);

    const [loading, setLoading] = useState(false);
    const [templateAdministrationModalOpened, setTemplateAdministrationModalOpened] =
        useState(false);

    useEffect(() => {
        fetchProjectTypes()
    }, [])

    const {token} = theme.useToken();

    const [initialValues, setInitialValues] = useState({
        project: {id: ""},
        templateValue: ''

    });

    const pageStyle = {
        maxWidth: "none",
        background: token.colorFillAlter,
        borderRadius: token.borderRadiusLG,
        padding: 24,
        height: "100%",
    };

    const fetchTemplates = async (values) => {
        const response = await axiosInstance.post(
            `/api/v1/ira-item-template/find-by?page=${pageNumber - 1}&size=${pageSize}`, {
                projectId: values?.projectId
            }
        );

        setTableData(
            response?.data?.content.map((element) => {
                return {
                    ...element,
                    key: element.id,
                };
            })
        );
        setTotalRows(response?.data?.totalElements);
    };

    const fetchProjectTypes = () => {
        const buildTree = (data) => {
            // Step 1: Create a map of all items by their IDs for quick access.
            const lookup = {};
            data.forEach(item => {
                lookup[item.id] = { ...item, title: item.name, value: item.id.toString(), children: [] };
            });

            // Step 2: Create the hierarchical tree by linking children to their parents.
            const tree = [];
            data.forEach(item => {
                if (item.parentId === null) {
                    // Root nodes
                    tree.push(lookup[item.id]);
                } else {
                    // Non-root nodes
                    if (lookup[item.parentId]) {
                        lookup[item.parentId].children.push(lookup[item.id]);
                    }
                }
            });
            return tree;
        }

        return axiosInstance.get(`/api/v1/project-types/find-all`).then(res => {
            console.log(res.data, 'resdata')
            const treeData = buildTree(res.data);
            console.log(treeData, 'treeData')
            setProjectTypes(treeData);
        }).catch(ex => {
            console.log('Error while calling fetchProjectTypes().' + ex.message)
        });
    };

    useEffect(() => {
        fetchTemplates({projectId: ""});
    }, [pageNumber, pageSize]);

    const onFormFilterSubmit = async (values) => {
        fetchTemplates(values);
    };

    const onDataSubmit = (values) => {
        if (mode === "insert") {
            axiosInstance
                .post("/api/v1/ira-item-template", values)
                .then((res) => {
                    setLoading(false);
                    setTemplateAdministrationModalOpened(false);
                    dataForm.resetFields();
                    toast.success("Predložak uspješno spremljen!");
                    fetchTemplates({projectId: ""});
                })
                .catch((ex) => {
                    toast.error("Došlo je do greške prilikom spremanja predloška. " + ex.response.data.errorMessage);
                });
        } else if (mode === "edit") {
            axiosInstance
                .patch(`/api/v1/ira-item-template`, values)
                .then((res) => {
                    setLoading(false);
                    setTemplateAdministrationModalOpened(false);
                    dataForm.resetFields();
                    toast.success("Predložak uspješno ažuriran!");
                    fetchTemplates({projectId: ""});
                })
                .catch((ex) => {
                    toast.error("Došlo je do greške prilikom ažuriranja predloška!");
                });
        }
    };

    const onFilterChanged = async (pagination, filters, sorter) => {
        setPageNumber(pagination.current);
        setPageSize(pagination.pageSize);
    };

    const handleSubmit = async () => {
        try {
            const validationResult = await dataForm.validateFields();
            if (!validationResult.errorFields) {
                dataForm.submit();
                setLoading(true);
            }
        } catch (ex) {
            console.log(ex);
        }
    };

    const formTitle = () => {
        if (mode === "insert") {
            return "Novi predložak";
        } else if (mode === "edit") {
            return "Uređivanje predloška";
        } else {
            return "Pregled detalja predloška";
        }
    };


    return (
        <div className={"ira-item-name-template-page"} style={pageStyle}>
            <h3>Administracija predložaka naziva stavke IRA-e</h3>
            <IraItemNameTemplateFilter
                form={filterForm}
                onFormFilterSubmit={(values) => onFormFilterSubmit(values)}
            ></IraItemNameTemplateFilter>
            <Flex vertical={false} justify="right" className="add-wrapper">
                <Button
                    type="dashed"
                    icon={<PlusOutlined/>}
                    style={{marginTop: "32px"}}
                    onClick={async () => {
                        dataForm.resetFields();
                        setMode("insert");
                        setTemplateAdministrationModalOpened(true);
                    }}
                >
                    Novi predložak
                </Button>
            </Flex>
            <IraItemNameTemplateTable
                tableData={tableData}
                onFilterChanged={(pagination, filters, sorter) =>
                    onFilterChanged(pagination, filters, sorter)
                }
                setMode={setMode}
                setTemplateAdministrationModalOpened={setTemplateAdministrationModalOpened}
                setRowData={setRowData}
                style={{marginTop: "32px"}}
                pageNumber={pageNumber}
                pageSize={pageSize}
                totalRows={totalRows}
                bordered
                refreshData={() =>
                    fetchTemplates({projectId: ""})
                }
            />
            <ModalComponent
                title={formTitle()}
                show={templateAdministrationModalOpened}
                onOk={() => handleSubmit()}
                onCancel={() => {
                    setTemplateAdministrationModalOpened(false);
                }}
                loading={loading}
                showFooter={mode !== "preview"}
                width={"580px"}
            >
                <IraItemNameTemplateForm
                    form={dataForm}
                    mode={mode}
                    projectTypes={projectTypes}
                    onFinish={(values) => onDataSubmit(values)}
                    initialValues={mode === "insert" ? initialValues : rowData}
                    disabled={mode === "preview"}
                ></IraItemNameTemplateForm>
            </ModalComponent>
        </div>
    );
};

export default IraItemNameTemplateAdministrationPage;
