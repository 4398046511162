import React, { useEffect, useState } from "react";
import { Flex, Form, Input, Select, theme } from "antd";

import { isOibValid } from "../../../../utils/validationUtil.js";
import "./CompanyAdministrationForm.css";
import {axiosInstance} from "../../../../api/axiosInstance.js";
import { cities } from "../../../../constants/constants.js";

const CompanyAdministrationForm = ({
  form,
  mode,
  initialValues,
  owners,
  onFinish,
  disabled,
}) => {
  const { token } = theme.useToken();

  const formStyle = {
    maxWidth: "none",
    background: token.colorFillAlter,
    borderRadius: token.borderRadiusLG,
    padding: 24,
  };

  useEffect(() => {
    form.setFieldsValue(initialValues);
  }, [initialValues]);

  useEffect(() => {
    if (mode === "insert") {
      form.resetFields();
    }
  }, [mode]);

  const validateOIB = (rule, value, callback) => {
    if (isOibValid(value)) {
      if (mode !== "edit") {
        axiosInstance
          .get(`/api/v1/companies/check-oib-exists/${value}`)
          .then((res) => {
            if (res.data) {
              callback("Već postoji tvrtka sa istim OIB-om!");
            } else {
              callback();
            }
          });
      } else {
        callback();
      }
    } else {
      callback("Neispravan OIB");
    }
  };

  return (
    <Form
      form={form}
      name="companyAdministrationForm"
      style={formStyle}
      onFinish={(values) =>
        onFinish({
          ...values,
          id: initialValues?.id,
          address: { ...values.address, id: initialValues?.address?.id },
          owner: { ...values.owner, id: values.owner.id },
        })
      }
      initialValues={initialValues}
      layout={"vertical"}
      disabled={disabled}
    >
      <Form.Item
        name={`name`}
        label={`Naziv:`}
        rules={[
          {
            required: true,
            message: "Naziv je obavezno polje!",
          },
        ]}
      >
        <Input placeholder="Naziv tvrtke" />
      </Form.Item>
      <Form.Item
        hasFeedback={ true }
        name={`oib`}
        label={`OIB:`}
        rules={[
          {
            required: true,
            message: "OIB je obavezno polje!",
          },
          {
            validator: (rule, value, callback) =>
              validateOIB(rule, value, callback),
          },
        ]}
      >
        <Input placeholder="OIB tvrtke" maxLength={11} />
      </Form.Item>
      <Form.Item
        name={["address", "street"]}
        label={`Ulica:`}
        rules={[
          {
            required: false,
            message: "Ulica je obavezno polje!",
          },
        ]}
      >
        <Input placeholder="Ulica tvrtke" />
      </Form.Item>
      <Form.Item
        name={["address", "houseNumber"]}
        label={`Kućni broj:`}
        rules={[
          {
            required: false,
            message: "Kućni broj je obavezno polje!",
          },
        ]}
      >
        <Input placeholder="Kućni broj tvrtke" />
      </Form.Item>
      <Form.Item
        name={["address", "postNumber"]}
        label={`Poštanski broj:`}
        rules={[
          {
            required: false,
            message: "Poštanski broj je obavezno polje!",
          },
        ]}
      >
        <Input placeholder="Poštanski broj tvrtke" />
      </Form.Item>
      <Form.Item
        name={["address", "city"]}
        label={`Grad:`}
        rules={[
          {
            required: false,
            message: "Grad je obavezno polje!",
          },
        ]}
      >
        <Select
          options={cities}
          showSearch
          optionFilterProp="children"
          filterOption={(input, option) =>
            (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
          }
        ></Select>
      </Form.Item>
      <Form.Item
        name={["owner", "id"]}
        label={`Vlasnik tvrtke:`}
        rules={[
          {
            required: true,
            message: "Vlasnik tvrtke je obavezno polje!",
          },
        ]}
      >
        <Select options={owners}></Select>
      </Form.Item>
    </Form>
  );
};

export default CompanyAdministrationForm;
