import React, {useState, useEffect} from "react";

import {Button, Flex, Form, theme} from "antd";
import {PlusOutlined} from "@ant-design/icons";

import "../ProjectsPage/ProjectsPage.css";
import ProjectsForm from "../../components/Projects/ProjectsForm/ProjectsForm";
import ModalComponent from "../../components/ModalComponent/ModalComponent";
import ProjectsFilter from "../../components/Projects/ProjectsFilter/ProjectsFilter";
import ProjectsTable from "../../components/Projects/ProjectsTable/ProjectsTable";

import "../../api/axiosInstance";
import {axiosInstance} from "../../api/axiosInstance";
import {toast} from "react-toastify";

import {useLocation} from "react-router-dom";
import dayjs from "dayjs";
import {useSelector} from 'react-redux'

const ProjectsPage = (props) => {

    const [pageLoaded, setPageLoaded] = useState(false);

    const [mode, setMode] = useState("insert");

    const [selectedProjectId, setSelectedProjectId] = useState(null);
    const [selectedSubProjectId, setSelectedSubProjectId] = useState(null);

    const [filterForm] = Form.useForm();
    const [generalDataForm] = Form.useForm();
    const [specificDataForm] = Form.useForm();
    const [userMapDataForm] = Form.useForm();

    const [rowData, setRowData] = useState();

    const [tableData, setTableData] = useState([]);
    const [pageNumber, setPageNumber] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalRows, setTotalRows] = useState(0);

    const [loading, setLoading] = useState(false);
    const [projectModalOpened, setProjectModalOpened] = useState(false);
    const [locationFieldDisabled, setLocationFieldDisabled] = useState(true);
    const [companyFieldDisabled, setCompanyFieldDisabled] = useState(true);

    const [investors, setInvestors] = useState([]);
    const [owners, setOwners] = useState([]);
    const [users, setUsers] = useState([]);
    const [usersOnProject, setUsersOnProject] = useState([]);
    const [companies, setCompanies] = useState([]);
    const [locations, setLocations] = useState([]);
    const [statuses, setStatuses] = useState([]);
    const [projectSubTypes, setProjectSubTypes] = useState([]);
    const [billingStatuses, setBillingStatuses] = useState([]);
    const [mapTypes, setMapTypes] = useState([]);
    const [mapStatuses, setMapStatuses] = useState([]);

    const [activeKey, setActiveKey] = useState(["1"]);

    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [fileList, setFileList] = useState([]);

    const [userMapsErrors, setUserMapsErrors] = useState([]);

    const [showSpecificFormSection, setShowSpecificFormSection] = useState(false);
    const [isMainProjectSelected, setIsMainProjectSelected] = useState(true);

    const [investorContactTableData, setInvestorContactTableData] = useState([]);

    const [generalFormInitialValues, setGeneralFormInitialValues] = useState({
        proposedDeadline: dayjs(new Date()).add(1, "hour"),
        investor: {id: ""},
        usersOnProject: [],
        location: {id: ""},
        company: {id: ""},
        status: {id: ""},
        owner: {id: ""},
    });

    const [specificFormInitialValues, setSpecificFormInitialValues] = useState();
    const [userMapInitialValues, setUserMapInitialValues] = useState({mapTypes: []});

    const [uraData, setUraData] = useState([]);
    const [iraData, setIraData] = useState({});
    const [recapitulationData, setRecapitulationData] = useState([]);
    const [salaryData, setSalaryData] = useState([]);

    const certificateInitialValues = {
        datum: dayjs(new Date()),
        vrstaObjekta: '',
        datumPregleda: '',
        terenObavio: '',
        poslanoNaIzradu: '',
        certifikatIzradio: '',
        datumSlanjaPreuzimanja: '',
        svrha: ''
    }

    const energyRenewalInitialValues = {
        odradjenTeren: false,
        prijava: false,
        nias: '',
        certifikat: '',
        glavniProjektFotonapon: '',
        omp: '',
        dozvola: '',
        osobna: '',
        fotodokumentacija: false,
        vlasnickiList: '',
        potvrdaKucniBroj: '',
        fasada: '',
        stolarija: '',
        tavan: '',
        krov: '',
        grijanje: '',
        fn: '',
        solari: '',
        zidPremaNegrijanom: ''
    }

    const mainProjectInitialValues = {
        nazivZahvata: '',
        vrijednostInvesticije: '',
        vlasnikParcele: '',
        postojiIzgradnjaNaParceli: false,
        legalnostPostojecihZgrada: '',
        geodet: '',
        ogradaOkoParcele: '',
        svijetlaVisinaGradjevine: '',
        razlikaGotovPodTeren: '',
        strujaPostojecaPrikljucnaSnaga: '',
        strujaNovaPrikljucnaSnaga: '',
        voda: '',
        polozajPostojecegVodomjernogOkna: '',
        kanalizacija: '',
        polozajPostojecegKanalizacijskogPriljucka: '',
        plin: '',
        polozajPostojecegPlinskogPrikljucka: '',
        kolniPrilaz: '',
        polozajPostojecegKolnogPrilaza: '',
        brojParkirnihMjestaNaOtvorenom: '',
        brojParkirnihMjestaGaraza: '',
        grijanje: '',
        ptvRecirkulacija: '',
        ptvPriprema: '',
        sabirnaJama: '',
        planiraniBrojKorisnika: '',
        polozajPlinskogOrmarica: '',
        PolozajElektroOrmara: '',
        kolektoriZaPtv: '',
        fotonaponskaElektrana: '',
        dizalicaTopline: '',
        klime: '',
        uvjetizaNZeb: '',
        negrijaneProstorije: '',
        pod: '',
        krov: '',
        zidovi: '',
        medjukatnaKonstrukcija: '',
        stolarija: '',
        vizalizacija: '',
        troskovnik: '',
        iznosUTroskovniku: '',
        napomene: ''
    }

    const professionalSupervisionInitialValues = {
        sudionikUGradnji: '',
        vrstaRadova: '',
        kontaktImePrezime: '',
        kontaktSvojstvo: '',
        kontaktBrTel: '',
        kontaktMail: ''
    }

    const realEstateAppraisalInitialValues = {
        lokacija: '',
        datumOcevida: '',
        predmetProcjene: '',
        svrhaProcjene: '',
        iznosProcjene: 0,
        zgrada: '',
        godinaIzgradnje: null,
        godinaRekonstrukcije: null,
        dokazLegalnosti: '',
        katnost: '',
        teren: '',
        prikljucci: '',
        grijanje: '',
        temeljiObjekta: '',
        zid: '',
        strop: '',
        pod: '',
        kroviste: '',
        krovisteMaterijal: '',
        obradaProcelja: '',
        vanjskaStolarija: '',
        unutarnjaStolarija: '',
        energetskiCertifikat: '',
        ocevidIzvrsio: '',
        dodatneNapomene: ''
    }

    const elaboratesInitialValues = {
        svrhaIzrade: ''
    }

    const blowerDoorInitialValues = {
        svrhaIzrade: ''
    }

    const stagingInitialValues = {
        sudionikUGradnji: '',
        vrstaRadova: '',
        kontaktImePrezime: '',
        konaktSvojstvo: '',
        kontaktBrTel: '',
        kontaktMail: ''
    }

    const {token} = theme.useToken();

    let routeState = useLocation();

    useEffect(() => {
        const getSpecificData = () => {
            switch (routeState?.state?.projectTypeId) {
                case 1:
                    return mainProjectInitialValues;
                case 2:
                    return certificateInitialValues;
                case 3:
                    return energyRenewalInitialValues;
                case 4:
                    return professionalSupervisionInitialValues;
                case 5:
                    return realEstateAppraisalInitialValues;
                case 6:
                    return blowerDoorInitialValues;
                case 7:
                    return elaboratesInitialValues;
                case 8:
                    return stagingInitialValues;
                default:
                    return {};
            }
        }
        setSpecificFormInitialValues(getSpecificData())
        if (routeState?.state?.projectType === 'main') {
            setIsMainProjectSelected(true);
        } else {
            setIsMainProjectSelected(false);
        }
    }, [routeState?.state?.projectTypeId])

    const pageStyle = {
        maxWidth: "none",
        background: token.colorFillAlter,
        borderRadius: token.borderRadiusLG,
        padding: 24,
        height: "100%",
    };

    const fetchProjects = async (values) => {

        try {
            const response = await axiosInstance.post(
                `/api/v1/projects/find-by?page=${pageNumber - 1}&size=${pageSize}`,
                {
                    name: values?.name,
                    investorId: values?.investorId,
                    ownerId: values?.ownerId,
                    companyId: values?.companyId,
                    locationId: values?.locationId,
                    statusId: values?.statusId,
                    projectTypeId: routeState?.state?.projectTypeId ? routeState.state.projectTypeId : 1,
                    projectSubTypeId: values?.projectSubTypeId,
                    projectDeadline: values?.projectDeadline ? dayjs(values?.projectDeadline).add(2, "hour").toDate() : null,
                    billingStatusId: values?.billingStatusId,
                    proposedDeadlineSort: values?.proposedDeadlineSort ? values.proposedDeadlineSort : null,
                    priceSort: values?.priceSort ? values.priceSort : null,
                    paymentPercentageSort: values?.paymentPercentageSort ? values.paymentPercentageSort : null,
                    usersOnProject: [],
                }
            );

            setTableData(
                response?.data?.content.map((element) => {
                    return {
                        ...element,
                        key: element.id,
                    };
                })
            );
            setTotalRows(response?.data?.totalElements);
        } catch (ex) {
            console.log('Error while calling fetchProjects().')
        }

    };

    const fetchInvestors = () => {
        return axiosInstance.get("/api/v1/investors/find-all")
            .then(res => {
                const data = res.data.map((x) => {
                    return {value: x.id, label: x.name};
                });
                setInvestors([{value: "", label: "Prikaži sve"}].concat(data));
            }).catch(ex => {
                console.log('Error while calling fetchInvestors().')
            })
    };

    const fetchInvestorByProjectId = async (projectId) => {
        try {
            const res = await axiosInstance.get(`/api/v1/investors/find-by-project-id/${projectId}`);
            setInvestorContactTableData([res.data]);
        } catch (ex) {
            console.log('Error while calling fetchInvestorByProjectId().')

        }
    };

    const fetchUsers = (values) => {
        return axiosInstance.get(`/api/v1/users/find-all`).then(res => {
            const data = res.data.map((x) => {
                return {value: x.id, label: `${x.firstName} ${x.lastName}`, jobTitle: x.jobTitle};
            });
            setUsers([{value: -1, label: "Odaberi sve"}].concat(data));
            setOwners([{value: "", label: "Prikaži sve"}].concat(data.filter(x => x.jobTitle === 'direktor')));
        }).catch(ex => {
            console.log('Error while calling fetchUsers().')
        });
    };

    const fetchUsersByProjectId = async (projectId) => {
        try {
            const res = await axiosInstance.get(
                `/api/v1/user-project/find-all-by-project-id/${projectId}`
            );
            const data = res.data.map((x) => {
                return {value: x.id, label: `${x.firstName} ${x.lastName}`};
            });
            setUsersOnProject(data);
        } catch (ex) {
            console.log('Error while calling fetchUsersByProjectId().')
        }
    };

    const fetchCompanies = (values) => {
        return axiosInstance.get(`/api/v1/companies/find-all`).then(res => {
            const data = res.data.map((x) => {
                return {value: x.id, label: `${x.name}`};
            });
            setCompanies([{value: "", label: "Prikaži sve"}].concat(data));
        }).catch(ex => {
            console.log('Error while calling fetchCompanies().')
        });
    };

    const fetchLocations = (values) => {
        return axiosInstance.get(`/api/v1/locations/find-all`).then(res => {
            const data = res.data.map((x) => {
                return {
                    value: x.id,
                    label: `${x.cadastralParcel} - ${x.cadastralMunicipality}`,
                    cadastralMunicipality: x.cadastralMunicipality
                };
            });
            setLocations([{value: "", label: "Prikaži sve"}].concat(data));
        }).catch(ex => {
            console.log('Error while calling fetchLocations().')
        });
    };

    const fetchStatuses = (values) => {
        return axiosInstance.get(`/api/v1/project-statuses/find-all`).then(res => {
            const data = res.data.map((x) => {
                return {value: x.id, label: `${x.name}`};
            });
            setStatuses([{value: "", label: "Prikaži sve"}].concat(data));
        }).catch(ex => {
            console.log('Error while calling fetchStatuses().')
        });
    };

    const fetchBillingStatuses = (values) => {
        return axiosInstance.get(`/api/v1/billing-statuses/find-all`).then(res => {
            const data = res.data.map((x) => {
                return {value: x.id, label: `${x.description}`};
            });
            setBillingStatuses([{value: "", label: "Prikaži sve"}].concat(data));
        }).catch(ex => {
            console.log('Error while calling fetchBillingStatuses().')
        });
    };


    const fetchProjectSubTypes = (values) => {
        return axiosInstance.get(`/api/v1/project-types/find-by-parent-id/${routeState?.state?.projectTypeId ? routeState.state.projectTypeId : 1}`)
            .then(res => {
                const data = res.data.map((x) => {
                    return {value: x.id, label: `${x.name}`};
                });
                setProjectSubTypes([{value: "", label: "Prikaži sve"}].concat(data));
            }).catch(ex => {
                console.log('Error while calling fetchProjectSubTypes()');
            });
    };

    const fetchMapTypes = (values) => {
        return axiosInstance.get(`/api/v1/maps/codebook/map-types`).then(res => {
            const data = res.data.map((x) => {
                return {value: x.id, label: `${x.name}`};
            });
            setMapTypes(data);
        }).catch(ex => {
            console.log('Error while calling fetchMapTypes()');
        });
    };

    const fetchMapStatuses = (values) => {
        return axiosInstance.get(`/api/v1/maps/codebook/map-statuses`).then(res => {
            const data = res.data.map((x) => {
                return {value: x.id, label: `${x.name}`};
            });
            setMapStatuses(data);
        }).catch(ex => {
            console.log('Error while calling fetchMapStatuses()');
        });
    };

    const fetchUsersOnMap = async (projectId, projectSubTypeId) => {
        try {
            const res = await axiosInstance.get(`/api/v1/user-project/find-all-maps-by/project/${projectId ? projectId : selectedProjectId}/project-type/${projectSubTypeId}`);
            setUserMapInitialValues({mapTypes: [...res.data]});
        } catch (ex) {
            console.log('Error while calling fetchUsersOnMap()');
        }
    }

    const fetchUras = async (projectId) => {
        try {
            const res = await axiosInstance.get(`/api/v1/ura/project/${projectId}`);
            setUraData(res.data)
        } catch (ex) {
            console.log('Error while calling fetchUras()');
        }
    }

    const fetchIras = async (projectId) => {
        try {
            const res = await axiosInstance.get(`/api/v1/ira/recapitulation/${projectId}`);
            setIraData(res.data)
        } catch (ex) {
            console.log('Error while calling fetchIras()');
        }
    }

    const fetchRecapitulationData = async (projectId) => {
        try {
            const res = await axiosInstance.get(`/api/v1/projects/recapitulation/${projectId}`);
            setRecapitulationData(res.data)
        } catch (ex) {
            console.log('Error while calling fetchRecapitulationData()');
        }
    }

    const fetchSalaries = async (projectId) => {
        try {
            const res = await axiosInstance.get(`/api/v1/employee-time-tracking/find-by-project-id/${projectId}`);
            setSalaryData(res.data)
        } catch (ex) {
            console.log('Error while calling fetchSalaries()');
        }
    }

    const fetchCodebooks = async () => {
        try {
            if (!pageLoaded) {
                Promise.all([fetchInvestors(), fetchUsers(), fetchCompanies(), fetchLocations(), fetchStatuses(),
                    fetchBillingStatuses(), fetchMapTypes(), fetchMapStatuses()])
            }
            fetchProjectSubTypes();
        } catch (ex) {
            console.log("Sjednica je istekla!");
        }
    };

    useEffect(() => {
        setPageLoaded(true)
    }, [])

    useEffect(() => {
        fetchProjects({
            name: "",
            investorId: null,
            ownerId: null,
            companyId: null,
            locationId: null,
            statusId: null,
        });
        fetchCodebooks();
    }, [pageNumber, pageSize, routeState?.state?.projectTypeId]);

    useEffect(() => {
        if (selectedProjectId) {
            setLocationFieldDisabled(false);
            setCompanyFieldDisabled(false);
        }
    }, [selectedProjectId])

    useEffect(() => {
        if (selectedSubProjectId === -1 || selectedSubProjectId === 16 || selectedSubProjectId == 17) {
            setShowSpecificFormSection(false);
        } else {
            setShowSpecificFormSection(true);
        }
    }, [selectedSubProjectId])

    const onFormFilterSubmit = async (values) => {
        fetchProjects(values);
    };

    const onProjectDataSubmit = ({generalData, specificData, userMapData}) => {

        const mappedValues = {
            ...generalData,
            additionalFields: specificData.additionalFields,
            id: selectedProjectId,
            investor: {...generalData.investor, id: generalData?.investor?.id},
            owner: {...generalData.owner, id: generalData?.owner?.id},
            company: {...generalData.company, id: generalData?.company?.id},
            location: {...generalData.location, id: generalData?.location?.id},
            status: {...generalData.status, id: generalData?.status?.id},
            mapType: {...generalData.mapType, id: generalData?.mapType?.id},
            mapStatus: {...generalData.mapStatus, id: generalData?.mapStatus?.id},
            userMapData: userMapData.mapTypes
        };

        if (mode === "insert") {

            const formData = new FormData();
            const projectBlob = new Blob([JSON.stringify({
                ...mappedValues,
                projectTypeId: selectedSubProjectId,
                proposedDeadline: dayjs(mappedValues.proposedDeadline).add(1, "hour"),
            })], {type: "application/json"})

            formData.append('projectDto', projectBlob);
            if (uploadedFiles) {
                uploadedFiles.forEach(file => {
                    formData.append('files', file);
                })
            }

            axiosInstance
                .post("/api/v1/projects", formData, {
                    headers: {
                        'content-type': 'multipart/form-data'
                    }
                })
                .then((res) => {
                    setLoading(false);
                    setProjectModalOpened(false);
                    generalDataForm.resetFields();
                    specificDataForm.resetFields();
                    setUploadedFiles([]);
                    toast.success("Posao uspješno spremljen!");
                    fetchProjects({
                        name: "",
                        investorId: null,
                        ownerId: null,
                        companyId: null,
                        locationId: null,
                        statusId: null,
                    });
                })
                .catch((ex) => {
                    console.log(ex, "ex");
                    toast.error("Došlo je do greške prilikom spremanja posla. " + ex?.response?.data?.errorMessage);
                });
        } else if (mode === "edit") {

            const formData = new FormData();

            const projectBlob = new Blob([JSON.stringify({
                ...mappedValues,
                projectTypeId: selectedSubProjectId,
                proposedDeadline: dayjs(mappedValues.proposedDeadline).add(2, "hour"),
            })], {type: "application/json"})

            formData.append('projectDto', projectBlob);

            console.log(mappedValues, 'mappedValues')

            if (uploadedFiles) {
                uploadedFiles.forEach(file => {
                    formData.append('files', file);
                })
            }

            axiosInstance
                .patch("/api/v1/projects", formData, {
                    headers: {
                        'content-type': 'multipart/form-data'
                    }
                })
                .then((res) => {
                    setLoading(false);
                    setProjectModalOpened(false);
                    generalDataForm.resetFields();
                    specificDataForm.resetFields();
                    toast.success("Posao uspješno ažuriran!");
                    fetchProjects({
                        name: "",
                        investorId: null,
                        ownerId: null,
                        companyId: null,
                        locationId: null,
                        statusId: null,
                    });
                })
                .catch((ex) => {
                    toast.error("Došlo je do greške prilikom ažuriranja posla!");
                });
        }
        setCompanyFieldDisabled(true);
        setLocationFieldDisabled(true);
    };

    const onFilterChanged = async (pagination, filters, sorter) => {
        setPageNumber(pagination.current);
        setPageSize(pagination.pageSize);

        console.log(sorter, 'sorter')

        if (Array.isArray(sorter)) {
            const fetchColumn = (columnName) => sorter.filter(x => x.columnKey === columnName)[0];

            const proposedDeadlineField = fetchColumn("proposedDeadline");
            const priceField = fetchColumn("price");
            const paymentPercentageField = fetchColumn("paymentPercentage");

            let proposedDeadlineSort;
            if(proposedDeadlineField?.order){
                proposedDeadlineSort = proposedDeadlineField.order === 'descend' ? "NEWEST" : "OLDEST"
            }

            let priceSort;
            if(priceField?.order){
                priceSort = priceField.order === 'descend' ? "DESC" : "ASC";
            }

            let paymentPercentageSort;
            if(paymentPercentageField?.order){
                paymentPercentageSort = paymentPercentageField.order === 'descend' ? "BIGGEST" : "SMALLEST";
            }

            fetchProjects({
                ...filterForm.getFieldsValue(),
                proposedDeadlineSort: proposedDeadlineSort,
                priceSort: priceSort,
                paymentPercentageSort: paymentPercentageSort
            })

        } else {
            if (sorter?.columnKey === 'proposedDeadline') {
                if(sorter.order === 'descend'){
                    fetchProjects({...filterForm.getFieldsValue(), proposedDeadlineSort: "NEWEST"})
                } else if(sorter.order === 'ascend'){
                    fetchProjects({...filterForm.getFieldsValue(), proposedDeadlineSort: "OLDEST"})
                } else {
                    fetchProjects({...filterForm.getFieldsValue(), proposedDeadlineSort: null})
                }

            }

            if (sorter?.columnKey === 'price') {
                if(sorter.order === 'descend'){
                    fetchProjects({...filterForm.getFieldsValue(), priceSort: "DESC"})
                } else if(sorter.order === 'ascend'){
                    fetchProjects({...filterForm.getFieldsValue(), priceSort: "ASC"})
                } else {
                    fetchProjects({...filterForm.getFieldsValue(), priceSort: null})
                }
            }
            if (sorter?.columnKey === 'paymentPercentage') {
                if(sorter.order === 'descend'){
                    fetchProjects({...filterForm.getFieldsValue(), paymentPercentageSort: "SMALLEST"})
                } else if(sorter.order === 'ascend'){
                    fetchProjects({...filterForm.getFieldsValue(), paymentPercentageSort: "BIGGEST"})
                } else {
                    fetchProjects({...filterForm.getFieldsValue(), paymentPercentageSort: null})
                }
            }
        }
    };

    const handleSubmitProject = async () => {


        let validationResultGeneralForm;
        let validationResultSpecificForm;
        let validationResultUserMapForm;

        let tGeneralFormHasErrors = false;
        let tSpecificFormHasErrors = false;
        let tUserMapFormHasErrors = false;

        try {
            validationResultGeneralForm = await generalDataForm.validateFields();
            if (!validationResultGeneralForm.errorFields) {
                generalDataForm.submit();
                //setLoading(true);
            }
        } catch (ex) {
            console.log(ex);
            tGeneralFormHasErrors = true;
        }

        try {
            validationResultSpecificForm = await specificDataForm.validateFields();
            if (!validationResultSpecificForm.errorFields) {
                specificDataForm.submit();
                //setLoading(true);
            }
        } catch (ex) {
            console.log(ex);
            tSpecificFormHasErrors = true;
        }


        try {
            validationResultUserMapForm = await userMapDataForm.validateFields();
            if (!validationResultUserMapForm.errorFields) {
                userMapDataForm.submit();
                //setLoading(true);
            }
        } catch (ex) {
            console.log(ex);
            tUserMapFormHasErrors = true;
            setUserMapsErrors(ex.errorFields)
        }

        if (tGeneralFormHasErrors && !tSpecificFormHasErrors) {
            setActiveKey(["1", "3", "4"]);
        } else if (tGeneralFormHasErrors && tSpecificFormHasErrors) {
            setActiveKey(["1", "2", "3", "4"]);
        } else if (tSpecificFormHasErrors && !tGeneralFormHasErrors) {
            setActiveKey(["2", "3", "4"]);
        }

        if (tGeneralFormHasErrors || tSpecificFormHasErrors || tUserMapFormHasErrors) {
        } else {
            onProjectDataSubmit({
                generalData: generalDataForm.getFieldsValue(),
                specificData: specificDataForm.getFieldsValue(),
                userMapData: userMapDataForm.getFieldsValue(),
            });
        }
        setFileList([]);
        setUploadedFiles([]);
        setCompanyFieldDisabled(true);
        setLocationFieldDisabled(true);
        setActiveKey(["1", "4"])
    };

    const generalDataFormTitle = () => {
        if (mode === "insert") {
            return "Novi posao";
        } else if (mode === "edit") {
            return "Uređivanje posla";
        } else {
            return "Pregled detalja posla";
        }
    };

    const getPageTitle = () => {
        switch (routeState?.state?.projectType) {
            case "main":
                return "Glavni projekt";
            case "certificates":
                return "Certifikati";
            case "energy-renewal":
                return "Energetske obnove";
            case "professional-supervision":
                return "Stručni nadzor";
            case "real-estate-appraisal":
                return "Procjena nekretnina";
            case "blower-door":
                return "Blower Door";
            case "elaborates":
                return "Elaborati";
            case "staging":
                return "Izvođenje";
            case "all-projects":
                return "Svi poslovi";
            default:
                return "Glavni projekt";
        }
    };

    const handleFieldsChange = async (formName, changedValues, allValues) => {
        if (formName === 'userMapForm') {
            try {
                userMapDataForm.validateFields();
                setUserMapsErrors([]);
            } catch (ex) {
                setUserMapsErrors(ex.errorFields);
            }
        }

        if (formName === 'generalDataForm') {
            if (changedValues.projectSubTypeId) {
                setSelectedSubProjectId(changedValues.projectSubTypeId);
                if (mode === 'edit' || mode === 'preview') {
                    fetchUsersOnMap(selectedProjectId, changedValues.projectSubTypeId);
                }
            }

            if (!allValues.projectSubTypeId) {
                setSelectedSubProjectId(-1);
                if (mode === 'edit' || mode === 'preview') {
                    setUserMapInitialValues({
                        mapTypes: [],
                    });
                }
            }

            if (!allValues.investor.id) {
                setLocationFieldDisabled(true);
            } else {
                setLocationFieldDisabled(false);
            }

            if (!allValues.owner.id) {
                setCompanyFieldDisabled(true);
            } else {
                setCompanyFieldDisabled(false);
            }
        }
    }

    return (
        <div className={"projects-page"} style={pageStyle}>
            <h3>{getPageTitle()}</h3>
            <ProjectsFilter
                form={filterForm}
                onFormFilterSubmit={onFormFilterSubmit}
                investors={investors}
                owners={owners}
                companies={companies}
                locations={locations}
                statuses={statuses}
                billingStatuses={billingStatuses}
                projectSubTypes={projectSubTypes}
                mapTypes={mapTypes}
                mapStatuses={mapStatuses}
                showProjectSubTypeField={routeState?.state?.projectType !== 'all-projects'}
            ></ProjectsFilter>
            {routeState?.state?.projectType !== 'all-projects' ?
                <Flex vertical={false} justify="right" className="add-project-wrapper">
                    <Button
                        disabled={props?.userData?.authorities?.includes('ROLE_ZAPOSLENIK')}
                        type="dashed"
                        icon={<PlusOutlined/>}
                        style={{marginTop: "32px"}}
                        onClick={() => {
                            generalDataForm.resetFields();
                            specificDataForm.resetFields();
                            setMode("insert");
                            setSelectedSubProjectId(-1);
                            // TODO: provjeriti ovo
                            setSelectedProjectId(null)
                            setUserMapInitialValues({
                                mapTypes: [],
                            })
                            setFileList([]);
                            setRowData(null);
                            setUploadedFiles([]);
                            setProjectModalOpened(true);
                        }}
                    >
                        Novi posao
                    </Button>
                </Flex> : <></>
            }
            <ProjectsTable
                userData={props.userData}
                tableData={tableData}
                onFilterChanged={(pagination, filters, sorter) =>
                    onFilterChanged(pagination, filters, sorter)
                }
                setMode={setMode}
                setProjectModalOpened={setProjectModalOpened}
                setRowData={setRowData}
                setFileList={setFileList}
                style={{marginTop: "32px"}}
                pageNumber={pageNumber}
                pageSize={pageSize}
                totalRows={totalRows}
                bordered
                refreshData={() =>
                    fetchProjects({
                        name: "",
                        investorId: null,
                        ownerId: null,
                        companyId: null,
                        locationId: null,
                        statusId: null,
                    })
                }
                fetchUsersByProjectId={fetchUsersByProjectId}
                fetchUsersOnMap={(projectId, projectSubTypeId) => fetchUsersOnMap(projectId, projectSubTypeId)}
                fetchUras={async (projectId) => await fetchUras(projectId)}
                fetchIras={async (projectId) => await fetchIras(projectId)}
                fetchRecapitulationData={async (projectId) => await fetchRecapitulationData(projectId)}
                fetchSalaries={async (projectId) => await fetchSalaries(projectId)}
                fetchInvestorByProjectId={(projectId) => fetchInvestorByProjectId(projectId)}
                setSelectedProjectId={setSelectedProjectId}
                setSelectedSubProjectId={setSelectedSubProjectId}
                readOnly={routeState?.state?.projectType === 'all-projects'}
            />
            <ModalComponent
                title={generalDataFormTitle()}
                show={projectModalOpened}
                onOk={handleSubmitProject}
                onCancel={() => {
                    setFileList([]);
                    setUploadedFiles([]);
                    setCompanyFieldDisabled(true);
                    setLocationFieldDisabled(true);
                    setProjectModalOpened(false);
                    setActiveKey(["1", "4"])
                }}
                loading={loading}
                showFooter={mode !== "preview"}
                width={"800px"}
            >
                <ProjectsForm
                    userData={props.userData}
                    generalDataForm={generalDataForm}
                    specificDataForm={specificDataForm}
                    userMapDataForm={userMapDataForm}
                    mode={mode}
                    rowData={rowData}
                    uraData={uraData}
                    iraData={iraData}
                    recapitulationData={recapitulationData}
                    salaryData={salaryData}
                    generalFormInitialValues={
                        mode === "insert" ? generalFormInitialValues : rowData
                    }
                    specificFormInitialValues={
                        mode === "insert" ? specificFormInitialValues : rowData
                    }
                    userMapInitialValues={userMapInitialValues}
                    disabled={mode === "preview"}
                    investors={investors}
                    owners={owners}
                    companies={companies}
                    locations={locations}
                    statuses={statuses}
                    mapTypes={mapTypes}
                    mapStatuses={mapStatuses}
                    projectSubTypes={projectSubTypes}
                    users={users}
                    usersOnProject={usersOnProject}
                    activeKey={activeKey}
                    setActiveKey={setActiveKey}
                    projectTypeId={routeState?.state?.projectTypeId}
                    selectedSubProjectId={selectedSubProjectId}
                    isMainProjectForm={isMainProjectSelected}
                    isAllProjects={routeState?.state?.projectType === 'all-projects'}
                    locationFieldDisabled={locationFieldDisabled}
                    companyFieldDisabled={companyFieldDisabled}
                    setUploadedFiles={setUploadedFiles}
                    fileList={fileList}
                    setFileList={setFileList}
                    userMapsErrors={userMapsErrors}
                    setUserMapsErrors={setUserMapsErrors}
                    showSpecificFormSection={showSpecificFormSection}
                    handleFieldsChange={(formName, changedValues, allValues) => handleFieldsChange(formName, changedValues, allValues)}
                    investorContactTableData={investorContactTableData}
                ></ProjectsForm>
            </ModalComponent>
        </div>
    );
};

export default ProjectsPage;
