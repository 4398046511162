import React, {useEffect, useState} from "react";

import {axiosInstance} from "../../api/axiosInstance";

import {toast} from "react-toastify";

import {Button, Form, Flex, theme} from "antd";
import {PlusOutlined} from "@ant-design/icons";

import ModalComponent from "../../components/ModalComponent/ModalComponent";

import "./EmployeeTimeTrackingPage.css";
import SalaryFilter from "../../components/Salary/SalaryFilter/SalaryFilter";
import SalaryTable from "../../components/Salary/SalaryTable/SalaryTable";
import SalaryForm from "../../components/Salary/SalaryForm/SalaryForm";
import dayjs from "dayjs";
import EmployeeTimeTrackingFilter
    from "../../components/EmployeeTimeTracking/EmployeeTimeTrackingFilter/EmployeeTimeTrackingFilter";
import EmployeeTimeTrackingTable
    from "../../components/EmployeeTimeTracking/EmployeeTimeTrackingTable/EmployeeTimeTrackingTable";

const EmployeeTimeTrackingPage = (props) => {

        const [mode, setMode] = useState("insert");

        const [filterForm] = Form.useForm();
        const [salaryForm] = Form.useForm();
        const [rowData, setRowData] = useState(null);

        const [tableData, setTableData] = useState([]);
        const [pageNumber, setPageNumber] = useState(1);
        const [pageSize, setPageSize] = useState(10);
        const [totalRows, setTotalRows] = useState(0);
        const [users, setUsers] = useState(false);

        const [userId, setUserId] = useState(null);

        const [loading, setLoading] = useState(false);
        const [salaryAdministrationModalOpened, setSalaryAdministrationModalOpened] =
            useState(false);

        const {token} = theme.useToken();

        const pageStyle = {
            maxWidth: "none",
            background: token.colorFillAlter,
            borderRadius: token.borderRadiusLG,
            padding: 24,
            height: "100%",
        };

        const fetchUsers = async (values) => {
            try {
                const res = await axiosInstance.get(`/api/v1/users/find-all`);
                const data = res.data.map((x) => {
                    return {value: x.id, label: `${x.firstName} ${x.lastName}`, jobTitle: x.jobTitle};
                });
                setUsers([{value: '', label: "Odaberi sve"}].concat(data));
            } catch (ex) {
                console.log('Error while calling fetchUsers().')
            }
        };

        const fetchEmployeeTimeTracking = async (userId, year, month, hourlyPriceSort, numberOfHoursSort, totalPriceSort) => {

            try {
                const response = await axiosInstance.post(
                    `/api/v1/employee-time-tracking/find-by?page=${pageNumber - 1}&size=${pageSize}`,
                    {
                        userId: userId,
                        year: year,
                        month: month,
                        hourlyPriceSort: hourlyPriceSort,
                        numberOfHoursSort: numberOfHoursSort,
                        totalPriceSort: totalPriceSort,
                    }
                );

                setTableData(
                    response?.data?.content.map((element) => {
                        return {
                            ...element,
                            key: element.id,
                        };
                    })
                );

                setTotalRows(response?.data?.totalElements);
            } catch (ex) {
                console.log('Error while calling fetchSalaries().')
            }

        };

        useEffect(() => {
            fetchUsers();
            fetchEmployeeTimeTracking(userId, dayjs(filterForm.getFieldValue("year")).year(), filterForm.getFieldValue("month"));
        }, [pageNumber, pageSize]);

        const onFormFilterSubmit = async (values) => {
            fetchEmployeeTimeTracking(values.userId, dayjs(values.year).year(), values.month);
        };

        const onFilterChanged = async (pagination, filters, sorter) => {
            setPageNumber(pagination.current);
            setPageSize(pagination.pageSize);

            if (Array.isArray(sorter)) {
                const fetchColumn = (columnName) => sorter.filter(x => x.columnKey === columnName)[0];

                const hourlyPriceField = fetchColumn("hourlyPrice");
                const numberOfHoursField = fetchColumn("numberOfHours");
                const totalPriceField = fetchColumn("totalPrice");

                let hourlyPriceSort;
                if (hourlyPriceField?.order) {
                    hourlyPriceSort = hourlyPriceField.order === 'descend' ? "DESC" : "ASC"
                }

                let numberOfHoursSort;
                if (numberOfHoursField?.order) {
                    numberOfHoursSort = numberOfHoursField.order === 'descend' ? "DESC" : "ASC";
                }

                let totalPriceSort;
                if (totalPriceField?.order) {
                    totalPriceSort = totalPriceField.order === 'descend' ? "DESC" : "ASC";
                }

                fetchEmployeeTimeTracking(
                    userId,
                    dayjs(filterForm.getFieldValue("year")).year(),
                    filterForm.getFieldValue("month"),
                    hourlyPriceSort,
                    numberOfHoursSort,
                    totalPriceSort
                )

            } else {

                let hourlyPriceSort;
                let numberOfHoursSort;
                let totalPriceSort;

                if (sorter?.columnKey === 'hourlyPrice') {
                    if (sorter.order === "descend") {
                        hourlyPriceSort = "DESC";
                    } else if (sorter.order === 'ascend') {
                        hourlyPriceSort = "ASC";
                    } else {
                        hourlyPriceSort = null;
                    }
                }

                if (sorter?.columnKey === 'numberOfHours') {
                    if (sorter.order === 'descend') {
                        numberOfHoursSort = "DESC";
                    } else if (sorter.order === 'ascend') {
                        numberOfHoursSort = "ASC"
                    } else {
                        numberOfHoursSort = null;
                    }
                }

                if (sorter?.columnKey === 'totalPrice') {
                    if (sorter.order === 'descend') {
                        totalPriceSort = "DESC";
                    } else if (sorter.order === 'ascend') {
                        totalPriceSort = "ASC"
                    } else {
                        totalPriceSort = null;
                    }
                }

                fetchEmployeeTimeTracking(
                    userId,
                    dayjs(filterForm.getFieldValue("year")).year(),
                    filterForm.getFieldValue("month"),
                    hourlyPriceSort,
                    numberOfHoursSort,
                    totalPriceSort
                )
            }
        }


        return (
            <div className={"salary-page"} style={pageStyle}>
                <h3>Evidencija vremena</h3>
                <EmployeeTimeTrackingFilter
                    form={filterForm}
                    onFormFilterSubmit={onFormFilterSubmit}
                    users={users}
                    setUserId={setUserId}
                ></EmployeeTimeTrackingFilter>
                <EmployeeTimeTrackingTable
                    tableData={tableData}
                    onFilterChanged={(pagination, filters, sorter) =>
                        onFilterChanged(pagination, filters, sorter)
                    }
                    pageNumber={pageNumber}
                    pageSize={pageSize}
                    totalRows={totalRows}
                    bordered
                />


            </div>
        );
    }
;

export default EmployeeTimeTrackingPage;
