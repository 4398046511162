import React, { useEffect, useState } from "react";

import {axiosInstance} from "../../api/axiosInstance";

import { toast } from "react-toastify";

import { Button, Form, Flex, theme } from "antd";
import { PlusOutlined } from "@ant-design/icons";

import ModalComponent from "../../components/ModalComponent/ModalComponent";

import "./SupplierAdministrationPage.css";
import SupplierAdministrationFilter from "../../components/Administration/Suppliers/SupplierAdministrationFilter/SupplierAdministrationFilter";
import SupplierAdministrationForm from "../../components/Administration/Suppliers/SupplierAdministrationForm/SupplierAdministrationForm";
import SupplierAdministrationTable from "../../components/Administration/Suppliers/SupplierAdministrationTable/SupplierAdministrationTable";

const SupplierAdministrationPage = (props) => {
  const [mode, setMode] = useState("insert");

  const [filterForm] = Form.useForm();
  const [supplierDataForm] = Form.useForm();
  const [rowData, setRowData] = useState();

  const [expenseTypes, setExpenseTypes] = useState([]);


  const [tableData, setTableData] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalRows, setTotalRows] = useState(0);

  const [loading, setLoading] = useState(false);
  const [
    supplierAdministrationModalOpened,
    setSupplierAdministrationModalOpened,
  ] = useState(false);

  const { token } = theme.useToken();

  const pageStyle = {
    maxWidth: "none",
    background: token.colorFillAlter,
    borderRadius: token.borderRadiusLG,
    padding: 24,
    height: "100%",
  };

  const [initialValues, setInitialValues] = useState({
    name: "",
    expenseType: {id: ""},
    oib: "",
    owner: { id: "" },
    city: ""
  });

  const fetchSuppliers = async (values) => {
    try {
      const response = await axiosInstance.get(
        `/api/v1/suppliers/find-by?searchTerm=${values.searchTerm}&page=${
          pageNumber - 1
        }&size=${pageSize}`
      );
  
      setTableData(
        response?.data?.content.map((element) => {
          return {
            ...element,
            key: element.id,
          };
        })
      );
      setTotalRows(response?.data?.totalElements);  
    } catch (ex) {
      console.log('Error happened while calling fetchSuppliers()');
    }
  };

    const fetchExpenseTypes = async (values) => {
        try {
            const res = await axiosInstance.get(`/api/v1/expense-types/find-all`);
            const expenseTypes = res.data.filter(x => x.parentId == null).map((x) => {
                return {value: x.id, label: `${x.name}`};
            });
            setExpenseTypes([{value: "", label: "Prikaži sve"}].concat(expenseTypes));
        } catch (ex) {
            console.log('Error while calling fetchExpenseTypes().')
        }
    };


    useEffect(() => {
    fetchSuppliers({ searchTerm: "" });
    fetchExpenseTypes()
}, [pageNumber, pageSize]);

  const onFormFilterSubmit = async (values) => {
    fetchSuppliers(values);
  };

  const onSupplierDataSubmit = (values) => {
    if (mode === "insert") {
      axiosInstance
        .post("/api/v1/suppliers", values)
        .then((res) => {
          setLoading(false);
          setSupplierAdministrationModalOpened(false);
          supplierDataForm.resetFields();
          toast.success("Dobavljač uspješno spremljen!");
          fetchSuppliers({ searchTerm: "" });
        })
        .catch((ex) => {
          toast.error("Došlo je do greške prilikom spremanja dobavljača!");
        });
    } else if (mode === "edit") {
      axiosInstance
        .patch("/api/v1/suppliers", values)
        .then((res) => {
          setLoading(false);
          setSupplierAdministrationModalOpened(false);
          supplierDataForm.resetFields();
          toast.success("Dobavljač uspješno ažuriran!");
          fetchSuppliers({ searchTerm: "" });
        })
        .catch((ex) => {
          toast.error("Došlo je do greške prilikom ažuriranja dobavljača!");
        });
    }
  };

  const onFilterChanged = async (pagination, filters, sorter) => {
    setPageNumber(pagination.current);
    setPageSize(pagination.pageSize);
  };

  const handleSubmitSupplier = async () => {
    try {
      const validationResult = await supplierDataForm.validateFields();
      if (!validationResult.errorFields) {
        supplierDataForm.submit();
        setLoading(true);
      }
    } catch (ex) {
      console.log(ex);
    }
  };

  const supplierDataFormTitle = () => {
    if (mode === "insert") {
      return "Novi dobavljač";
    } else if (mode === "edit") {
      return "Uređivanje dobavljača";
    } else {
      return "Pregled detalja dobavljača";
    }
  };

  return (
    <div className={"supplier-page"} style={pageStyle}>
      <h3>Administracija dobavljača</h3>
      <SupplierAdministrationFilter
        form={filterForm}
        onFormFilterSubmit={onFormFilterSubmit}
      ></SupplierAdministrationFilter>
      <Flex vertical={false} justify="right" className="add-supplier-wrapper">
        <Button
          type="dashed"
          icon={<PlusOutlined />}
          style={{ marginTop: "32px" }}
          onClick={async () => {
            supplierDataForm.resetFields();
            setMode("insert");
            setSupplierAdministrationModalOpened(true);
          }}
        >
          Novi dobavljač
        </Button>
      </Flex>
      <SupplierAdministrationTable
        tableData={tableData}
        onFilterChanged={(pagination, filters, sorter) =>
          onFilterChanged(pagination, filters, sorter)
        }
        setMode={setMode}
        setSupplierAdministrationModalOpened={
          setSupplierAdministrationModalOpened
        }
        setRowData={setRowData}
        style={{ marginTop: "32px" }}
        pageNumber={pageNumber}
        pageSize={pageSize}
        totalRows={totalRows}
        bordered
        refreshData={() => fetchSuppliers({ searchTerm: "" })}
      />
      <ModalComponent
        title={supplierDataFormTitle()}
        show={supplierAdministrationModalOpened}
        onOk={handleSubmitSupplier}
        onCancel={() => {
          setSupplierAdministrationModalOpened(false);
        }}
        loading={loading}
        showFooter={mode !== "preview"}
        width={"580px"}
      >
        <SupplierAdministrationForm
          form={supplierDataForm}
          mode={mode}
          onFinish={onSupplierDataSubmit}
          initialValues={mode === "insert" ? initialValues : rowData}
          disabled={mode === "preview"}
          expenseTypes={expenseTypes}
        ></SupplierAdministrationForm>
      </ModalComponent>
    </div>
  );
};

export default SupplierAdministrationPage;
