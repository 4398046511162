import React, {useMemo, useState} from "react";
import {Table, Button, Flex, Tooltip, Modal, Space, Empty} from "antd";
import {
    EditOutlined,
    SearchOutlined,
    DeleteOutlined,
    ExclamationCircleOutlined,
} from "@ant-design/icons";

import '../../../../api/axiosInstance'
import {axiosInstance} from "../../../../api/axiosInstance";
import {toast} from "react-toastify";

const IraItemNameTemplateTable = ({
                                      tableData,
                                      pageNumber,
                                      pageSize,
                                      totalRows,
                                      onFilterChanged,
                                      setMode,
                                      setTemplateAdministrationModalOpened,
                                      setRowData,
                                      refreshData,
                                  }) => {
    const [arrow, setArrow] = useState("Show");

    const [modal, contextHolder] = Modal.useModal();

    const confirm = (id) => {
        modal.confirm({
            title: "Potvrda",
            icon: <ExclamationCircleOutlined/>,
            content: "Jeste li sigurni da želite obrisati predložak?",
            okText: "Da",
            cancelText: "Ne",
            centered: true,
            onOk: () => {
                axiosInstance.delete(`/api/v1/ira-item-template/${id}`)
                    .then(res => {
                        toast.success("Predložak je uspješno obrisan!");
                        refreshData();
                    }).catch(ex => {
                    toast.error(`${ex.response.data.errorMessage}`);
                })
            },
        });
    };

    const mergedArrow = useMemo(() => {
        if (arrow === "Hide") {
            return false;
        }

        if (arrow === "Show") {
            return true;
        }

        return {
            pointAtCenter: true,
        };
    }, [arrow]);

    const tableColumns = () => {
        return [
            {
                title: "Vrsta posla",
                dataIndex: ["projectType", "name"],
                key: "projectTypeId",
            },
            {
                title: "Naziv stavke",
                dataIndex: "title",
                key: "title",
            },
            {
                title: "",
                key: "operation",
                //fixed: "right",
                width: "150px",
                render: (text, record, index) => (
                    <Flex vertical={false} gap={"4px"}>
                        <Tooltip
                            placement="top"
                            title={<span>Pregled</span>}
                            arrow={mergedArrow}
                        >
                            <Button
                                type="text"
                                size="large"
                                icon={<SearchOutlined/>}
                                onClick={() => {
                                    setMode("preview");
                                    setRowData(record);
                                    setTemplateAdministrationModalOpened(true);
                                }}
                            />
                        </Tooltip>
                        <Tooltip
                            placement="top"
                            title={<span>Uređivanje</span>}
                            arrow={mergedArrow}
                        >
                            <Button
                                type="text"
                                size="large"
                                icon={<EditOutlined/>}
                                onClick={() => {
                                    setMode("edit");
                                    setTemplateAdministrationModalOpened(true);
                                    setRowData(record);
                                }}
                            />
                        </Tooltip>
                        <Tooltip
                            placement="top"
                            title={<span>Brisanje</span>}
                            arrow={mergedArrow}
                        >
                            <Space>
                                <Button
                                    type="text"
                                    size="large"
                                    danger
                                    icon={<DeleteOutlined/>}
                                    onClick={() => confirm(record.id)}
                                />
                            </Space>
                        </Tooltip>
                    </Flex>
                ),
            },
        ];
    };

    return (
        <>
            <Table
                columns={tableColumns()}
                dataSource={tableData}
                onChange={(pagination, filters, sorter) =>
                    onFilterChanged(pagination, filters, sorter)
                }
                style={{marginTop: "32px"}}
                pageNumber={pageNumber}
                pageSize={pageSize}
                pagination={{
                    defaultPageSize: 10,
                    showSizeChanger: true,
                    pageSizeOptions: ['10', '20', '30'],
                    total: totalRows,
                    showTotal: (total, range) => `Ukupan broj rezultata: ${totalRows}`
                }}
                bordered
                scroll={{x: 700}}
                locale={{emptyText: <Empty description="Nije pronađen niti jedan predložak."/>}}
            />
            {contextHolder}
        </>
    );
};
export default IraItemNameTemplateTable;
