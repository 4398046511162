import React, {useEffect, useState} from "react";
import {Flex, Form, Input, Select, theme} from "antd";

import "./SupplierAdministrationForm.css";
import {cities} from "../../../../constants/constants";
import {isOibValid} from "../../../../utils/validationUtil";
import {axiosInstance} from "../../../../api/axiosInstance";

const SupplierAdministrationForm = ({
                                        form,
                                        mode,
                                        initialValues,
                                        expenseTypes,
                                        onFinish,
                                        disabled,
                                    }) => {

    const {token} = theme.useToken();

    const formStyle = {
        maxWidth: "none",
        background: token.colorFillAlter,
        borderRadius: token.borderRadiusLG,
        padding: 24,
    };

    useEffect(() => {
        form.setFieldsValue(initialValues);
    }, [initialValues]);

    useEffect(() => {
        if (mode === "insert") {
            form.resetFields();
        }
    }, [mode]);

    return (
        <Form
            form={form}
            name="supplierAdministrationForm"
            style={formStyle}
            onFinish={(values) =>
                onFinish({
                    ...values,
                    id: initialValues?.id,
                })
            }
            initialValues={initialValues}
            layout={"vertical"}
            disabled={disabled}
        >
            <Form.Item
                name={`name`}
                label={`Naziv:`}
                rules={[
                    {
                        required: true,
                        message: "Naziv je obavezno polje!",
                    },
                ]}
            >
                <Input placeholder="Naziv dobavljača"/>
            </Form.Item>
            <Form.Item
                name={["expenseType", "id"]}
                label={`Vrsta troška`}
                rules={[
                    {
                        required: true,
                        message: "Vrsta troška je obavezno polje!",
                    },
                ]}
            >
                <Select
                    options={expenseTypes}
                    popupMatchSelectWidth={false}
                ></Select>
            </Form.Item>
            <Form.Item
                name={["streetName"]}
                label={`Ulica:`}
                rules={[
                    {
                        required: false,
                        message: "Ulica je obavezno polje!",
                    },
                ]}
            >
                <Input placeholder="Ulica dobavljača"/>
            </Form.Item>
            <Form.Item
                name={["houseNumber"]}
                label={`Kućni broj:`}
                rules={[
                    {
                        required: false,
                        message: "Kućni broj je obavezno polje!",
                    },
                ]}
            >
                <Input placeholder="Kućni broj dobavljača"/>
            </Form.Item>
            <Form.Item
                name={["postCode"]}
                label={`Poštanski broj:`}
                rules={[
                    {
                        required: false,
                        message: "Poštanski broj je obavezno polje!",
                    },
                ]}
            >
                <Input placeholder="Poštanski broj dobavljača"/>
            </Form.Item>
            <Form.Item
                name={["city"]}
                label={`Grad:`}
                rules={[
                    {
                        required: false,
                        message: "Grad je obavezno polje!",
                    },
                ]}
            >
                <Select
                    options={cities}
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                        (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
                    }
                ></Select>
            </Form.Item>
            <Form.Item
                name={`oib`}
                label={`OIB:`}
                rules={[
                    {
                        required: false,
                        message: "OIB je obavezno polje!",
                    },
                ]}
            >
                <Input placeholder="OIB dobavljača" maxLength={11}/>
            </Form.Item>
            <Form.Item
                name={`iban`}
                label={`IBAN:`}
                rules={[
                    {
                        required: false,
                        message: "IBAN dobavljača je obavezno polje!",
                    },
                ]}
            >
                <Input placeholder="IBAN dobavljača"/>
            </Form.Item>
            <Form.Item
                name={`workArea`}
                label={`Djelatnost:`}
                rules={[
                    {
                        required: false,
                        message: "Djelatnost je obavezno polje!",
                    },
                ]}
            >
                <Input placeholder="Djelatnost dobavljača"/>
            </Form.Item>
            <Form.Item
                name={`phoneNumber`}
                label={`Broj telefona:`}
                rules={[
                    {
                        required: false,
                        message: "Broje telefona je obavezno polje!",
                    },
                ]}
            >
                <Input placeholder="Broj telefona dobavljača"/>
            </Form.Item>
            <Form.Item
                name={`email`}
                label={`E-mail:`}
                rules={[
                    {
                        required: false,
                        message: "E-mail adresa je obavezno polje!",
                    },
                    {
                        type: "email",
                        message: "E-mail adresa je neispravna!",
                    },
                ]}
            >
                <Input placeholder="E-mail adresa dobavljača"/>
            </Form.Item>
        </Form>
    );
};

export default SupplierAdministrationForm;
