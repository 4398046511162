import {Button, Flex, Form, Select} from "antd";
import TextArea from "antd/es/input/TextArea";
import React from "react";

const PostSubmitForm = ({form, selectedProject, projects, users, fetchUsers, handleSubmitPost}) => {


    return (
        <Form
            form={form}
            name="form"
            onFinish={async (values) => {
                handleSubmitPost(values);
            }}
            layout={"vertical"}
            initialValues={{message: '', projectId: selectedProject && selectedProject !== -1 ? selectedProject : '', recipientId: []}}
        >
            <Flex vertical>
                <Form.Item
                    name={`projectId`}
                    label={`Posao:`}
                    rules={[
                        {
                            required: false,
                            message: "Posao je obavezno polje!",
                        },
                    ]}
                >
                    <Select
                        options={projects.filter( x => x.value !== -1)}
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                            (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
                        }
                        onChange={(projectId) => {
                            form.setFieldValue("recipientId", []);
                            fetchUsers(projectId)
                        }}
                    ></Select>
                </Form.Item>
                <Form.Item
                    name={`recipientId`}
                    label={`Poruku prima:`}
                    rules={[
                        {
                            required: true,
                            message: "Primatelj je obavezno polje!",
                        },
                    ]}
                >
                    <Select
                        placeholder={"Odaberite primatelja"}
                        mode={"multiple"}
                        allowClear={true}
                        options={users}
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                            (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
                        }
                        onChange={(value) => {
                            console.log(value, 'velju')
                            const includesAllRecipientsOption = !!(value && value.includes(""));
                            const lastValue = value[value.length-1];
                            console.log(lastValue, 'lastValue')

                            if (includesAllRecipientsOption && value.length === 1) {
                                console.log('uvjet1')
                                form.setFields([
                                    {name: ["recipientId"], value: [""]},
                                ]);
                            } else if(includesAllRecipientsOption && value.length > 1 && lastValue !== ""){
                                if(value.includes("")){
                                    console.log('uvjet3')
                                    const filteredArray = value.filter(x => x !== "");
                                    form.setFields([
                                        {name: ["recipientId"], value: filteredArray},
                                    ]);
                                } else {
                                    console.log('uvjet4')
                                    form.setFields([
                                        {name: ["recipientId"], value: value},
                                    ]);
                                }
                            } else if(includesAllRecipientsOption && value.length > 1 && lastValue === "") {
                                console.log('uvjet5')
                                form.setFields([
                                    {name: ["recipientId"], value: [""]},
                                ]);
                            }
                        }}
                    ></Select>
                </Form.Item>
                <Form.Item>
                    <Form.Item
                        name={"message"}
                        rules={[
                            {required: true, message: 'Poruka je obavezno polje!'},
                            {min: 5, message: 'Poruka ne smije biti kraća od 5 znakova.'},
                        ]}
                    >
                        <TextArea rows={4} style={{borderColor: ''}}/>
                    </Form.Item>
                </Form.Item>
                <Button
                    htmlType="submit"
                    loading={false}
                    type="primary"
                >
                    Pošalji poruku
                </Button>
            </Flex>
        </Form>);
}
export default PostSubmitForm;