import React, { useMemo, useState } from "react";
import { Table, Button, Flex, Tooltip, Modal, Space, Empty } from "antd";
import {
  EditOutlined,
  SearchOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";

import '../CooperantAdministrationTable/CooperantAdministrationTable.css'
import '../../../../api/axiosInstance'
import {axiosInstance} from "../../../../api/axiosInstance";
import { toast } from "react-toastify";

const CooperantAdministrationTable = ({
  tableData,
  pageNumber,
  pageSize,
  totalRows,
  onFilterChanged,
  setMode,
  setCooperantAdministrationModalOpened,
  setRowData,
  refreshData,
}) => {
  const [arrow, setArrow] = useState("Show");

  const [modal, contextHolder] = Modal.useModal();

  const confirm = (id) => {
    modal.confirm({
      title: "Potvrda",
      icon: <ExclamationCircleOutlined />,
      content: "Jeste li sigurni da želite obrisati kooperanta?",
      okText: "Da",
      cancelText: "Ne",
      centered: true,
      onOk: () => {
        axiosInstance.delete(`/api/v1/cooperants/${id}`)
        .then(res => {
          toast.success("Kooperant je uspješno obrisan!");
          refreshData();
        }).catch(ex => {
          toast.error("Dogodila se greška prilikom brisanja kooperanta!");
        })
      },
    });
  };

  const mergedArrow = useMemo(() => {
    if (arrow === "Hide") {
      return false;
    }

    if (arrow === "Show") {
      return true;
    }

    return {
      pointAtCenter: true,
    };
  }, [arrow]);

  const tableColumns = () => {
    return [
      {
        title: "Naziv",
        dataIndex: "name",
        key: "name",
      },
      {
        title: "OIB",
        dataIndex: "oib",
        key: "oib",
      },
      {
        title: "IBAN",
        dataIndex: "iban",
        key: "iban",
      },
      {
        title: "Adresa",
        dataIndex: "addressStr",
        key: "address",
      },
      {
        title: "Broj telefona",
        dataIndex: "phoneNumber",
        key: "phoneNumber",
      },
      {
        title: "Email",
        dataIndex: "email",
        key: "email",
      },
      {
        title: "",
        key: "operation",
        //fixed: "right",
        width: "150px",
        render: (text, record, index) => (
          <Flex vertical={false} gap={"4px"}>
            <Tooltip
              placement="top"
              title={<span>Pregled</span>}
              arrow={mergedArrow}
            >
              <Button
                type="text"
                size="large"
                icon={<SearchOutlined />}
                onClick={() => {
                  setMode("preview");
                  setRowData(record);
                  setCooperantAdministrationModalOpened(true);
                }}
              />
            </Tooltip>
            <Tooltip
              placement="top"
              title={<span>Uređivanje</span>}
              arrow={mergedArrow}
            >
              <Button
                type="text"
                size="large"
                icon={<EditOutlined />}
                onClick={() => {
                  setMode("edit");
                  setRowData(record);
                  setCooperantAdministrationModalOpened(true);
                }}
              />
            </Tooltip>
            <Tooltip
              placement="top"
              title={<span>Brisanje</span>}
              arrow={mergedArrow}
            >
              <Space>
                <Button
                  type="text"
                  size="large"
                  danger
                  icon={<DeleteOutlined />}
                  onClick={() => confirm(record.id)}
                />
              </Space>
            </Tooltip>
          </Flex>
        ),
      },
    ];
  };

  return (
    <>
      <Table
        columns={tableColumns()}
        dataSource={tableData}
        onChange={(pagination, filters, sorter) =>
          onFilterChanged(pagination, filters, sorter)
        }
        style={{ marginTop: "32px"}}
        pageNumber={pageNumber}
        pageSize={pageSize}
        pagination={{ 
          defaultPageSize: 10, 
          showSizeChanger: true, 
          pageSizeOptions: ['10', '20', '30'], 
          total: totalRows, 
          showTotal: (total, range) =>  `Ukupan broj rezultata: ${totalRows}`
        }} 
        bordered
        scroll={{ x: 700 }}
        locale={{ emptyText:  <Empty description="Nije pronađen niti jedan kooperant."/> }}
      />
      {contextHolder}
    </>
  );
};
export default CooperantAdministrationTable;
