import React from "react";
import {Col, DatePicker, Form, Input, InputNumber, Row, Select, TimePicker} from "antd";
import dayjs from "dayjs";
import TextArea from "antd/es/input/TextArea";

const GeneralDataForm = ({
                             form,
                             formStyle,
                             disabled,
                             initialValues,
                             handleFieldsChange,
                             userData,
                             owners,
                             investors,
                             companies,
                             paymentTypes,
                             taxLiabilityTypes,
                             companyFieldDisabled,
                             mode,
                             pdvDisabled,
                             setPdvDisabled,
                             fetchProjects,
                         }) => {

    // Add refs for other input fields as needed

    const focusNextInput = (ref) => {
        if (ref.current) {
            ref.current.focus();
        }
    };

    const handleKeyDown = (e, ref) => {
        if (e.key === "Tab") {
            e.preventDefault();
            focusNextInput(ref);
        }
    };
    const onePointDecimal = (number) => Number(number).toFixed(1);
    const twoPointDecimal = (number) => Number(number).toFixed(2);

    const generateFormRow = (key, leftColumn, rightColumn) => {
        return (<Row gutter={16} key={key}>
                <Col span={12} xs={24} sm={12}>
                    {leftColumn}
                </Col>
                <Col span={12} xs={24} sm={12}>
                    {rightColumn}
                </Col>
            </Row>
        );
    }

    const isUserRole = userData?.authorities?.includes('ROLE_ZAPOSLENIK');
    const isAdminRole = userData?.authorities?.includes('ROLE_ADMINISTRATOR');

    const invoiceNumberField = (<Form.Item
        name={`invoiceNumber`}
        label={`Broj računa:`}
        rules={[
            {
                required: false,
                message: "Broj računa je obavezno polje!",
            },
        ]}
    >
        <Input placeholder="Broj računa se generira automatski" disabled/>
    </Form.Item>);


    const ownerField = (<Form.Item
        name={["owner", "id"]}
        label={`Nositelj`}
        rules={[
            {
                required: true,
                message: "Nositelj je obavezno polje!",
            },
        ]}
    >
        <Select options={owners} popupMatchSelectWidth={false}
        ></Select>
    </Form.Item>);

    const investorField = (<Form.Item
        name={["investor", "id"]}
        label={`Investitor`}
        rules={[
            {
                required: true,
                message: "Investitor je obavezno polje!",
            },
        ]}
    >
        <Select
            options={investors}
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) =>
                (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
            onChange={(investorId) => {
                fetchProjects(investorId);
            }}
        ></Select>
    </Form.Item>);

    const companyField = (<Form.Item
        name={["company", "id"]}
        label={`Tvrtka`}
        rules={[
            {
                required: true,
                message: "Tvrtka je obavezno polje!",
            },
        ]}
    >
        <Select
            options={companies}
            disabled={mode === "insert" && companyFieldDisabled}
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) =>
                (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
        ></Select>
    </Form.Item>);


    const creationDate = (<Form.Item
        name={"creationDate"}
        label={`Nadnevak:`}
        rules={[
            {
                required: true,
                message: "Nadnevak je obavezno polje!",
            },
        ]}
    >
        <DatePicker
            style={{width: "100%"}}
            getValueProps={(e) => ({
                value: e ? dayjs(e).add(1, "hour") : "",
            })}
            placeholder="Odaberite datum"
            format={"DD.MM.YYYY."}
            disabledTime={true}
        />
    </Form.Item>);

    const creationTime = (<Form.Item
        name={"creationTime"}
        label={`Vrijeme izdavanja:`}
        rules={[
            {
                required: true,
                message: "Vrijeme izdavanja je obavezno polje!",
            },
        ]}
    >
        <TimePicker
            style={{width: "100%"}}
            getValueProps={(e) => ({
                value: e ? dayjs(e).add(1, "hour") : "",
            })}
            placeholder="Odaberite vrijeme"
            format={"HH:mm"}
        />
    </Form.Item>);

    const invoiceDueTimeField = (<Form.Item
        name={"invoiceDueTime"}
        label={`Broj dana dospijeća:`}
        rules={[
            {
                required: true,
                message: "Broj dana dospijeća je obavezno polje!",
            },
        ]}
    >
        <InputNumber min={0} placeholder={"Broj dana dospijeća"} style={{width: '100%'}}></InputNumber>
    </Form.Item>);

    const deliveryField = (<Form.Item
        name={"delivery"}
        label={`Isporuka:`}
        rules={[
            {
                required: true,
                message: "Isporuka je obavezno polje!",
            },
        ]}
    >
        <DatePicker
            style={{width: "100%"}}
            getValueProps={(e) => ({
                value: e ? dayjs(e).add(1, "hour") : "",
            })}
            placeholder="Odaberite datum"
            format={"DD.MM.YYYY."}
            disabledTime={true}
        />
    </Form.Item>);

    const paymentTypeField = (<Form.Item
        name={["paymentType", "id"]}
        label={`Način plaćanja:`}
        rules={[
            {
                required: true,
                message: "Način plaćanja je obavezno polje!",
            },
        ]}
    >
        <Select
            options={paymentTypes}
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) =>
                (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
        ></Select>

    </Form.Item>)

    const taxLiabilityTypeField = (<Form.Item
        name={["taxLiabilityType", "id"]}
        label={`Vrsta porezne obveze:`}
        rules={[
            {
                required: true,
                message: "Vrsta porezne obveze je obavezno polje!",
            },
        ]}
    >
        <Select
            options={taxLiabilityTypes}
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) =>
                (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
            onChange={(value) => {
                if (value === 1) {
                    setPdvDisabled(false);
                    form.setFieldsValue({pdv: 25.00});
                } else {
                    setPdvDisabled(true);
                    form.setFieldsValue({pdv: 0.00});
                }
            }}
        ></Select>

    </Form.Item>)

    const pdvField = (<Form.Item
            name={["pdv"]}
            label={`PDV (%):`}
            rules={[
                {
                    required: true,
                    message: "PDV je obavezno polje!",
                },
            ]}
        >
            <InputNumber
                precision={2}
                step={0.1}
                style={{width: "100%"}}
                placeholder="PDV"
                disabled={pdvDisabled}
            />
        </Form.Item>
    )


    const remark = () => (
        <Form.Item
            name={`remark`}
            label={`Napomena:`}
            rules={[
                {
                    required: false,
                    message: "Napomena je obavezno polje!",
                },
            ]}
        >
            <TextArea rows={1} placeholder="Maksimalna dužina je 255 znakova" maxLength={255} style={{height: '50px'}}/>
        </Form.Item>
    )

    return (
        <Form
            form={form}
            name="generalDataForm"
            style={formStyle}
            onFinish={(values) => {
            }}
            layout={"vertical"}
            disabled={disabled}
            initialValues={initialValues}
            onClick={(e) => e.stopPropagation()}
            onValuesChange={(changedValues, allValues) => {
                handleFieldsChange('generalDataForm', changedValues, allValues);
            }}
            labelCol={{span: 24}}
            wrapperCol={{span: 24}}
            autoComplete="off"
        >
            {generateFormRow(1, invoiceNumberField, ownerField)}
            {generateFormRow(2, companyField, investorField)}
            {generateFormRow(3, creationDate, creationTime)}
            {generateFormRow(4, invoiceDueTimeField, deliveryField)}
            {generateFormRow(5, paymentTypeField, taxLiabilityTypeField)}
            {generateFormRow(6, pdvField, null)}
            {remark()}
        </Form>
    );
}

export default GeneralDataForm;