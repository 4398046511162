import React, {useEffect, useState} from "react";
import {
    Collapse, theme
} from "antd";
import GeneralDataForm from "../GeneralDataForm/GeneralDataForm";
import {CaretRightOutlined} from "@ant-design/icons";
import UraItemForm from "../UraItemForm/UraItemForm";

const UraForm = ({
                     userData,
                     rowData,
                     mode,
                     generalDataForm,
                     uraItemForm,
                     activeKey,
                     setActiveKey,
                     disabled,
                     formStyle,
                     initialValues,
                     uraItemFormInitialValues,
                     handleFieldsChange,
                     owners,
                     projects,
                     companies,
                     suppliers,
                     companyFieldDisabled,
                     expenseSubTypeFieldDisabled,
                     coefficientFieldDisabled,
                     supplierFieldDisabled,
                     expenseTypes,
                     expenseSubTypes,
                     setUploadedFile,
                     fileList,
                     setFileList,
                     uraItemFormErrors,
                     setUraItemFormErrors
                 }) => {

    useEffect(() => {
        generalDataForm.resetFields();
    }, [rowData])

    const {Panel} = Collapse;

    const {token} = theme.useToken();
    const panelStyle = {
        marginBottom: 0,
        background: "white",
        borderRadius: token.borderRadiusLG,
        border: "none",
    };

    const handleSetActiveKey = (selectedKey) => {
        let activeKeyCopy = [...activeKey];
        const keyAlreadyExists = activeKeyCopy.includes(selectedKey);
        if (keyAlreadyExists) {
            const index = activeKeyCopy.indexOf(selectedKey);
            if (index > -1) {
                activeKeyCopy.splice(index, 1);
            }
        } else {
            activeKeyCopy.push(selectedKey);
        }
        setActiveKey(activeKeyCopy);
    };

    return (
        <>
            <Collapse
                bordered={false}
                expandIcon={({isActive}) => (
                    <CaretRightOutlined
                        rotate={isActive ? 90 : 0}
                        style={{
                            background: token.colorBgContainer,
                        }}
                    />
                )}
                style={{marginTop: "4px"}}
                activeKey={activeKey}
                ghost
            >
                <Panel
                    forceRender={true}
                    key="1"
                    header="Opći podaci"
                    style={{...panelStyle, background: "white"}}
                    onClick={() => handleSetActiveKey("1")}
                >
                    <GeneralDataForm
                        form={generalDataForm}
                        formStyle={formStyle}
                        disabled={disabled}
                        initialValues={initialValues}
                        handleFieldsChange={handleFieldsChange}
                        userData={userData}
                        suppliers={suppliers}
                        owners={owners}
                        projects={projects}
                        companies={companies}
                        expenseTypes={expenseTypes}
                        expenseSubTypes={expenseSubTypes}
                        companyFieldDisabled={companyFieldDisabled}
                        coefficientFieldDisabled={coefficientFieldDisabled}
                        supplierFieldDisabled={supplierFieldDisabled}
                        mode={mode}
                        fileList={fileList}
                        setFileList={setFileList}
                        setUploadedFile={setUploadedFile}
                        expenseSubTypeFieldDisabled={expenseSubTypeFieldDisabled}
                    >
                    </GeneralDataForm>
                </Panel>
                <Panel
                    forceRender={true}
                    key="2"
                    header="Stavke računa"
                    style={{...panelStyle, background: "white"}}
                    onClick={() => handleSetActiveKey("2")}
                >
                    <UraItemForm
                        form={uraItemForm}
                        initialValues={uraItemFormInitialValues}
                        mode={mode}
                        projects={projects}
                        expenseSubTypes={expenseSubTypes}
                        handleFieldsChange={handleFieldsChange}
                        uraItemFormErrors={uraItemFormErrors}
                        setUraItemFormErrors={setUraItemFormErrors}
                    ></UraItemForm>
                </Panel>
            </Collapse>
        </>
    )
        ;
}

export default UraForm;