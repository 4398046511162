import React, {useEffect, useState} from "react";
import {Button, Flex, Form, Select, Input, theme, DatePicker} from "antd";
import {DownOutlined} from "@ant-design/icons";

import "./ProjectsFilter.css";
import {axiosInstance} from "../../../api/axiosInstance";

const ProjectsFilter = ({
                            form,
                            onFormFilterSubmit,
                            investors,
                            owners,
                            companies,
                            locations,
                            statuses,
                            projectSubTypes,
                            billingStatuses,
                            showProjectSubTypeField
                        }) => {
    const {token} = theme.useToken();
    const [expand, setExpand] = useState(false);

    const formStyle = {
        maxWidth: "none",
        background: token.colorFillAlter,
        borderRadius: token.borderRadiusLG,
        padding: 24,
        borderColor: "rgba(0, 0, 0, 0.03)",
        borderStyle: "solid",
        borderWidth: "thin",
    };


    return (
        <Form
            form={form}
            name="projectFilter"
            style={formStyle}
            onFinish={onFormFilterSubmit}
            layout={"vertical"}
            initialValues={{
                projectTypeId: -1,
                projectSubTypeId: '',
                projectDeadline: '',
                billingStatusId: '',
                name: '',
                investorId: '',
                ownerId: '',
                companyId: '',
                locationId: '',
                statusId: '',
                proposedDeadlineSort: '',
            }}
        >
            <Flex vertical>
                {showProjectSubTypeField ?
                    <Form.Item
                        name={`projectSubTypeId`}
                        label={`Vrsta podposla:`}
                        rules={[
                            {
                                required: false,
                                message: "Vrsta podposla je obavezno polje!",
                            },
                        ]}
                    >
                        <Select options={projectSubTypes}></Select>
                    </Form.Item>
                    : <Form.Item
                        name={`name`}
                        label={`Oznaka posla:`}
                        rules={[
                            {
                                required: false,
                                message: "Oznaka posla je obavezno polje!",
                            },
                        ]}
                    >
                        <Input placeholder="Oznaka posla"/>
                    </Form.Item>
                }
                {expand ? (
                    <>
                        {showProjectSubTypeField ?
                            <Form.Item
                                name={`name`}
                                label={`Oznaka posla:`}
                                rules={[
                                    {
                                        required: false,
                                        message: "Oznaka posla je obavezno polje!",
                                    },
                                ]}
                            >
                                <Input placeholder="Oznaka posla"/>
                            </Form.Item>
                            : <></>
                        }
                        <Form.Item
                            name={`investorId`}
                            label={`Investitor:`}
                            rules={[
                                {
                                    required: false,
                                    message: "Investitor je obavezno polje!",
                                },
                            ]}
                        >
                            <Select options={investors}></Select>
                        </Form.Item>
                        <Form.Item
                            name={`ownerId`}
                            label={`Nositelj:`}
                            rules={[
                                {
                                    required: false,
                                    message: "Nositelj je obavezno polje!",
                                },
                            ]}
                        >
                            <Select options={owners}></Select>
                        </Form.Item>
                        <Form.Item
                            name={`companyId`}
                            label={`Tvrtka:`}
                            rules={[
                                {
                                    required: false,
                                    message: "Tvrtka je obavezno polje!",
                                },
                            ]}
                        >
                            <Select options={companies}></Select>
                        </Form.Item>
                        <Form.Item
                            name={`locationId`}
                            label={`Lokacija:`}
                            rules={[
                                {
                                    required: false,
                                    message: "Lokacija je obavezno polje!",
                                },
                            ]}
                        >
                            <Select options={locations}></Select>
                        </Form.Item>
                        <Form.Item
                            name={`projectDeadline`}
                            label={`Rok dovršetka:`}
                            rules={[
                                {
                                    required: false,
                                    message: "Rok dovršetka je obavezno polje!",
                                },
                            ]}
                        >
                            <DatePicker onChange={(date) => {
                            }}
                                        style={{width: '100%'}}
                                        placeholder={"Rok dovršetka"}
                                        format={"DD.MM.YYYY."}
                            />
                        </Form.Item>
                        <Form.Item
                            name={`statusId`}
                            label={`Status:`}
                            rules={[
                                {
                                    required: false,
                                    message: "Status je obavezno polje!",
                                },
                            ]}
                        >
                            <Select options={statuses}></Select>
                        </Form.Item>
                        <Form.Item
                            name={`billingStatusId`}
                            label={`Status naplate:`}
                            rules={[
                                {
                                    required: false,
                                    message: "Status naplate je obavezno polje!",
                                },
                            ]}
                        >
                            <Select options={billingStatuses}></Select>
                        </Form.Item>
                    </>
                ) : (
                    <React.Fragment></React.Fragment>
                )}
                <Flex justify="end" align="center" style={{marginBottom: '32px'}}>
                    <a
                        style={{fontSize: 12}}
                        onClick={() => {
                            setExpand(!expand);
                        }}
                    >
                        <DownOutlined rotate={expand ? 180 : 0}/> {expand ? "Skupi filter" : "Proširi filter"}
                    </a>
                </Flex>
                <Flex
                    justify="end"
                    align="center"
                    className="button-container"
                    gap={"4px"}
                >
                    <Button
                        type="primary"
                        htmlType="submit"
                        style={{minWidth: "120px"}}
                    >
                        Pretraži
                    </Button>
                    <Button
                        onClick={() => {
                            form.resetFields();
                        }}
                        style={{minWidth: "120px"}}
                    >
                        Poništi filtere
                    </Button>
                </Flex>
            </Flex>
        </Form>
    );
};

export default ProjectsFilter;
