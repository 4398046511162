import {Button, Modal, Space, Table, Tooltip} from "antd";
import dayjs from "dayjs";
import {DeleteOutlined, ExclamationCircleOutlined, PlusOutlined} from "@ant-design/icons";
import ModalComponent from "../../ModalComponent/ModalComponent";
import React, {useMemo, useState} from "react";
import {axiosInstance} from "../../../api/axiosInstance";
import {toast} from "react-toastify";

const IraPaymentModal = ({
                             iraPaymentModalOpened,
                             setIraPaymentModalOpened,
                             setIraAddPaymentModalOpened,
                             loading,
                             mode,
                             payments,
                             fetchPayments,
                             fetchIras
                         }) => {

    const [arrow, setArrow] = useState("Show");

    const mergedArrow = useMemo(() => {
        if (arrow === "Hide") {
            return false;
        }

        if (arrow === "Show") {
            return true;
        }

        return {
            pointAtCenter: true,
        };
    }, [arrow]);
    const twoPointDecimal = (number) => Number(number).toFixed(2);

    const confirm = (id, iraId) => {
        Modal.confirm({
            title: "Potvrda",
            icon: <ExclamationCircleOutlined/>,
            content: "Jeste li sigurni da želite obrisati uplatu?",
            okText: "Da",
            cancelText: "Ne",
            centered: true,
            onOk: () => {
                axiosInstance.delete(`/api/v1/ira/payment/${id}`)
                    .then(res => {
                        toast.success("Uplata uspješno obrisana!");
                        fetchPayments(iraId)
                        fetchIras();
                    }).catch(ex => {
                    toast.error(`${ex.response.data.errorMessage}`);
                });
            },
        });
    };

    return (<ModalComponent
        title={"Uplate"}
        show={iraPaymentModalOpened}
        okButtonText={"Zatvori"}
        onOk={() => {
            setIraPaymentModalOpened(false);
        }}
        onCancel={() => {
            setIraPaymentModalOpened(false);
            //Modal.destroyAll();
        }}
        loading={loading}
        showFooter={mode !== "preview"}
        width={"580px"}
    >
        <Table
            bordered={true}
            columns={[
                {
                    title: "Datum uplate",
                    dataIndex: ["paymentDate"],
                    key: "paymentDate",
                    render: (value, record, index) => (<>{dayjs(record.paymentDate).format("DD.MM.YYYY.")}</>),
                },
                {
                    title: "Iznos",
                    dataIndex: ["paymentAmount"],
                    key: "paymentAmount",
                    render: (value, record, index) => (<>{`${twoPointDecimal(value)} €`}</>),
                },
                {
                    title: "Napomena",
                    dataIndex: ["paymentRemark"],
                    key: "paymentRemark"
                },
                {
                    title: "",
                    key: "operation",
                    fixed: "right",
                    render: (text, record, index) => (<>
                        <Tooltip
                            placement="top"
                            title={<span>Obriši</span>}
                            arrow={mergedArrow}
                        >
                            <Space>
                                <Button
                                    type="text"
                                    size="large"
                                    danger
                                    icon={<DeleteOutlined/>}
                                    onClick={() => confirm(record.id, record.iraId)}
                                />
                            </Space>
                        </Tooltip>
                    </>)
                }
            ]}
            dataSource={payments}
        >
        </Table>
        <div style={{
            display: 'flex',
            alignContent: 'flex-end',
            justifyItems: 'flex-end',
            justifyContent: 'flex-end',
            marginTop: '16px',
            marginBottom: '16px'
        }}>
            <Button
                type="primary"
                shape="circle"
                icon={<PlusOutlined/>}
                size={"large"}
                onClick={() => setIraAddPaymentModalOpened(true)}
            />
        </div>
    </ModalComponent>)
}

export default IraPaymentModal;