import React from "react";
import { Button, Flex, Form, Select, Input, Space, theme } from "antd";

import "./UserAdministrationFilter.css";

const UserAdministrationFilter = ({ form, onFormFilterSubmit }) => {
  const { token } = theme.useToken();

  const formStyle = {
    maxWidth: "none",
    background: token.colorFillAlter,
    borderRadius: token.borderRadiusLG,
    padding: 24,
    borderColor: "rgba(0, 0, 0, 0.03)",
    borderStyle: "solid",
    borderWidth: "thin",
  };

  return (
    <Form
      form={form}
      name="userAdministrationPageFilter"
      style={formStyle}
      onFinish={onFormFilterSubmit}
      layout={"vertical"}
      initialValues={{
        firstName: "",
        lastName: "",
        active: "",
      }}
    >
      <Flex vertical>
        <Form.Item
          name={`firstName`}
          label={`Ime:`}
          rules={[
            {
              required: false,
              message: "Ime je obavezno polje!",
            },
          ]}
        >
          <Input placeholder="Ime zaposlenika" />
        </Form.Item>
        <Form.Item
          name={`lastName`}
          label={`Prezime:`}
          rules={[
            {
              required: false,
              message: "Prezime je obavezno polje!",
            },
          ]}
        >
          <Input placeholder="Prezime zaposlenika" />
        </Form.Item>
        <Form.Item
          name={`active`}
          label={`Aktivnost:`}
          rules={[
            {
              required: false,
              message: "Aktivnost je obavezno polje!",
            },
          ]}
        >
          <Select
            options={[
              { label: "Prikaži sve", value: "" },
              { label: "Aktivan", value: true },
              { label: "Neaktivan", value: false },
            ].map((item) => ({ label: item.label, value: item.value }))}
          ></Select>
        </Form.Item>
        <Flex
          justify="end"
          align="center"
          className="button-container"
          gap={"4px"}
        >
          <Button
            type="primary"
            htmlType="submit"
            style={{ minWidth: "120px" }}
          >
            Pretraži
          </Button>
          <Button
            onClick={() => {
              form.resetFields();
            }}
            style={{ minWidth: "120px" }}
          >
            Poništi filtere
          </Button>
        </Flex>
      </Flex>
    </Form>
  );
};

export default UserAdministrationFilter;
