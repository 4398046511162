import React, {useEffect, useState} from "react";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import ".//CustomCalendar.css";
import {Form, Select, DatePicker, theme} from "antd";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";

dayjs.extend(customParseFormat);

const TimeTrackingForm = ({
                              form,
                              timeTrackingItemForm,
                              initialValues,
                              mode,
                              workTypes,
                              setWorkTypeId,
                          }) => {
    const {token} = theme.useToken();

    const formStyle = {
        maxWidth: "none",
        background: token.colorFillAlter,
        borderRadius: token.borderRadiusLG,
        padding: 24,
    };

    useEffect(() => {
    }, [mode]);

    const [startDate, setStartDate] = useState();

    const {RangePicker} = DatePicker;

    const disabledDate = (current) => {
        // Disable dates before the start date and the same date as the start date

        if (!startDate) {
            return dayjs().isBefore(current, "day");
        }

        return (
            (current && dayjs(startDate).isBefore(current, "day")) ||
            dayjs(startDate).isAfter(current, "day")
        );
    };


    return (
        <Form
            name={"timeTrackingForm"}
            form={form}
            style={formStyle}
            onValuesChange={(changedValues, allValues) => {
                setWorkTypeId(allValues.workTypeId);
                console.log(form.getFieldsValue());
                timeTrackingItemForm.setFieldsValue({items: []})
            }}
            layout={"vertical"}
            initialValues={initialValues}
        >
            <Form.Item
                name={"timeRange"}
                label={`Datum obavljanja posla`}
                rules={[
                    {
                        required: true,
                        message: "Datum obavljanja posla je obavezno polje!",
                    },
                ]}
            >
                <DatePicker
                    style={{width: "100%"}}
                    format={"DD.MM.YYYY."}
                    disabledDate={disabledDate}
                />
            </Form.Item>
            <Form.Item
                name={"workTypeId"}
                label={`Vrsta rada`}
                rules={[
                    {
                        required: true,
                        message: "Vrsta rada je obavezno polje!",
                    },
                ]}
            >
                <Select
                    options={workTypes?.map((item) => ({
                        label: item.name,
                        value: item.id,
                    }))}
                ></Select>
            </Form.Item>

        </Form>
    );
};

export default TimeTrackingForm;
