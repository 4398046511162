import React, {useEffect} from "react";
import {Table} from "antd";


const InputCostsTable = ({data}) => {

    const twoPointDecimal = (number) => Number(number).toFixed(2);

    return (<>
        <span onClick={(e) => {
            e.preventDefault();
            e.stopPropagation()
        }}>
                <Table
                    rowKey="uraId"
                    bordered
                    size="small"
                    pagination={false}
                    columns={[{
                        title: "Oznaka URA-e", dataIndex: ["name"],
                    }, {
                        title: "Dobavljač", dataIndex: ["supplier", "name"],
                    }, {
                        title: "Kategorija", dataIndex: ["expenseType", "name"],
                    }, {
                        title: "Opis", dataIndex: "remark",
                    }, {
                        title: "Iznos", dataIndex: "priceWithPdv", render: (value => (<>{twoPointDecimal(value)} €</>))
                    },]}
                    dataSource={data}
                    scroll={{x: 700}}
                    summary={() => (<Table.Summary>
                        <Table.Summary.Row>
                            <Table.Summary.Cell index={0} colSpan={4}>
                                <b>SUMA</b>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell index={0} colSpan={4}>
                                {twoPointDecimal(data[0]?.totalSumWithVAT) + ' €'}
                            </Table.Summary.Cell>
                        </Table.Summary.Row>
                    </Table.Summary>)}
                ></Table>
        </span>
</>
)
    ;
}

export default InputCostsTable;