import React, { useRef, useEffect } from "react";
import {
  Form,
  Input,
  Select,
  InputNumber
} from "antd";

const RealEstateAppraisalSubForm = (props) => {
    return (<>
      <Form.Item
            name={["additionalFields", "lokacija"]}
            label={`Lokacija`}
          >
            <Input placeholder="Lokacija" />
          </Form.Item>
          <Form.Item
            name={["additionalFields", "datumOcevida"]}
            label={`Datum očevida`}
          >
            <Input placeholder="Datum očevida" />
          </Form.Item>
          <Form.Item
            name={["additionalFields", "predmetProcjene"]}
            label={`Predmet procjene`}
          >
            <Select
            defaultValue={''}
            options={[{ value: "", label: "Odaberite vrijednost" }].concat(["Zemljište", "Zgrada", "Izvedeni radovi"]
            .map( (item) => {
                return { value: item, label: item };
            }))}
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) =>
                (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
            ></Select>
          </Form.Item>
          <Form.Item
            name={["additionalFields", "svrhaProcjene"]}
            label={`Svrha procjene`}
          >
            <Input placeholder="Svrha procjene" />
          </Form.Item>
          <Form.Item
            name={["additionalFields", "iznosProcjene"]}
            label={`Iznos procjene`}
          >
            <InputNumber placeholder="Iznos procjene" style={{width: '100%'}}/>
          </Form.Item>
          <Form.Item
            name={["additionalFields", "zgrada"]}
            label={`Zgrada`}
          >
            <Input placeholder="Zgrada" />
          </Form.Item>
          <Form.Item
            name={["additionalFields", "godinaIzgradnje"]}
            label={`Godina izgradnje`}
          >
            <Input placeholder="Godina izgradnje" />
          </Form.Item>
          <Form.Item
            name={["additionalFields", "godinaRekonstrukcije"]}
            label={`Godina rekonstrukcije`}
          >
            <Input placeholder="Godina rekonstrukcije" />
          </Form.Item>
          <Form.Item
            name={["additionalFields", "dokazLegalnosti"]}
            label={`Dokaz legalnosti`}
          >
            <Input placeholder="Dokaz legalnosti" />
          </Form.Item>
          <Form.Item
            name={["additionalFields", "katnost"]}
            label={`Katnost / Smještaj građevine / stana`}
          >
            <Input placeholder="Katnost / Smještaj građevine / stana" />
          </Form.Item>
          <Form.Item
            name={["additionalFields", "teren"]}
            label={`Teren`}
          >
            <Select
            defaultValue={''}
            options={[{ value: "", label: "Odaberite vrijednost" }].concat(["Ravno", "Uređeno", "Zaraslo", "Popločano", "Asfaltirano", "Ograđeno"]
            .map( (item) => {
                return { value: item, label: item };
            }))}
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) =>
                (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
            ></Select>
          </Form.Item>
          <Form.Item
            name={["additionalFields", "prikljucci"]}
            label={`Priključci`}
          >
            <Select
            defaultValue={''}
            options={[{ value: "", label: "Odaberite vrijednost" }].concat(["Struja", "Voda-Bunar", "Voda-Gradska", "Telefon", "Plin", "Kanalizacija-Gradska", "Kanalizacija-Sabirna"]
            .map( (item) => {
                return { value: item, label: item };
            }))}
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) =>
                (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
            ></Select>
          </Form.Item>
          <Form.Item
            name={["additionalFields", "grijanje"]}
            label={`Grijanje`}
          >
            <Select
            defaultValue={''}
            options={[{ value: "", label: "Odaberite vrijednost" }].concat(["Centralno-Kombi", "Centralno-Peći", "Centralno-Toplana", "Energent-Drva", "Energent-Plin", "Energent-Nafta", "Energent-Pelet", "Energent-Struja"]
            .map( (item) => {
                return { value: item, label: item };
            }))}
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) =>
                (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
            ></Select>
          </Form.Item>
          <Form.Item
            name={["additionalFields", "temeljiObjekta"]}
            label={`Grijanje`}
          >
            <Select
            defaultValue={''}
            options={[{ value: "", label: "Odaberite vrijednost" }].concat(["Beton", "Kamen", "Opeka"]
            .map( (item) => {
                return { value: item, label: item };
            }))}
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) =>
                (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
            ></Select>
          </Form.Item>
          <Form.Item
            name={["additionalFields", "zid"]}
            label={`Zid`}
          >
            <Select
            defaultValue={''}
            options={[{ value: "", label: "Odaberite vrijednost" }].concat(["Beton", "Blok opeka", "Betonski blok", "Siporeks", "Rigips", "AB"]
            .map( (item) => {
                return { value: item, label: item };
            }))}
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) =>
                (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
            ></Select>
          </Form.Item>
          <Form.Item
            name={["additionalFields", "strop"]}
            label={`Strop`}
          >
            <Select
            defaultValue={''}
            options={[{ value: "", label: "Odaberite vrijednost" }].concat(["Drveni grednik", "Fert", "Siporeks", "Siporeks", "AB ploča", "GK ploče"]
            .map( (item) => {
                return { value: item, label: item };
            }))}
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) =>
                (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
            ></Select>
          </Form.Item>
          <Form.Item
            name={["additionalFields", "pod"]}
            label={`Pod`}
          >
            <Select
            defaultValue={''}
            options={[{ value: "", label: "Odaberite vrijednost" }].concat(["Parket", "Laminat", "Keramika", "Beton"]
            .map( (item) => {
                return { value: item, label: item };
            }))}
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) =>
                (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
            ></Select>
          </Form.Item>
          <Form.Item
            name={["additionalFields", "kroviste"]}
            label={`Krovište`}
          >
            <Select
            defaultValue={''}
            options={[{ value: "", label: "Odaberite vrijednost" }].concat(["Ravni krov", "Dvostrešno", "Višestrešno"]
            .map( (item) => {
                return { value: item, label: item };
            }))}
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) =>
                (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
            ></Select>
          </Form.Item>
          <Form.Item
            name={["additionalFields", "krovisteMaterijal"]}
            label={`Krovište materijal`}
          >
            <Select
            defaultValue={''}
            options={[{ value: "", label: "Odaberite vrijednost" }].concat(["Drvo", "Čelik", "Crijep", "Salonit"]
            .map( (item) => {
                return { value: item, label: item };
            }))}
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) =>
                (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
            ></Select>
          </Form.Item>
          <Form.Item
            name={["additionalFields", "obradaProcelja"]}
            label={`Obrada pročelja`}
          >
            <Select
            defaultValue={''}
            options={[{ value: "", label: "Odaberite vrijednost" }].concat(["Bavalit", "Hirofa", "Demit", "Ožbukano", "Neobrađeno"]
            .map( (item) => {
                return { value: item, label: item };
            }))}
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) =>
                (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
            ></Select>
          </Form.Item>
          <Form.Item
            name={["additionalFields", "vanjskaStolarija"]}
            label={`Vanjska stolarija`}
          >
            <Select
            defaultValue={''}
            options={[{ value: "", label: "Odaberite vrijednost" }].concat(["Drveno", "PVC", "Aluminijsko", "Aluminijsko / Crna bravarija", "Nema", "Jednostruko", "Dvostruko", "Trostruko", "IZO"]
            .map( (item) => {
                return { value: item, label: item };
            }))}
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) =>
                (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
            ></Select>
          </Form.Item>
          <Form.Item
            name={["additionalFields", "unutarnjaStolarija"]}
            label={`Unutarnja stolarija`}
          >
            <Select
            defaultValue={''}
            options={[{ value: "", label: "Odaberite vrijednost" }].concat(["Drvo", "PVC", "Aluminij", "Crna bravarija"]
            .map( (item) => {
                return { value: item, label: item };
            }))}
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) =>
                (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
            ></Select>
          </Form.Item>
          <Form.Item
            name={["additionalFields", "energetskiCertifikat"]}
            label={`Energetski certifikat`}
          >
            <Select
            defaultValue={''}
            options={[{ value: "", label: "Odaberite vrijednost" }].concat(["DA", "NE"]
            .map( (item) => {
                return { value: item, label: item };
            }))}
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) =>
                (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
            ></Select>
          </Form.Item>
          <Form.Item
            name={["additionalFields", "ocevidIzvrsio"]}
            label={`Očevid izvršio`}
          >
            <Input placeholder="Očevid izvršio" />
          </Form.Item>
          <Form.Item
            name={["additionalFields", "dodatneNapomene"]}
            label={`Dodatne napomene`}
          >
            <Input placeholder="Dodatne napomene" />
          </Form.Item>
    </>)
}

export default RealEstateAppraisalSubForm;