import {axiosInstance} from "../../../api/axiosInstance";
import {DatePicker, Form, InputNumber} from "antd";
import TextArea from "antd/es/input/TextArea";
import ModalComponent from "../../ModalComponent/ModalComponent";
import React from "react";
import {toast} from "react-toastify";
import dayjs from "dayjs";

const IraPaymentForm = ({
                             addPaymentForm,
                             iraAddPaymentModalOpened,
                             setIraAddPaymentModalOpened,
                             rowData,
                             fetchPayments,
                             fetchIras,
                             loading,
                             mode,
                         }) => {

    return (<ModalComponent
        title={"Dodaj uplatu"}
        show={iraAddPaymentModalOpened}
        onOk={async () => {
            let validationResultPaymentForm;
            let tValidationFormHasErrors = false;

            try {
                validationResultPaymentForm = await addPaymentForm.validateFields();
                if (!validationResultPaymentForm.errorFields) {
                    addPaymentForm.submit();
                    //setLoading(true);
                }
            } catch (ex) {
                console.log(ex);
                tValidationFormHasErrors = true;
            }

            if (!tValidationFormHasErrors) {
                var request = {...addPaymentForm.getFieldsValue(), id: rowData?.id}
                axiosInstance.post("/api/v1/ira/add-payment", {
                    iraId: request.id,
                    amount: request.paymentAmount,
                    tstamp: request.paymentDate,
                    remark: request.paymentRemark
                }).then((res) => {
                    fetchPayments(request.id);
                }).then((res) => {
                    fetchIras()
                }).catch((ex) => {
                    toast.error(ex.response.data.errorMessage);
                })
                console.log(request, 'request');
                addPaymentForm.resetFields();
                setIraAddPaymentModalOpened(false);
            }
        }
        }
        onCancel={() => {
            setIraAddPaymentModalOpened(false);
        }}
        loading={loading}
        showFooter={mode !== "preview"}
        width={"580px"}
    >
        <Form
            form={addPaymentForm}
            name={"addPaymentForm"}
            initialValues={{paymentDate: dayjs(new Date()), paymentAmount: 0.00, remark: ''}}
            layout={"vertical"}
        >
            <Form.Item
                name={"paymentDate"}
                label={"Datum uplate"}
                rules={[{required: true, message: "Datum uplate je obavezan podatak!"}]}
            >
                <DatePicker onChange={(date) => {
                }}
                            style={{width: '100%'}}
                            placeholder={"Datum uplate"}
                            format={"DD.MM.YYYY."}
                />
            </Form.Item>
            <Form.Item
                name={"paymentAmount"}
                label={"Iznos (EUR)"}
                rules={[
                    {
                        required: true, message: "Iznos je obavezan podatak!"
                    },
                    {
                        type: 'number',
                        min: 0.01,
                        message: 'Iznos mora biti veći od nule.'
                    }
                ]}

            >
                <InputNumber min={0} placeholder={"Iznos"} style={{width: '100%'}} precision={2}></InputNumber>
            </Form.Item>
            <Form.Item name={"paymentRemark"} label={"Napomena"}>
                <TextArea placeholder={"Napomena"}></TextArea>
            </Form.Item>
        </Form>
    </ModalComponent>)
}

export default IraPaymentForm;