import React, {useEffect, useState} from "react";

import {cleanStore as cleanUserData} from "../../slices/userDataSlice";
import {useRoutes, useNavigate, Navigate} from "react-router-dom";
import {useDispatch} from "react-redux";
import axios from "axios";

import {
    LaptopOutlined,
    UserAddOutlined,
    UserOutlined,
    MenuOutlined,
    ProjectOutlined,
    SafetyCertificateOutlined,
    GlobalOutlined,
    AuditOutlined,
    HomeOutlined,
    FolderOpenOutlined,
    BorderOutlined,
    BranchesOutlined,
    AppstoreOutlined,
    FolderViewOutlined,
    LoginOutlined,
    LogoutOutlined,
    FieldTimeOutlined,
    EuroOutlined,
    ShoppingCartOutlined,
    BankOutlined,
    ShopOutlined,
    FileTextOutlined,
    AccountBookOutlined,
    ProfileOutlined,

} from "@ant-design/icons";
import {Layout, Menu, Button, Flex, Drawer, theme, Badge} from "antd";
import {Footer} from "antd/es/layout/layout";
import "./Navigation.css";

import {
    TOKEN_REVOCATION_ENDPOINT,
    CLIENT_NAME,
    CLIENT_SECRET,
} from "../../constants/constants";
import UserAdministrationPage from "../../pages/UserAdministrationPage/UserAdministrationPage";
import TimeTrackingPage from "../../pages/TimeTrackingPage/TimeTrackingPage";
import InvestorAdministrationPage from "../../pages/InvestorAdministrationPage/InvestorAdministrationPage";
import CompanyAdministrationPage from "../../pages/CompanyAdministrationPage/CompanyAdministrationPage";
import CooperantAdministrationPage from "../../pages/CooperantAdministrationPage/CooperantAdministrationPage";
import LocationAdministrationPage from "../../pages/LocationAdministrationPage/LocationAdministrationPage";
import SupplierAdministrationPage from "../../pages/SupplierAdministrationPage/SupplierAdministrationPage";

import ProjectsPage from "../../pages/ProjectsPage/ProjectsPage";
import UraPage from "../../pages/UraPage/UraPage";
import IraPage from "../../pages/IraPage/IraPage";
import {getRoles} from "@testing-library/react";
import SalaryPage from "../../pages/SalaryPage/SalaryPage";
import EmployeeTimeTrackingPage from "../../pages/EmployeeTimeTrackingPage/EmployeeTimeTrackingPage";
import IraItemNameTemplateAdministrationPage
    from "../../pages/IraItemNameTemplateAdministrationPage/IraItemNameTemplateAdministrationPage";
import NoticeboardPage from "../../pages/Noticeboard/NoticeboardPage";
import TasksPage from "../../pages/TasksPage/TasksPage";

const Navigation = ({userData}) => {
    const [collapsed, setCollapsed] = useState(false);
    const [visible, setVisible] = useState(false);
    const [selectedKeys, setSelectedKeys] = useState(['noticeboard']);

    const dispatch = useDispatch();
    let navigate = useNavigate();

    const {Header, Content, Sider} = Layout;

    useEffect(() => {
        onNavbarItemClicked()
    }, [selectedKeys])

    useEffect(() => {
        console.log(userData.unreadPostCount, 'unreadPostCount');
        console.log(userData.unreadPostIds, 'unreadPostIds');
    }, [userData.unreadPostCount, userData.unreadPostIds])

    const projectsSubMenu = () => {
        return {
            key: "projects",
            label: `Poslovi`,
            onTitleClick: (item) => {
                item.domEvent.preventDefault();
                item.domEvent.stopPropagation();
            },
            icon: React.createElement(FolderViewOutlined),
            disabled: userData.uiDisabled,
            children: [
                {
                    key: "main-project",
                    label: `Glavni projekt`,
                    icon: React.createElement(ProjectOutlined),
                    disabled: userData.uiDisabled,
                }, {
                    key: "certificates",
                    label: `Certifikati`,
                    icon: React.createElement(SafetyCertificateOutlined),
                    disabled: userData.uiDisabled,
                },
                {
                    key: "energy-renewal",
                    label: `Energetske obnove`,
                    icon: React.createElement(GlobalOutlined),
                    disabled: userData.uiDisabled,
                },
                {
                    key: "professional-supervision",
                    label: `Stručni nadzor`,
                    icon: React.createElement(AuditOutlined),
                    disabled: userData.uiDisabled,
                },
                {
                    key: "real-estate-appraisal",
                    label: `Procjena nekretnina`,
                    icon: React.createElement(HomeOutlined),
                    disabled: userData.uiDisabled,
                },
                {
                    key: "blower-door",
                    label: `Blower Door`,
                    icon: React.createElement(BorderOutlined),
                    disabled: userData.uiDisabled,
                },
                {
                    key: "elaborates",
                    label: `Elaborati`,
                    icon: React.createElement(FolderOpenOutlined),
                    disabled: userData.uiDisabled,
                },
                {
                    key: "staging",
                    label: `Izvođenje`,
                    icon: React.createElement(BranchesOutlined),
                    disabled: userData.uiDisabled,
                },
                {
                    key: "all-projects",
                    label: `Svi poslovi`,
                    icon: React.createElement(AppstoreOutlined),
                    disabled: userData.uiDisabled,
                }]
        }
    }


    const mainMenu = (userRole) => {

        return {
            key: `main-menu`,
            icon: React.createElement(LaptopOutlined),
            label: `Glavni izbornik`,
            onTitleClick: (item) => {
                item.domEvent.preventDefault();
                item.domEvent.stopPropagation();
            },
            children: [
                {
                    key: "noticeboard",
                    label: "",
                    icon: <Badge
                        count={userData?.unreadPostCount ? userData.unreadPostCount : 0}
                        text={<span style={{marginLeft: '8px'}}>Oglasna ploča</span>}
                        title={"Broj nepročitanih objava"}
                        offset={[20, 1]}
                        style={{lineHeight: "1.5"}}
                        showZero
                    >
                        <AccountBookOutlined/>
                    </Badge>,
                    disabled: userData.uiDisabled
                },
                {
                    key: "tasks",
                    label: "Zadaci",
                    icon: React.createElement(ProfileOutlined),
                    disabled: userData.uiDisabled
                },
                projectsSubMenu(),
                userRole !== 'ROLE_ZAPOSLENIK' &&
                {
                    key: "ura",
                    label: `Ulazni računi`,
                    icon: React.createElement(LoginOutlined),
                    disabled: userData.uiDisabled,
                },
                userRole !== 'ROLE_ZAPOSLENIK' &&
                {
                    key: "ira",
                    label: `Izlazni računi`,
                    icon: React.createElement(LogoutOutlined),
                    disabled: userData.uiDisabled,
                },
                {
                    key: "time-tracking",
                    label: `Evidencija radnog vremena`,
                    icon: React.createElement(FieldTimeOutlined),
                },
                userRole !== 'ROLE_ZAPOSLENIK' &&
                {
                    key: "employees",
                    label: `Evidencija djelatnika`,
                    onTitleClick: (item) => {
                        item.domEvent.preventDefault();
                        item.domEvent.stopPropagation();
                    },
                    icon: React.createElement(UserOutlined),
                    disabled: userData.uiDisabled,
                    children: [
                        {
                            key: "salaries",
                            label: `Plaće`,
                            icon: React.createElement(EuroOutlined),
                        }, {
                            key: "employee-time-tracking",
                            label: `Evidencija vremena`,
                            icon: React.createElement(FieldTimeOutlined),
                        }]
                }
            ]
        }
    }

    const administrationSubMenu = () => {
        return {
            key: `administration-menu`,
            icon: React.createElement(UserAddOutlined),
            label: `Administracija`,
            onTitleClick: (item) => {
                item.domEvent.preventDefault();
                item.domEvent.stopPropagation();
            },
            disabled: userData.uiDisabled,
            children: [
                {
                    key: "administration-users",
                    label: `Zaposlenici`,
                    icon: React.createElement(UserOutlined),
                    disabled: userData.uiDisabled,
                },
                {
                    key: "administration-suppliers",
                    label: `Dobavljači`,
                    icon: React.createElement(ShoppingCartOutlined),
                    disabled: userData.uiDisabled,
                },
                /*                {
                                    key: "administration-cooperants",
                                    label: `Kooperanti`,
                                },*/
                {
                    key: "administration-investors",
                    label: `Investitori`,
                    icon: React.createElement(BankOutlined),
                    disabled: userData.uiDisabled,
                },
                {
                    key: "administration-companies",
                    label: `Tvrtke`,
                    icon: React.createElement(ShopOutlined),
                    disabled: userData.uiDisabled,
                },
                {
                    key: "administration-locations",
                    label: `Lokacije`,
                    icon: React.createElement(GlobalOutlined),
                    disabled: userData.uiDisabled,
                },
                {
                    key: "administration-ira-item-name-templates",
                    label: `Predlošci naziva stavke IRA-e`,
                    icon: React.createElement(FileTextOutlined),
                    disabled: userData.uiDisabled,
                },
            ]
        }
    }

    const createNavbar = () => {
        if (userData?.authorities?.includes('ROLE_ZAPOSLENIK')) {
            return [
                mainMenu('ROLE_ZAPOSLENIK'),
            ]
        } else {
            return [
                mainMenu('ROLE_ADMINISTRATOR'),
                administrationSubMenu()
            ]
        }
    }

    const {
        token: {colorBgContainer, colorFillAlter},
    } = theme.useToken();

    const routes = useRoutes([
        {
            path: "/",
            element: <Navigate to="/projects" replace={true}/>,
        },
        {
            path: "/noticeboard",
            element: <NoticeboardPage to="/noticeboard" userData={userData}/>,
        },
        {
            path: "/projects",
            element: <ProjectsPage userData={userData}></ProjectsPage>,
        },
        {
            path: "/tasks",
            element: <TasksPage userData={userData}></TasksPage>,
        },
        {
            path: "/incoming-invoices",
            element: <UraPage userData={userData}></UraPage>,
        },
        {
            path: "/sales-book",
            element: <IraPage userData={userData}></IraPage>,
        },
        {
            path: "/time-tracking",
            element: <TimeTrackingPage userData={userData}></TimeTrackingPage>,
        },
        {
            path: "/salaries",
            element: <SalaryPage userData={userData}></SalaryPage>,
        },
        {
            path: "/employee-time-tracking",
            element: <EmployeeTimeTrackingPage userData={userData}></EmployeeTimeTrackingPage>,
        },
        {
            path: "/administration/users",
            element: <UserAdministrationPage></UserAdministrationPage>,
        },
        {
            path: "/administration/suppliers",
            element: <SupplierAdministrationPage></SupplierAdministrationPage>,
        },
        {
            path: "/administration/cooperants",
            element: <CooperantAdministrationPage></CooperantAdministrationPage>,
        },
        {
            path: "/administration/investors",
            element: <InvestorAdministrationPage></InvestorAdministrationPage>,
        },
        {
            path: "/administration/companies",
            element: <CompanyAdministrationPage></CompanyAdministrationPage>,
        },
        {
            path: "/administration/locations",
            element: <LocationAdministrationPage></LocationAdministrationPage>,
        },
        {
            path: "/administration/ira-item-name-templates",
            element: <IraItemNameTemplateAdministrationPage></IraItemNameTemplateAdministrationPage>,
        },
    ]);

    const onNavbarItemClicked = () => {
        switch (selectedKeys[0]) {
            case "noticeboard":
                navigate("/noticeboard");
                break;
            case "main-project":
                navigate("/projects", {state: {projectType: 'main', projectTypeId: 1}});
                break;
            case "certificates":
                navigate("/projects", {state: {projectType: 'certificates', projectTypeId: 2}});
                break;
            case "energy-renewal":
                navigate("/projects", {state: {projectType: 'energy-renewal', projectTypeId: 3}});
                break;
            case "professional-supervision":
                navigate("/projects", {state: {projectType: 'professional-supervision', projectTypeId: 4}});
                break;
            case "real-estate-appraisal":
                navigate("/projects", {state: {projectType: 'real-estate-appraisal', projectTypeId: 5}});
                break;
            case "blower-door":
                navigate("/projects", {state: {projectType: 'blower-door', projectTypeId: 6}});
                break;
            case "elaborates":
                navigate("/projects", {state: {projectType: 'elaborates', projectTypeId: 7}});
                break;
            case "staging":
                navigate("/projects", {state: {projectType: 'staging', projectTypeId: 8}});
                break;
            case "all-projects":
                navigate("/projects", {state: {projectType: 'all-projects', projectTypeId: -1}});
                break;
            case "ura":
                navigate("/incoming-invoices");
                break;
            case "ira":
                navigate("/sales-book");
                break;
            case "time-tracking":
                navigate("/time-tracking");
                break;
            case "salaries":
                navigate("/salaries");
                break;
            case "tasks":
                navigate("/tasks");
                break;
            case "employee-time-tracking":
                navigate("/employee-time-tracking");
                break;
            case "administration-users":
                navigate("/administration/users");
                break;
            case "administration-suppliers":
                navigate("/administration/suppliers");
                break;
            case "administration-cooperants":
                navigate("/administration/cooperants");
                break;
            case "administration-investors":
                navigate("/administration/investors");
                break;
            case "administration-companies":
                navigate("/administration/companies");
                break;
            case "administration-locations":
                navigate("/administration/locations");
                break;
            case "administration-ira-item-name-templates":
                navigate("/administration/ira-item-name-templates");
                break;
            default:
                break;
        }
    };

    const revokeToken = () => {
        const refreshToken = localStorage.getItem("refreshToken");

        const params = new URLSearchParams();
        params.append("token", refreshToken);
        params.append("token_type_hint", "refresh_token");

        const response = axios
            .post(TOKEN_REVOCATION_ENDPOINT, params, {
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                    Authorization: "Basic " + btoa(`${CLIENT_NAME}:${CLIENT_SECRET}`),
                },
            })
            .then((response) => {
            })
            .catch((error) => {
                console.error(
                    "Error revoking token:",
                    error.response ? error.response.data : error.message
                );
            });
    };

    const navbar = createNavbar();

    return (
        <Layout>
            <Header style={{color: "white", padding: "0 20px"}}>
                <Flex vertical={false} style={{justifyContent: "space-between"}} className="navigation-wrapper">
                    <Flex vertical={false} style={{alignItems: 'center', justifyContent: 'space-between'}} gap={'16px'}
                          className="navigation-menu">
                        <nav className="navigation-hamburger-button">
                            <Button
                                className="menu"
                                type="primary"
                                icon={<MenuOutlined/>}
                                onClick={() => setVisible(true)}
                            />
                            <Drawer
                                title=""
                                placement="left"
                                onClick={() => setVisible(false)}
                                onClose={() => setVisible(false)}
                                open={visible}
                            >
                                <Menu
                                    key={"hamburgerMenu"}
                                    mode="inline"
                                    style={{height: "100%", borderRight: 0}}
                                    selectedKeys={selectedKeys}
                                    onClick={(value) => {
                                        setSelectedKeys([value.key])
                                    }}
                                    defaultOpenKeys={["main-menu", "projects", "administration-menu"]}
                                    items={navbar}
                                />
                            </Drawer>
                        </nav>
                    </Flex>
                    <b className="navbar-title">EnergisERP</b>

                    <Flex vertical={true} className="navigation-user-logout">
            <span style={{marginRight: "0px"}}>
              Dobrodošli, {userData && userData.firstAndLastName ? userData.firstAndLastName : ""}
                {userData && userData.user ? (
                    <Button
                        type="link"
                        danger
                        href="#"
                        onClick={() => {
                            revokeToken();
                            dispatch(cleanUserData());
                            navigate("/");
                        }}
                    >
                        Odjavite se
                    </Button>
                ) : (
                    <></>
                )}
            </span>
                    </Flex>
                </Flex>
            </Header>
            <Layout style={{height: '100%', minHeight: '100vh'}}>
                <Sider
                    trigger={null}
                    width={"300px"}
                    style={{background: colorBgContainer}}
                    collapsible={true}
                    collapsed={collapsed}
                    onCollapse={(collapsed) => setCollapsed(collapsed)}
                    breakpoint="md" // Set the breakpoint for automatic collapse
                    collapsedWidth={0} // Customize the width when collapsed
                >
                    <Menu
                        key={"sideMenu"}
                        mode="inline"
                        defaultOpenKeys={["main-menu", "projects", "administration-menu"]}
                        selectedKeys={selectedKeys}
                        multiple={false}
                        style={{height: "100%", borderRight: 0}}
                        items={navbar}
                        onClick={(value) => {
                            setSelectedKeys([value.key])
                        }}
                    />
                </Sider>
                <Layout style={{padding: "0 24px 24px"}}>
                    <Content
                        style={{
                            width: "100%",
                            padding: 0,
                            margin: 0,
                            background: colorBgContainer,
                        }}
                    >
                        {routes}
                    </Content>
                    <Footer style={{textAlign: "center"}}>Energis ©{new Date().getFullYear()}</Footer>
                </Layout>
            </Layout>
        </Layout>
    );
};

export default Navigation;
