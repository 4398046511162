import React, {useEffect, useMemo, useState} from "react";
import {Table, Select, theme, Flex, Tooltip, Button, Space, Modal, Empty} from "antd";

import "../TasksPage/TasksPage.css";
import {axiosInstance} from "../../api/axiosInstance";
import {
    DeleteOutlined,
    ExclamationCircleOutlined,
    SearchOutlined,
    CheckCircleOutlined,
    CloseCircleOutlined,
    CheckCircleFilled
} from "@ant-design/icons";
import {toast} from "react-toastify";
import ModalComponent from "../../components/ModalComponent/ModalComponent";
import SupplierAdministrationForm
    from "../../components/Administration/Suppliers/SupplierAdministrationForm/SupplierAdministrationForm";
import PostWithComments from "../../components/Noticeboard/PostWithComments/PostWithComments";
import {useForm} from "antd/lib/form/Form";

import dayjs from "dayjs";

const TasksPage = ({userData}) => {
    const {token} = theme.useToken();

    const [form] = useForm()

    const [tasks, setTasks] = useState([]);
    const [selectedProject, setSelectedProject] = useState(-1);
    const [projects, setProjects] = useState([]);

    const [showModal, setShowModal] = useState(false);
    const [rowData, setRowData] = useState({})
    const [posts, setPosts] = useState([]);
    const [post, setPost] = useState({})
    const [comments, setComments] = useState([]);

    const [arrow, setArrow] = useState("Show");
    const [childKey, setChildKey] = useState(0);

    const mergedArrow = useMemo(() => {
        if (arrow === "Hide") {
            return false;
        }

        if (arrow === "Show") {
            return true;
        }

        return {
            pointAtCenter: true,
        };
    }, [arrow]);

    const fetchTasks = () => {
        axiosInstance
            .post("/api/v1/task/find-all-by-project", {projectId: selectedProject})
            .then((res) => setTasks(res.data));
    };

    const fetchUserProjectsByUserId = (userId) => {
        const isAdminRole = userData?.authorities?.includes('ROLE_ADMINISTRATOR');
        return axiosInstance.get(isAdminRole ? `/api/v1/projects/find-all` : `/api/v1/projects/find-all-by-user/${userId}`).then((res) => {
            const userProjects = res.data?.map((x) => {
                return {
                    value: x.id,
                    label: `${x.name} - ${x.investor.name}`,
                };
            });
            setProjects([{value: -1, label: 'Svi zadaci'}, {value: '', label: 'Javno'}].concat(userProjects));
        });

    };

    const fetchPost = (likeableId, likeType) => {
        return axiosInstance
            .post(`/api/v1/noticeboard/find-post-by`, {likeableId: likeableId, likeType: likeType})
            .then((res) => {
                setPosts([].concat(res.data));
                setPost({...res.data, seen: true});
                return res.data.id
            }).then((postId) => {
                fetchComments(postId);
            });
    }
    const fetchComments = (postId) => {
        axiosInstance.get(`/api/v1/noticeboard/find-comments-by-post/${postId}`)
            .then(res => setComments(res.data))
    }

    const confirm = (id) => {
        Modal.confirm({
            title: "Potvrda",
            icon: <ExclamationCircleOutlined/>,
            content: "Jeste li sigurni da želite obrisati zadatak?",
            okText: "Da",
            cancelText: "Ne",
            centered: true,
            onOk: () => {
                axiosInstance.delete(`/api/v1/task/${id}`)
                    .then(res => {
                        toast.success("Zadatak je uspješno obrisan!");
                        fetchTasks();
                    }).catch(ex => {
                    toast.error(`${ex.response.data.errorMessage}`);
                })
            },
        });
    };

    useEffect(() => {
        fetchTasks();
        fetchUserProjectsByUserId(userData.userId);
    }, []);

    useEffect(() => {
        fetchTasks();
    }, [selectedProject]);

    const pageStyle = {
        maxWidth: "none",
        background: token.colorFillAlter,
        borderRadius: token.borderRadiusLG,
        padding: 24,
        height: "100%",
    };

    const rowClassName = (record, index) => {
        if (record.done) {
            return 'done';
        } else {
            return "not-done"
        }
    };

    return (
        <>
            <div className={"tasks-page"} style={pageStyle}>
                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                    <h3>{`Zadaci`}</h3>
                    <Select
                        style={{width: "200px", alignSelf: "center"}}
                        placeholder={"Odaberite posao"}
                        value={selectedProject}
                        options={projects}
                        onChange={(value) => {
                            setSelectedProject(value);
                        }}
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                            (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
                        }
                    ></Select>
                </div>

                {tasks &&
                    tasks.groupedTasks &&
                    Object.values(tasks.groupedTasks).map((task, index) => (
                        <div key={index}>
                            <h3>
                                {task[0]?.projectName
                                    ? `${task[0].projectName} (investitor: ${task[0].investorName})`
                                    : `Javno`}
                            </h3>
                            <Table
                                rowClassName={rowClassName}
                                components={{
                                    body: {
                                        row: ({className, ...props}) => {
                                            let tClassName = className.split(" ")[2]
                                            let currentStyle;
                                            switch (tClassName) {
                                                case 'done':
                                                    currentStyle = {
                                                        backgroundImage: "url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVQIW2NkYGCQBAAAIwAbDJgTxgAAAABJRU5ErkJggg==)",
                                                        backgroundRepeat: "repeat-x",
                                                        backgroundPosition: "50% 50%",
                                                        color: 'black'
                                                    };
                                                    currentStyle = {
                                                        textDecoration: 'line-through'
                                                    }
                                                    break;
                                                default:
                                                    break;
                                            }
                                            return (
                                                <tr
                                                    {...props}
                                                    style={
                                                        currentStyle
                                                    }
                                                />
                                            )
                                        }
                                    },
                                }}
                                scroll={{x: 700}}
                                size={"small"}
                                bordered={true}
                                rowKey={"id"}
                                columns={[
                                    {
                                        title: "Poruka",
                                        dataIndex: "text",
                                        key: "text",
                                        width: "40%",
                                        render: (value, record, index) => {
                                            return <div style={{
                                                padding: '20px',
                                                width: '600px'
                                            }}>{value ? value : "-"}</div>;
                                        },
                                    },
                                    {
                                        title: "Tip poruke",
                                        dataIndex: "likeType",
                                        key: "likeType",
                                        align: "center",
                                        render: (value, record, index) => {
                                            return <p>{value ? value : "-"}</p>;
                                        },
                                    },
                                    {
                                        title: "Datum kreiranja zadatka",
                                        dataIndex: "createdAt",
                                        key: "createdAt",
                                        align: "center",
                                        render: (value, record, index) => {
                                            return <p>{value ? dayjs(value).format("DD.MM.YYYY. HH:mm") : "-"}</p>;
                                        },
                                    },
                                    {
                                        title: "",
                                        key: "operation",
                                        //fixed: "right",
                                        width: "150px",
                                        render: (text, record, index) => (
                                            <Flex vertical={false} gap={"4px"}>
                                                <Tooltip
                                                    placement="top"
                                                    title={<span>Pregled objave</span>}
                                                    arrow={mergedArrow}
                                                >
                                                    <Button
                                                        type="text"
                                                        size="large"
                                                        icon={<SearchOutlined/>}
                                                        onClick={() => {
                                                            setChildKey(prevKey => prevKey + 1);
                                                            setRowData(record);
                                                            fetchPost(record.likeableId, record.likeType)
                                                            setShowModal(true);
                                                        }}
                                                    />
                                                </Tooltip>
                                                <Tooltip
                                                    placement="top"
                                                    title={
                                                        <span>{!record.done ? "Postavi zadatak kao izvršen" : "Postavi zadatak kao neizvršen"}</span>}
                                                    arrow={mergedArrow}
                                                >
                                                    <Button
                                                        type="text"
                                                        size="large"
                                                        icon={!record.done ? <CheckCircleOutlined/> :
                                                            <CheckCircleFilled/>}
                                                        onClick={() => {
                                                            axiosInstance.patch(`/api/v1/task/toggle-done/${record.id}`)
                                                                .then(res => {
                                                                    fetchTasks();
                                                                })
                                                        }}
                                                        style={{color: 'green'}}
                                                    />
                                                </Tooltip>
                                                <Tooltip
                                                    placement="top"
                                                    title={<span>Ukloni zadatak</span>}
                                                    arrow={mergedArrow}
                                                >
                                                    <Space>
                                                        <Button
                                                            type="text"
                                                            size="large"
                                                            danger
                                                            icon={<DeleteOutlined/>}
                                                            onClick={() => confirm(record.id)}
                                                        />
                                                    </Space>
                                                </Tooltip>
                                            </Flex>
                                        ),
                                    },
                                ]}
                                dataSource={task}
                                pagination={false}
                            ></Table>
                        </div>
                    ))}
                {tasks &&
                JSON.stringify(tasks.groupedTasks) === '{}' ?
                    <div style={{
                        display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh'
                    }}>
                        <Empty description={"Nije pronađen niti jedan zadatak."}/>
                    </div> : <></>
                }

            </div>
            <ModalComponent
                show={showModal}
                onOk={() => {
                    setComments([]);
                    setShowModal(false);
                }}
                onCancel={() => {
                    setComments([]);
                    setShowModal(false);
                }}
                showFooter={false}
                width={"700px"}
            >
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: 'center',
                        alignItems: 'center',
                        justifyItems: "center"
                    }}
                >
                    <PostWithComments
                        key={childKey}
                        likeableId={rowData.likeableId}
                        likeType={rowData.likeType}
                        previewMode={true}
                        post={post}
                        comments={comments}
                        selectedProject={selectedProject}
                        setPosts={setPosts}
                        userId={post?.user?.id}
                        message={post.message}
                        username={`${post?.user?.nameStr}`}
                        createdAt={post.tstamp}
                        //numberOfComments={post?.numberOfComments}
                        fetchComments={(postId) => fetchComments(postId)}
                        expandComments={true}
                        handlePostDelete={(postId) => {
                        }}
                        style={{width: '100%'}}
                    ></PostWithComments>
                </div>

            </ModalComponent>
        </>
    );
};
export default TasksPage;
