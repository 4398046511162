import React, { useEffect, useState } from "react";
import { Button, Modal } from "antd";

const ModalComponent = ({
  title,
  children,
  show,
  onOk,
  onCancel,
  loading,
  width,
  showFooter = true,
  hasErrors = false,
  okButtonText = "Spremi",
  centered = false,
}) => {


  useEffect(() => {}, []);

  return (
    <Modal
      maskClosable={false}
      width={width}
      transitionName=""
      open={show}
      title={title ? title : ""}
      onOk={onOk}
      onCancel={onCancel}
      centered={centered}
      footer={
        showFooter && [
          <Button key="back" onClick={onCancel}>
            Odustani
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={loading}
            onClick={() => onOk()}
            htmlType="submit"
            disabled={hasErrors}
          >
              {okButtonText}
          </Button>,
        ]
      }
    >
      {children}
    </Modal>
  );
};
export default ModalComponent;
