import React, { useEffect } from "react";
import { Form, Input, TreeSelect, theme } from "antd";

const IraItemNameTemplateForm = ({
  form,
  mode,
  initialValues,
  projectTypes,
  onFinish,
  disabled,
}) => {
  const { token } = theme.useToken();

  const formStyle = {
    maxWidth: "none",
    background: token.colorFillAlter,
    borderRadius: token.borderRadiusLG,
    padding: 24,
  };

  useEffect(() => {
    form.setFieldsValue(initialValues);
  }, [initialValues]);

  useEffect(() => {
    if(mode === 'insert'){
      form.resetFields();
    }
  }, [mode]);

  return (
    <Form
      form={form}
      name="administrationForm"
      style={formStyle}
      onFinish={(values) =>
        onFinish({
          ...values,
          id: initialValues?.id,
        })
      }
      initialValues={initialValues}
      layout={"vertical"}
      disabled={disabled}
    >
      <Form.Item
          name={["projectType", "id"]}
          label={`Vrsta posla:`}
          rules={[
            {
              required: true,
              message: "Vrsta posla je obavezno polje!",
            },
          ]}
      >
          <TreeSelect
              style={{ width: '100%' }}
              dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
              treeData={projectTypes}
              placeholder="Odaberite vrstu posla"
              treeDefaultExpandAll
          />
      </Form.Item>
      <Form.Item
        name={`title`}
        label={`Naziv:`}
        rules={[
          {
            required: true,
            message: "Naziv čestica je obavezno polje!",
          },
        ]}
      >
        <Input placeholder="Naziv" />
      </Form.Item>
    </Form>
  );
};

export default IraItemNameTemplateForm;
