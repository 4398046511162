import React, {useState, useEffect} from "react";

import {Button, Flex, Form, theme} from "antd";
import {PlusOutlined} from "@ant-design/icons";

import "../UraPage/UraPage.css";
import ModalComponent from "../../components/ModalComponent/ModalComponent";

import "../../api/axiosInstance";
import {axiosInstance} from "../../api/axiosInstance";
import {toast} from "react-toastify";

import {useLocation} from "react-router-dom";
import dayjs from "dayjs";
import {useSelector} from 'react-redux'
import UraFilter from "../../components/Uras/UraFilter/UraFilter";
import UraTable from "../../components/Uras/UraTable/UraTable";
import UraForm from "../../components/Uras/UraForm/UraForm";

const UraPage = (props) => {
    const [mode, setMode] = useState("insert");
    const [activeKey, setActiveKey] = useState(["1"]);

    const [filterForm] = Form.useForm();
    const [generalDataForm] = Form.useForm();
    const [uraItemForm] = Form.useForm();

    const [rowData, setRowData] = useState();

    const [tableData, setTableData] = useState([]);
    const [pageNumber, setPageNumber] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalRows, setTotalRows] = useState(0);

    const [loading, setLoading] = useState(false);
    const [uraModalOpened, setUraModalOpened] = useState(false);
    const [companyFieldDisabled, setCompanyFieldDisabled] = useState(true);
    const [coefficientFieldDisabled, setCoefficientFieldDisabled] = useState(true);
    const [supplierFieldDisabled, setSupplierFieldDisabled] = useState(true);

    const [supplierFilterFieldDisabled, setSupplierFilterFieldDisabled] = useState(true);

    const [allExpenseTypes, setAllExpenseTypes] = useState([]);

    // form

    const [generalFormExpenseSubTypes, setGeneralFormExpenseSubTypes] = useState([]);
    const [expenseSubTypeFieldDisabled, setExpenseSubTypeFieldDisabled] = useState([]);

    // filter
    const [expenseTypes, setExpenseTypes] = useState([]);
    const [expenseSubTypes, setExpenseSubTypes] = useState([]);

    const [expenseSubTypeDisabled, setExpenseSubTypeDisabled] = useState(true);

    const [projects, setProjects] = useState([]);

    const [owners, setOwners] = useState([]);
    const [companies, setCompanies] = useState([]);
    const [suppliers, setSuppliers] = useState([]);

    const [suppliersFilterForm, setSupplierFilterForm] = useState([]);


    const [uploadedFile, setUploadedFile] = useState();
    const [fileList, setFileList] = useState([]);

    const [generalFormInitialValues, setGeneralFormInitialValues] = useState({
        proposedDeadline: dayjs(new Date()).add(1, "hour"),
        usersOnProject: [],
        location: {id: ""},
        company: {id: ""},
        supplier: {id: ""},
        status: {id: ""},
        owner: {id: ""},
        project: {id: ""},
        expenseType: {id: ""},
        expenseSubType: {id: ""},
        receiptCreationDate: dayjs(),
        receiptPaymentDate: null,
        pdv: 25.00,
        coefficient: 1,
        remark: ''
    });

    const [uraItemFormInitialValues, setUraItemFormInitialValues] = useState({items: []});
    const [uraItemFormErrors, setUraItemFormErrors] = useState([]);

    const {token} = theme.useToken();

    let routeState = useLocation();

    const pageStyle = {
        maxWidth: "none",
        background: token.colorFillAlter,
        borderRadius: token.borderRadiusLG,
        padding: 24,
        height: "100%",
    };

    const fetchUras = async (values) => {
        try {
            const response = await axiosInstance.post(
                `/api/v1/ura/find-by?page=${pageNumber - 1}&size=${pageSize}`,
                {
                    ownerId: values?.ownerId,
                    expenseTypeId: values?.expenseTypeId,
                    expenseSubTypeId: values?.expenseSubTypeId,
                    supplierId: values?.supplierId,
                    dateFrom: values?.dateFrom ? dayjs(values?.dateFrom).startOf('day').format('YYYY-MM-DD') : null,
                    dateTo: values?.dateTo ? dayjs(values?.dateTo).startOf('day').format('YYYY-MM-DD') : null,
                    priceWithoutPdvSort: values?.priceWithoutPdvSort ? values.priceWithoutPdvSort : null,
                    priceWithPdvSort: values?.priceWithPdvSort ? values.priceWithPdvSort : null,
                    pdvPriceSort: values?.pdvPriceSort ? values.pdvPriceSort : null,
                }
            );

            setTableData(
                response?.data?.content.map((element) => {
                    return {
                        ...element,
                        key: element.id,
                    };
                })
            );
            setTotalRows(response?.data?.totalElements);
        } catch (ex) {
            console.log('Error while calling fetchUras().')
        }

    };

    const fetchUsers = (values) => {
        return axiosInstance.get(`/api/v1/users/find-all`).then(res => {
            const data = res.data.map((x) => {
                return {value: x.id, label: `${x.firstName} ${x.lastName}`, jobTitle: x.jobTitle};
            });
            setOwners([{
                value: "",
                label: "Prikaži sve"
            }].concat(data.filter(x => x.jobTitle === 'direktor')).concat([{value: "-1", label: "Zajedničko"}]))
        }).catch(ex => {
            console.log('Error while calling fetchUsers().')
        });
    };

    const fetchProjects = (values) => {
        return axiosInstance.get(`/api/v1/projects/find-all`).then(res => {
            const data = res.data.map((x) => {
                return {value: x.id, label: `${x.name}`};
            });
            setProjects([{value: "", label: "Prikaži sve"}].concat(data));
        }).catch(ex => {
            console.log('Error while calling fetchProjects().')
        });
    };


    const fetchCompanies = (values) => {
        return axiosInstance.get(`/api/v1/companies/find-all`).then(res => {
            const data = res.data.map((x) => {
                return {value: x.id, label: `${x.name}`};
            });
            setCompanies([{value: "", label: "Prikaži sve"}].concat(data));
        }).catch(ex => {
            console.log('Error while calling fetchCompanies().')
        });
    };

    const fetchExpenseTypes = (values) => {
        return axiosInstance.get(`/api/v1/expense-types/find-all`).then(res => {
            setAllExpenseTypes(res.data);
            const expenseTypes = res.data.filter(x => x.parentId == null).map((x) => {
                return {value: x.id, label: `${x.name}`};
            });
            setExpenseTypes([{value: "", label: "Prikaži sve"}].concat(expenseTypes));
        }).catch(ex => {
            console.log('Error while calling fetchExpenseTypes().')
        });
    };

/*    const fetchSuppliers = async (values) => {
        try {
            const res = await axiosInstance.get(`/api/v1/suppliers/find-all`);
            const data = res.data.map((x) => {
                return {value: x.id, label: `${x.name}`};
            });
            setSuppliers([{value: "", label: "Prikaži sve"}].concat(data));
        } catch (ex) {
            console.log('Error while calling fetchSuppliers().')
        }
    };*/

    const fetchSuppliersByExpenseTypeId = async (id) => {
        try {
            return await axiosInstance.get(`/api/v1/suppliers/find-all-by/expense-type/${id}`);
        } catch (ex) {
            console.log('Error while calling fetchSuppliersByExpenseTypeId().')
        }
    };

    const fetchCodebooks = async () => {
        Promise.all([fetchUsers(), fetchProjects(), fetchCompanies(), fetchExpenseTypes()]);
    };

    useEffect(() => {
        fetchUras({
            ownerId: null,
            expenseTypeId: null,
            supplierId: null,
            dateFrom: null,
            dateTo: null,
        });
        fetchCodebooks();
    }, [pageNumber, pageSize, routeState?.state?.projectTypeId]);

    const onFormFilterSubmit = async (values) => {
        fetchUras(values);
    };

    const onUraSubmit = ({generalData}) => {

        const mappedValues = {
            ...generalData,
            id: rowData?.id,
            creationDate: generalData?.receiptCreationDate ? dayjs(generalData.receiptCreationDate).add(2, 'hour').toDate() : null,
            owner: {...generalData.owner, id: generalData?.owner?.id},
            company: {...generalData.company, id: generalData?.company?.id},
            items: uraItemForm.getFieldsValue().items
        };

        console.log(mappedValues, 'mappedValues')

        if (mode === "insert") {

            const formData = new FormData();
            const uraBlob = new Blob([JSON.stringify({
                ...mappedValues,
            })], {type: "application/json"})

            formData.append('requestDto', uraBlob);
            formData.append('file', uploadedFile);

            axiosInstance
                .post("/api/v1/ura", formData, {
                    headers: {
                        'content-type': 'multipart/form-data'
                    }
                })
                .then((res) => {
                    setLoading(false);
                    setUraModalOpened(false);
                    uraItemForm.resetFields();
                    setUraItemFormInitialValues({items: []})
                    generalDataForm.resetFields();
                    setUploadedFile(null);
                    toast.success("Račun uspješno spremljen!");
                    fetchUras({
                        ownerId: null,
                        expenseTypeId: null,
                        supplierId: null,
                        dateFrom: null,
                        dateTo: null,
                    });
                })
                .catch((ex) => {
                    console.log(ex, "ex");
                    toast.error("Došlo je do greške prilikom spremanja računa!");
                });
        } else if (mode === "edit") {

            const formData = new FormData();

            const uraBlob = new Blob([JSON.stringify({
                ...mappedValues,
            })], {type: "application/json"})

            formData.append('requestDto', uraBlob);
            formData.append('file', uploadedFile);

            const isCombined = filterForm.getFieldValue("ownerId") === "-1";

            axiosInstance
                .patch(`/api/v1/ura?combined=${isCombined}`, formData, {
                    headers: {
                        'content-type': 'multipart/form-data'
                    }
                })
                .then((res) => {
                    setLoading(false);
                    setUraModalOpened(false);
                    filterForm.resetFields();
                    generalDataForm.resetFields();
                    uraItemForm.resetFields();
                    setUraItemFormInitialValues({items: []})
                    toast.success("Račun uspješno ažuriran!");
                    fetchUras({
                        ownerId: null,
                        expenseTypeId: null,
                        supplierId: null,
                        dateFrom: null,
                        dateTo: null,
                    });
                })
                .catch((ex) => {
                    toast.error("Došlo je do greške prilikom ažuriranja računa!");
                });
        }
        setCompanyFieldDisabled(true);
    };

    const onFilterChanged = async (pagination, filters, sorter) => {
        setPageNumber(pagination.current);
        setPageSize(pagination.pageSize);

        if (Array.isArray(sorter)) {
            const fetchColumn = (columnName) => sorter.filter(x => x.columnKey === columnName)[0];

            const priceWithoutPdvField = fetchColumn("priceWithoutPdv");
            const priceWithPdvField = fetchColumn("priceWithPdv");
            const pdvPriceField = fetchColumn("pdvPrice");

            let priceWithoutPdvSort;
            if(priceWithoutPdvField?.order){
                priceWithoutPdvSort = priceWithoutPdvField.order === 'descend' ? "DESC" : "ASC"
            }

            let priceWithPdvSort;
            if(priceWithPdvField?.order){
                priceWithPdvSort = priceWithPdvField.order === 'descend' ? "DESC" : "ASC";
            }

            let pdvPriceSort;
            if(pdvPriceField?.order){
                pdvPriceSort = pdvPriceField.order === 'descend' ? "DESC" : "ASC";
            }

            fetchUras({
                ...filterForm.getFieldsValue(),
                priceWithoutPdvSort: priceWithoutPdvSort,
                priceWithPdvSort: priceWithPdvSort,
                pdvPriceSort: pdvPriceSort
            })

        } else {
            if (sorter?.columnKey === 'priceWithoutPdv') {
                if(sorter.order === 'descend'){
                    fetchUras({...filterForm.getFieldsValue(), priceWithoutPdvSort: "DESC"})
                } else if(sorter.order === 'ascend'){
                    fetchUras({...filterForm.getFieldsValue(), priceWithoutPdvSort: "ASC"})
                } else {
                    fetchUras({...filterForm.getFieldsValue(), priceWithoutPdvSort: null})
                }
            }
            if (sorter?.columnKey === 'priceWithPdv') {
                if(sorter.order === 'descend'){
                    fetchUras({...filterForm.getFieldsValue(), priceWithPdvSort: "DESC"})
                } else if(sorter.order === 'ascend'){
                    fetchUras({...filterForm.getFieldsValue(), priceWithPdvSort: "ASC"})
                } else {
                    fetchUras({...filterForm.getFieldsValue(), priceWithPdvSort: null})
                }
            }
            if (sorter?.columnKey === 'pdvPrice') {
                if(sorter.order === 'descend'){
                    fetchUras({...filterForm.getFieldsValue(), pdvPriceSort: "DESC"})
                } else if(sorter.order === 'ascend'){
                    fetchUras({...filterForm.getFieldsValue(), pdvPriceSort: "ASC"})
                } else {
                    fetchUras({...filterForm.getFieldsValue(), pdvPriceSort: null})
                }
            }
        }
    };

    const handleSubmitProject = async () => {
        let validationResultGeneralForm;
        let validationResultUraItemForm;

        let tGeneralFormHasErrors = false;
        let tUraItemFormHasErrors = false;

        try {
            validationResultGeneralForm = await generalDataForm.validateFields();
            if (!validationResultGeneralForm.errorFields) {
                generalDataForm.submit();
                //setLoading(true);
            }
        } catch (ex) {
            console.log(ex);
            tGeneralFormHasErrors = true;
        }

        try {
            validationResultUraItemForm = await uraItemForm.validateFields();
            if (!validationResultUraItemForm.errorFields) {
                uraItemForm.submit();
                //setLoading(true);
            }
        } catch (ex) {
            console.log(ex);
            tUraItemFormHasErrors = true;
            setUraItemFormErrors(ex.errorFields)

        }

        const uraItemFormValues = uraItemForm.getFieldsValue()?.items;

        console.log(uraItemFormValues, 'uraItemValues')

        if (tGeneralFormHasErrors || tUraItemFormHasErrors || uraItemFormValues?.length === 0) {
            if(uraItemFormValues?.length === 0){
                toast.error("Račun nema niti jednu stavku.");
            }
        } else {
            onUraSubmit({
                generalData: generalDataForm.getFieldsValue(),
            });
        }
        setFileList([]);
        setUploadedFile();
        //setCompanyFieldDisabled(true);
    };

    const generalDataFormTitle = () => {
        if (mode === "insert") {
            return "Novi ulazni račun";
        } else if (mode === "edit") {
            return "Uređivanje ulaznog računa";
        } else {
            return "Pregled ulaznog računa";
        }
    };


    const handleFieldsChange = async (formName, changedValues, allValues) => {
        if (formName === 'generalDataForm') {
            if (!allValues?.owner?.id) {
                setCompanyFieldDisabled(true);
                setCoefficientFieldDisabled(true);
            } else {
                setCompanyFieldDisabled(false);
                setCoefficientFieldDisabled(false);
            }

            if (!allValues?.expenseType?.id) {
                setSupplierFieldDisabled(true);
            } else {
                setSupplierFieldDisabled(false);
            }

            if(changedValues?.expenseType?.id){
                const res = await fetchSuppliersByExpenseTypeId(changedValues?.expenseType?.id);
                const data = res.data.map((x) => {
                    return {value: x.id, label: `${x.name}`};
                });
                setSuppliers([{value: "", label: "Prikaži sve"}].concat(data));
                generalDataForm.setFieldsValue({supplier: {id: ''}})
            }

            console.log(changedValues, 'changedValues');
            console.log(allValues, 'allValues');

            if (changedValues.expenseType?.id) {

                uraItemForm.setFieldsValue({
                    items: uraItemFormInitialValues.items.map(item => item.expenseSubTypeId = ''),
                });
                const expenseSubTypes = allExpenseTypes.filter(x => x.parentId === allValues.expenseType.id).map((x) => {
                    return {value: x.id, label: `${x.name}`};
                });
                setGeneralFormExpenseSubTypes(expenseSubTypes);
                if (expenseSubTypes && expenseSubTypes.length > 0) {
                    generalDataForm.setFieldsValue({expenseSubType: {id: expenseSubTypes[0].value}})
                    setExpenseSubTypeFieldDisabled(false);
                } else {
                    generalDataForm.setFieldsValue({expenseSubType: {id: ''}})
                    setExpenseSubTypeFieldDisabled(true);
                }

            }

            if (!allValues?.expenseType?.id) {
                generalDataForm.setFieldsValue({expenseSubType: {id: ''}})
                setExpenseSubTypeFieldDisabled(true);
            }

            //generalDataForm.setFieldsValue({ expenseSubType: { id: changedValues.expenseSubType?.id } })

        }

        if(formName === 'filterForm'){
            console.log(allValues, 'fiiiilter')

            if (!allValues?.expenseTypeId) {
                setSupplierFilterFieldDisabled(true);
            } else {
                setSupplierFilterFieldDisabled(false);
            }

            if(changedValues?.expenseTypeId){
                const res = await fetchSuppliersByExpenseTypeId(changedValues?.expenseTypeId);
                const data = res.data.map((x) => {
                    return {value: x.id, label: `${x.name}`};
                });
                setSupplierFilterForm([{value: "", label: "Prikaži sve"}].concat(data));
                filterForm.setFieldValue("supplierId", '');
            }
        }
    }


    return (
        <div className={"ura-page"} style={pageStyle}>
            <h3>Ulazni računi</h3>
            <UraFilter
                form={filterForm}
                onFormFilterSubmit={onFormFilterSubmit}
                owners={owners}
                suppliers={suppliersFilterForm}
                allExpenseTypes={allExpenseTypes}
                expenseTypes={expenseTypes}
                expenseSubTypes={expenseSubTypes}
                setExpenseSubTypes={setExpenseSubTypes}
                expenseSubTypeDisabled={expenseSubTypeDisabled}
                setExpenseSubTypeDisabled={setExpenseSubTypeDisabled}
                supplierFilterFieldDisabled={supplierFilterFieldDisabled}
                handleFieldsChange={(formName, changedValues, allValues) => handleFieldsChange(formName, changedValues, allValues)}
            ></UraFilter>
            <Flex vertical={false} justify="right" className="add-ura-wrapper">
                <Button
                    disabled={props?.userData?.authorities?.includes('ROLE_ZAPOSLENIK')}
                    type="dashed"
                    icon={<PlusOutlined/>}
                    style={{marginTop: "32px"}}
                    onClick={() => {
                        generalDataForm.resetFields();
                        uraItemForm.resetFields();
                        setUraItemFormInitialValues({items: []})
                        setUraItemFormErrors([]);
                        setMode("insert");
                        setFileList([]);
                        setRowData(null);
                        setUploadedFile(null);
                        setUraModalOpened(true);
                    }}
                >
                    Kreiraj ulazni račun
                </Button>
            </Flex>
            <UraTable
                userData={props.userData}
                tableData={tableData}
                onFilterChanged={(pagination, filters, sorter) =>
                    onFilterChanged(pagination, filters, sorter)
                }
                setMode={setMode}
                setUraModalOpened={setUraModalOpened}
                setRowData={setRowData}
                allExpenseTypes={allExpenseTypes}
                setGeneralFormExpenseSubTypes={setGeneralFormExpenseSubTypes}
                uraItemFormInitialValues={uraItemFormInitialValues}
                setUraItemFormInitialValues={setUraItemFormInitialValues}
                fetchSuppliers={(id) => fetchSuppliersByExpenseTypeId(id)}
                setSuppliers={setSuppliers}
                setSupplierFieldDisabled={setSupplierFieldDisabled}
                setFileList={setFileList}
                style={{marginTop: "32px"}}
                isCombined={filterForm.getFieldValue("ownerId") === "-1"}
                pageNumber={pageNumber}
                pageSize={pageSize}
                totalRows={totalRows}
                bordered
                refreshData={() =>
                    fetchUras({
                        ownerId: null,
                        expenseTypeId: null,
                        supplierId: null,
                        dateFrom: null,
                        dateTo: null,
                    })
                }
                readOnly={routeState?.state?.projectType === 'all-projects'}
            />
            <ModalComponent
                title={generalDataFormTitle()}
                show={uraModalOpened}
                onOk={handleSubmitProject}
                onCancel={() => {
                    generalDataForm.resetFields();
                    uraItemForm.resetFields();
                    //setUraItemFormInitialValues({items: []})
                    setUraItemFormErrors([]);
                    setRowData(null);
                    setFileList([]);
                    setUploadedFile(null);
                    setCompanyFieldDisabled(true);
                    setUraModalOpened(false);
                }}
                loading={loading}
                showFooter={mode !== "preview"}
                width={"800px"}
            >
                <UraForm
                    userData={props.userData}
                    generalDataForm={generalDataForm}
                    uraItemForm={uraItemForm}
                    mode={mode}
                    activeKey={activeKey}
                    setActiveKey={setActiveKey}
                    rowData={rowData}
                    initialValues={
                        mode === "insert" ? generalFormInitialValues : rowData
                    }
                    uraItemFormInitialValues={uraItemFormInitialValues}
                    disabled={mode === "preview"}
                    owners={owners.filter(x => x.value !== "-1")}
                    companies={companies}
                    companyFieldDisabled={companyFieldDisabled}
                    coefficientFieldDisabled={coefficientFieldDisabled}
                    expenseSubTypeFieldDisabled={expenseSubTypeFieldDisabled}
                    supplierFieldDisabled={supplierFieldDisabled}
                    suppliers={suppliers}
                    projects={projects}
                    expenseTypes={expenseTypes}
                    expenseSubTypes={generalFormExpenseSubTypes}
                    setUploadedFile={setUploadedFile}
                    fileList={fileList}
                    setFileList={setFileList}
                    handleFieldsChange={(formName, changedValues, allValues) => handleFieldsChange(formName, changedValues, allValues)}
                    uraItemFormErrors={uraItemFormErrors}
                    setUraItemFormErrors={setUraItemFormErrors}
                ></UraForm>
            </ModalComponent>
        </div>
    );
};

export default UraPage;
