import axios from "axios";
import {
    API_URL,
    AUTHORIZATION_URL,
    CLIENT_NAME,
    CLIENT_SECRET,
    TOKEN_ENDPOINT,
} from "../constants/constants";
import Cookies from "universal-cookie";
import {store, persistor} from "../store/store";
import {cleanStore as cleanUserData} from "../slices/userDataSlice";
import {toast} from "react-toastify";

const cookies = new Cookies();

const axiosInstance = axios.create({
    baseURL: API_URL,
    headers: {
        "Content-Type": "application/json",
    },
});

let refreshingFunc = undefined;

const refreshToken = async () => {
    const params = new URLSearchParams();
    params.append("grant_type", "refresh_token");
    params.append("refresh_token", localStorage.getItem("refreshToken"));
    params.append("client_id", CLIENT_NAME);
    params.append("client_secret", CLIENT_SECRET);
    try {
        const response = await axios
            .post(TOKEN_ENDPOINT, params, {
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                    Authorization: "Basic " + btoa(`${CLIENT_NAME}:${CLIENT_SECRET}`),
                },
            })
        return response.data;
    } catch (error) {
        throw error;
    }
}


axiosInstance.interceptors.request.use(
    (config) => {
        let accessToken = localStorage.getItem("accessToken");
        if (accessToken) {
            config.headers.Authorization = "Bearer " + accessToken;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

function isUnauthorizedError(error) {
    const {
        response: {status, statusText},
    } = error;

    return status === 401;
}

axiosInstance.interceptors.response.use(
    (config) => {
        return Promise.resolve(config);
    }, async (error) => {
        const originalConfig  = error.config;
        const token = localStorage.getItem("accessToken")

        if (!token || !isUnauthorizedError(error)) {
            return Promise.reject(error);
        }


        try {
            if (!refreshingFunc)
                refreshingFunc = refreshToken();

            const {access_token, refresh_token} = await refreshingFunc;
            localStorage.setItem("accessToken", access_token);
            localStorage.setItem("refreshToken", refresh_token);

            // Update the original request with the new token
            originalConfig.headers.Authorization = `Bearer ${access_token}`;
            // Retry the original request
            try {
                return await axios.request(originalConfig);
            } catch(innerError) {
                // if original req failed with 401 again - it means server returned not valid token for refresh request
                if (isUnauthorizedError(innerError)) {
                    throw innerError;
                }
            }
        } catch (refreshError) {
            // If token refresh fails, log the user out or handle the error accordingly
            console.error('Token refresh failed:', refreshError);
            // You might want to redirect the user to the login page or handle the error in your application
            store.dispatch(cleanUserData());
            toast.error("Sesija je istekla.", {
                toastId: "sessionExpired"
            });
            throw refreshError;
        } finally {
            refreshingFunc = undefined;
        }

        // If the request still fails after token refresh, reject the promise
        return Promise.reject(error);
    }
);

export {axiosInstance};
