import {
    Form
} from "antd";

import StagingSubForm from "../SubProjectForms/StagingSubForm";
import CertificatesSubForm from "../SubProjectForms/CertificatesSubForm";
import EnergyRenewalSubForm from "../SubProjectForms/EnergyRenewalSubForm";
import ProfessionalSupervisionSubForm from "../SubProjectForms/ProfessionalSupervisionSubForm";
import RealEstateAppraisalSubForm from "../SubProjectForms/RealEstateAppraisalSubForm";
import BlowerDoorSubForm from "../SubProjectForms/BlowerDoorSubForm";
import ElaboratesSubForm from "../SubProjectForms/ElaboratesSubForm";
import MainProjectSubForm from "../SubProjectForms/MainProjectSubForm";

const SpecificDataForm = ({ form, formStyle, disabled, initialValues, projectTypeId, selectedSubProjectId, handleFieldsChange }) => {

    const getSubForm = () => {
        switch (selectedSubProjectId) {
            case 9:
            case 10:
            case 11:
            case 1:
                return <MainProjectSubForm />;
            case 12:
            case 13:
            case 2:
                return <CertificatesSubForm />;
            case 14:
            case 15:
            case 16:
            case 17:
            case 3:
                return <EnergyRenewalSubForm selectedSubProjectId={selectedSubProjectId} />;
            case 18:
            case 19:
            case 20:
            case 4:
                return <ProfessionalSupervisionSubForm />;
            case 21:
            case 22:
            case 5:
                return <RealEstateAppraisalSubForm />;
            case 23:
            case 24:
            case 6:
                return <BlowerDoorSubForm />;
            case 25:
            case 26:
            case 27:
            case 28:
            case 29:
            case 30:
            case 31:
            case 7:
                return <ElaboratesSubForm />;
            case 32:
            case 33:
            case 8:
                return <StagingSubForm />;
            default:
                return <></>;
        }
    }
    return (<Form
        form={form}
        name="specificDataForm"
        style={formStyle}
        onFinish={(values) => { }}
        layout={"vertical"}
        disabled={disabled}
        initialValues={initialValues}
        onClick={(e) => e.stopPropagation()}
        onValuesChange={(changedValues, allValues) => {
            handleFieldsChange('specificDataForm', changedValues, allValues);
        }}
    >
        {getSubForm()}
    </Form>);
}
export default SpecificDataForm;