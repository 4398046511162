import React, { useEffect } from "react";
import { Flex, Form, Input, Select, theme } from "antd";

import { isOibValid } from "../../../../utils/validationUtil.js";
import "./CooperantAdministrationForm.css";
import {axiosInstance} from "../../../../api/axiosInstance.js";
import { cities } from "../../../../constants/constants.js";

const CooperantAdministrationForm = ({
  form,
  mode,
  initialValues,
  onFinish,
  disabled,
}) => {
  const { token } = theme.useToken();

  const formStyle = {
    maxWidth: "none",
    background: token.colorFillAlter,
    borderRadius: token.borderRadiusLG,
    padding: 24,
  };

  useEffect(() => {
    if(mode === 'insert'){
      form.resetFields();
    }
  }, [mode]);

  
  useEffect(() => {
    form.setFieldsValue(initialValues);
  }, [initialValues]);


  const validateOIB = (rule, value, callback) => {
    if (isOibValid(value)) {
      if (mode !== "edit") {
        axiosInstance
          .get(`/api/v1/cooperants/check-oib-exists/${value}`)
          .then((res) => {
            if (res.data) {
              callback("Već postoji kooperant sa istim OIB-om!");
            } else {
              callback();
            }
          });
      } else {
        callback();
      }
    } else {
      callback("Neispravan OIB");
    }
  };

  return (
    <Form
      form={form}
      initialValues={initialValues}
      name="cooperantAdministrationForm"
      style={formStyle}
      onFinish={(values) =>
        onFinish({
          ...values,
          id: initialValues?.id,
          address: { ...values.address, id: initialValues?.address?.id },
        })
      }
      layout={"vertical"}
      disabled={disabled}
    >
      <Form.Item
        name={`name`}
        label={`Naziv:`}
        rules={[
          {
            required: true,
            message: "Naziv je obavezno polje!",
          },
        ]}
      >
        <Input placeholder="Naziv kooperanta" />
      </Form.Item>
      <Form.Item
        name={["address", "street"]}
        label={`Ulica:`}
        rules={[
          {
            required: false,
            message: "Ulica je obavezno polje!",
          },
        ]}
      >
        <Input placeholder="Ulica kooperanta" />
      </Form.Item>
      <Form.Item
        name={["address", "houseNumber"]}
        label={`Kućni broj:`}
        rules={[
          {
            required: false,
            message: "Kućni broj je obavezno polje!",
          },
        ]}
      >
        <Input placeholder="Kućni broj kooperanta" />
      </Form.Item>
      <Form.Item
        name={["address", "postNumber"]}
        label={`Poštanski broj:`}
        rules={[
          {
            required: false,
            message: "Poštanski broj je obavezno polje!",
          },
        ]}
      >
        <Input placeholder="Poštanski broj kooperanta" />
      </Form.Item>
      <Form.Item
        name={["address", "city"]}
        label={`Grad:`}
        rules={[
          {
            required: false,
            message: "Grad je obavezno polje!",
          },
        ]}
      >
        <Select
          options={cities}
          showSearch
          optionFilterProp="children"
          filterOption={(input, option) =>
            (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
          }
        ></Select>
      </Form.Item>
      <Form.Item
        hasFeedback={true}
        name={`oib`}
        label={`OIB:`}
        rules={[
          {
            required: true,
            message: "OIB je obavezno polje!",
          },
          {
            validator: (rule, value, callback) =>
              validateOIB(rule, value, callback),
          },
        ]}
      >
        <Input placeholder="OIB kooperanta" maxLength={11} />
      </Form.Item>
      <Form.Item
        name={`iban`}
        label={`IBAN:`}
        rules={[
          {
            required: true,
            message: "IBAN kooperanta je obavezno polje!",
          },
        ]}
      >
        <Input placeholder="IBAN kooperanta" />
      </Form.Item>
      <Form.Item
        name={`workArea`}
        label={`Djelatnost:`}
        rules={[
          {
            required: false,
            message: "Djelatnost je obavezno polje!",
          },
        ]}
      >
        <Input placeholder="Djelatnost kooperanta" />
      </Form.Item>
      <Form.Item
        name={`phoneNumber`}
        label={`Broj telefona:`}
        rules={[
          {
            required: false,
            message: "Broje telefona je obavezno polje!",
          },
        ]}
      >
        <Input placeholder="Broj telefona kooperanta" />
      </Form.Item>
      <Form.Item
        name={`email`}
        label={`E-mail:`}
        rules={[
          {
            required: true,
            message: "E-mail adresa je obavezno polje!",
          },
          {
            type: "email",
            message: "E-mail adresa je neispravna!",
          },
        ]}
      >
        <Input placeholder="E-mail adresa kooperanta" />
      </Form.Item>
    </Form>
  );
};

export default CooperantAdministrationForm;
