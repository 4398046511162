import React, { useEffect } from "react";
import {
  MinusCircleOutlined,
  PlusOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import { Button, Card, Form, Flex, Modal, Select, Row, Col } from "antd";

import "./UserMapSubForm.css";
import { axiosInstance } from "../../../api/axiosInstance";
import { toast } from "react-toastify";
import { validate } from "uuid";

const onFinish = (values) => {
};

const UserMapSubForm = ({
  form,
  initialValues,
  mapTypes,
  mapStatuses,
  users,
  mode,
  handleFieldsChange,
  userMapsErrors,
  setUserMapsErrors,
}) => {
  const { confirm } = Modal;

  const destroyAll = () => {
    Modal.destroyAll();
  };

  const validateFields = () => {
    try {
      form.validateFields();
    } catch (ex) {
      setUserMapsErrors(ex.errorFields);
      console.log(ex.errorFields);
    }
  };

  useEffect(() => {
    form.setFieldsValue(initialValues);
  }, [initialValues]);

  const showConfirm = (remove, fieldName, fields) => {
    const mapId =
      form.getFieldValue("mapTypes")[fields[fieldName]?.name]?.mapId;
    confirm({
      icon: <ExclamationCircleOutlined />,
      centered: true,
      title: "Upozorenje",
      content: "Jeste li sigurni da želite obrisati mapu?",
      okText: "Potvrdi",
      cancelText: "Odustani",
      onOk() {
        if (mapId) {
          axiosInstance
            .delete(`/api/v1/maps/${mapId}`)
            .then((res) => {
              remove(fieldName);
            })
            .catch((err) => {
              toast.error("Dogodila se greška prilikom brisanja polja.");
            });
        } else {
          remove(fieldName);
        }
      },
      onCancel() {},
    });
  };

  const ellipsisStyle = {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    maxWidth: "100%",
  };

  const MapType = ({ name, restField, errors }) => {
    const errorMessage = userMapsErrors.filter(x => x.name[1] === name && x.name[2] === 'mapTypeId')[0]?.errors[0]
    return (
      <Form.Item
        validateStatus={errorMessage ? "error" : ""}
        help={errorMessage ? errorMessage : ''}
        onChange={() => {
         validateFields();
        }}
        label={`Tip mape:`}
        {...restField}
        name={[name, "mapTypeId"]}
        required={true}
        rules={[
          {
            validator: (_, value) => {
              if (value) {
                const selectedMaps = form
                  .getFieldsValue()
                  .mapTypes.map((item) => item?.mapTypeId);
                const elementCount = selectedMaps.reduce(
                  (cnt, cur) => ((cnt[cur] = cnt[cur] + 1 || 1), cnt),
                  {}
                );
                if (selectedMaps.length > 1 && elementCount[value] > 1) {
                  return Promise.reject(new Error("Mapa već postoji."));
                } else {
                  return Promise.resolve(value);
                }
              } else {
                return Promise.reject(
                  new Error("Vrsta mape je obavezno polje.")
                );
              }
            },
          },
        ]}
      >
        <Select
          showSearch
          dropdownRender={(menu) => (
            <div style={{ maxWidth: "300px" }}>
              {React.cloneElement(menu, { style: ellipsisStyle })}
            </div>
          )}
          placeholder="Vrsta mape"
          popupMatchSelectWidth={false}
          placement="bottomLeft"
          optionFilterProp="children"
          filterOption={(input, option) =>
            (option?.label ?? "").includes(input)
          }
          filterSort={(optionA, optionB) =>
            (optionA?.label ?? "")
              .toLowerCase()
              .localeCompare((optionB?.label ?? "").toLowerCase())
          }
          options={mapTypes}
          disabled={mode === "preview"}
        />
      </Form.Item>
    );
  };

  const MapStatus = ({ name, restField }) => {
    const errorMessage = userMapsErrors.filter(x => x.name[1] === name && x.name[2] === 'mapStatusId')[0]?.errors[0]
    return (
      <Form.Item
        validateStatus={errorMessage ? "error" : ""}
        help={errorMessage ? errorMessage : ''}
        label={`Status mape:`}
        {...restField}
        name={[name, "mapStatusId"]}
        rules={[
          {
            required: true,
            message: "Status mape je obavezno polje",
          },
        ]}
      >
        <Select
          showSearch
          dropdownRender={(menu) => (
            <div>
              {React.cloneElement(menu, {
                style: { ...menu.props.style }, // Set the minimum width as needed
              })}
            </div>
          )}
          placeholder="Status mape"
          popupMatchSelectWidth={false}
          optionFilterProp="children"
          filterOption={(input, option) =>
            (option?.label ?? "").includes(input)
          }
          filterSort={(optionA, optionB) =>
            (optionA?.label ?? "")
              .toLowerCase()
              .localeCompare((optionB?.label ?? "").toLowerCase())
          }
          options={mapStatuses}
          disabled={mode === "preview"}
          onChange={() => {
            validateFields();
          }}
        />
      </Form.Item>
    );
  };

  const UsersOnMap = ({ name, restField }) => {
    const errorMessage = userMapsErrors.filter(x => x.name[1] === name && x.name[2] === 'usersOnMap')[0]?.errors[0]
    return (
      <Form.Item
        validateStatus={errorMessage ? "error" : ""}
        help={errorMessage ? errorMessage : ''}
        label={`Zaposlenici:`}
        {...restField}
        name={[name, "usersOnMap"]}
        rules={[
          {
            required: true,
            message: "Korisnici su obavezno polje",
          },
        ]}
      >
        <Select
          mode="multiple"
          showSearch
          allowClear
          style={{ width: "100%" }}
          placeholder="Pridružite korisnika mapi"
          onChange={(value) => {
            if(value){
              validateFields();
            }
            if (value && value.includes(-1)) {
              const allUsers = [...users]
                .filter((x) => x.value !== -1)
                .map((item) => item.value);
              form.setFields([
                { name: ["mapTypes", name, "usersOnMap"], value: allUsers },
              ]);
            } else {
              form.setFields([
                { name: ["mapTypes", name, "usersOnMap"], value: value },
              ]);
            }
          }}
          optionFilterProp="children"
          filterOption={(input, option) =>
            (option?.label ?? "").includes(input)
          }
          options={users}
          disabled={mode === "preview"}
        />
      </Form.Item>
    );
  };

  const generateFormRow = (key, leftColumn, rightColumn) => {
    return (
      <Row gutter={16} key={key}>
        {rightColumn ? (
          <>
            <Col span={12} xs={24} sm={12}>
              {leftColumn}
            </Col>
            <Col span={12} xs={24} sm={12}>
              {rightColumn}
            </Col>
          </>
        ) : (
          <Col span={24}>{leftColumn}</Col>
        )}
      </Row>
    );
  };

  const mapType = (name, restField, errors) => (
    <MapType name={name} restField={restField} errors={errors} />
  );
  const mapStatus = (name, restField) => (
    <MapStatus name={name} restField={restField} />
  );
  const usersOnMap = (name, restField) => (
    <UsersOnMap name={name} restField={restField} />
  );

  return (
    <Form
      form={form}
      initialValues={initialValues}
      name="mapsForm"
      onFinish={onFinish}
      autoComplete="off"
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
      }}
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
      onValuesChange={(changedValues, allValues) => {
        handleFieldsChange("userMapForm", changedValues, allValues);
      }}
    >
      <Form.List name="mapTypes">
        {(fields, { add, remove }, { errors }) => (
          <>
            {fields.map(({ key, name, restField }) => (
              <div
                key={key}
                style={{
                  marginTop: "16px",
                  padding: "16px",
                  background: "rgba(0, 0, 0, 0.02)",
                }}
              >
                {generateFormRow(
                  key,
                  mapType(name, restField, errors),
                  mapStatus(name, restField)
                )}
                {generateFormRow(key + 1, usersOnMap(name, restField), null)}
                <Col>
                  <Button
                    danger
                    icon={<MinusCircleOutlined />}
                    onClick={() => showConfirm(remove, name, fields)}
                    style={{ width: "100%", marginTop: "16px" }}
                    disabled={mode === "preview"}
                  >
                    Obriši mapu
                  </Button>
                </Col>
              </div>
            ))}
            <Form.Item>
              <Button
                type="dashed"
                onClick={() => {
                  add();
                }}
                block
                icon={<PlusOutlined />}
                style={{ marginTop: "16px" }}
                disabled={mode === "preview"}
              >
                Dodaj novu mapu
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>
    </Form>
  );
};

export default UserMapSubForm;
