import React, {useEffect, useState} from "react";
import {Button, Flex, Form, Input, InputNumber, Select, theme} from "antd";
import {PlusOutlined} from "@ant-design/icons";
import {axiosInstance} from "../../../api/axiosInstance";
import {toast} from "react-toastify";

const SalaryForm = ({
                        form,
                        mode,
                        initialValues,
                        setInitialValues,
                        onFinish,
                        disabled,
                        users,
                        userId,
                        setUserId,
                    }) => {
    const {token} = theme.useToken();

    const formStyle = {
        maxWidth: "none",
        background: token.colorFillAlter,
        borderRadius: token.borderRadiusLG,
        padding: 24,
    };

    useEffect(() => {
        form.setFieldsValue(initialValues);
    }, [initialValues]);

    useEffect(() => {
        if (mode === 'insert') {
            form.resetFields();
        }
    }, [mode]);
    const twoPointDecimal = (number) => Number(number).toFixed(2);

    return (
        <Form
            form={form}
            name="salaryForm"
            style={formStyle}
            onFinish={(values) =>
                onFinish({
                    ...values,
                    id: initialValues?.id,
                    address: {...values.address, id: initialValues?.address?.id},
                })
            }
            initialValues={initialValues}
            layout={"vertical"}
            disabled={disabled}
        >
            <Form.Item
                name={"userId"}
                label={`Zaposlenik`}
                rules={[
                    {
                        required: true,
                        message: "Zaposlenik je obavezno polje!",
                    },
                ]}
            >
                <Select
                    options={users}
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                        (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
                    }
                    onChange={(value) => setUserId(value)}
                    disabled={mode === 'edit' || mode === 'preview'}
                ></Select>
            </Form.Item>
            { mode !== 'edit' && mode !== 'preview' ?
               <div
                   style={{display: 'flex', justifyContent: 'flex-end'}}
               >
                   <Button
                       type="dashed"
                       icon={<PlusOutlined />}
                       onClick={async () => {
                           axiosInstance.get(`/api/v1/salaries/find-last-salary/user/${userId}`)
                               .then(res => {

                                   const incrementedMonth =  res.data.month === 12 ? 1  : Number(res.data.month) + 2;
                                   const incrementedYear =  res.data.month === 12 ? Number(res.data.year) + 1 : Number(res.data.year);

                                   setInitialValues({...res.data, year: incrementedYear, month: incrementedMonth});
                                   form.resetFields();
                               })
                               .catch((error) => {
                                   console.log(error.response.data)
                                   toast.error(error.response.data.errorMessage);
                           }).finally(() => {
                               setUserId(null);
                           })
                       }}
                       disabled={!userId}
                   >
                       Kopiraj prethodnu plaću
                   </Button>
               </div> : <></>
            }
            <Form.Item
                name={"year"}
                label={`Godina:`}
                rules={[
                    {
                        required: true,
                        message: "Godina je obavezno polje!",
                    },
                ]}
            >
                <InputNumber
                    step={1}
                    style={{width: "100%"}}
                    placeholder="Godina"
                    disabled={mode === 'edit' || mode === 'preview'}
                />
            </Form.Item>
            <Form.Item
                name={"month"}
                label={`Mjesec:`}
                rules={[
                    {
                        required: true,
                        message: "Mjesec je obavezno polje!",
                    },
                ]}
            >
                <Select
                    options={[
                        {value: '', label: 'Odaberite mjesec'},
                        {value: 1, label: 'Siječanj'},
                        {value: 2, label: 'Veljača'},
                        {value: 3, label: 'Ožujak'},
                        {value: 4, label: 'Travanj'},
                        {value: 5, label: 'Svibanj'},
                        {value: 6, label: 'Lipanj'},
                        {value: 7, label: 'Srpanj'},
                        {value: 8, label: 'Kolovoz'},
                        {value: 9, label: 'Rujan'},
                        {value: 10, label: 'Listopad'},
                        {value: 11, label: 'Studeni'},
                        {value: 12, label: 'Prosinac'}
                    ]}
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                        (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
                    }
                    disabled={mode === 'edit' || mode === 'preview'}
                ></Select>
            </Form.Item>
            <Form.Item
                name={"healthInsurance"}
                label={`Zdravstveno (€):`}
                rules={[
                    {
                        required: false,
                        message: "Zdravstveno je obavezno polje!",
                    },
                ]}
            >
                <InputNumber
                    precision={2}
                    step={0.1}
                    style={{width: "100%"}}
                    placeholder="Zdravstveno"
                />
            </Form.Item>
            <Form.Item
                name={"pensionInsuranceSecondPillar"}
                label={`Mirovinsko osiguranje drugi stup (€):`}
                rules={[
                    {
                        required: false,
                        message: "Mirovinsko osiguranje drugi stup je obavezno polje!",
                    },
                ]}
            >
                <InputNumber
                    precision={2}
                    step={0.1}
                    style={{width: "100%"}}
                    placeholder="Mirovinsko osiguranje drugi stup"
                />
            </Form.Item>
            <Form.Item
                name={"pensionInsuranceFirstPillar"}
                label={`Mirovinsko osiguranje prvi stup (€):`}
                rules={[
                    {
                        required: false,
                        message: "Mirovinsko osiguranje prvi stup je obavezno polje!",
                    },
                ]}
            >
                <InputNumber
                    precision={2}
                    step={0.1}
                    style={{width: "100%"}}
                    placeholder="Mirovinsko osiguranje prvi stup"
                />
            </Form.Item>
            <Form.Item
                name={"surtax"}
                label={`Prirez (€):`}
                rules={[
                    {
                        required: false,
                        message: "Prirez je obavezno polje!",
                    },
                ]}
            >
                <InputNumber
                    precision={2}
                    step={0.1}
                    style={{width: "100%"}}
                    placeholder="Prirez"
                />
            </Form.Item>
            <Form.Item
                name={"salary"}
                label={`Plaća (€):`}
                rules={[
                    {
                        required: false,
                        message: "Plaća je obavezno polje!",
                    },
                ]}
            >
                <InputNumber
                    precision={2}
                    step={0.1}
                    style={{width: "100%"}}
                    placeholder="Plaća"
                />
            </Form.Item>
            <Form.Item
                name={"fieldWork"}
                label={`Terenske (€):`}
                rules={[
                    {
                        required: false,
                        message: "Terenske je obavezno polje!",
                    },
                ]}
            >
                <InputNumber
                    precision={2}
                    step={0.1}
                    style={{width: "100%"}}
                    placeholder="Terenske"
                />
            </Form.Item>
            <Form.Item
                name={"transport"}
                label={`Prijevoz (€):`}
                rules={[
                    {
                        required: false,
                        message: "Prijevoz je obavezno polje!",
                    },
                ]}
            >
                <InputNumber
                    precision={2}
                    step={0.1}
                    style={{width: "100%"}}
                    placeholder="Prijevoz"
                />
            </Form.Item>
            <Form.Item
                name={"meal"}
                label={`Topli obrok (€):`}
                rules={[
                    {
                        required: false,
                        message: "Topli obrok je obavezno polje!",
                    },
                ]}
            >
                <InputNumber
                    precision={2}
                    step={0.1}
                    style={{width: "100%"}}
                    placeholder="Topli obrok"
                />
            </Form.Item>
            <Form.Item
                name={"rewards"}
                label={`Nagrade (€):`}
                rules={[
                    {
                        required: false,
                        message: "Nagrade su obavezno polje!",
                    },
                ]}
            >
                <InputNumber
                    precision={2}
                    step={0.1}
                    style={{width: "100%"}}
                    placeholder="Nagrade"
                />
            </Form.Item>
            <Form.Item
                name={"other"}
                label={`Ostalo (€):`}
                rules={[
                    {
                        required: false,
                        message: "Ostalo je obavezno polje!",
                    },
                ]}
            >
                <InputNumber
                    precision={2}
                    step={0.1}
                    style={{width: "100%"}}
                    placeholder="Ostalo"
                />
            </Form.Item>
            <Form.Item
                name={"totalHours"}
                label={`Broj sati:`}
                rules={[
                    {
                        required: true,
                        message: "Broj sati je obavezno polje!",
                    },
                ]}
            >
                <InputNumber
                    min={0}
                    max={320}
                    step={1}
                    style={{width: "100%"}}
                    placeholder="Broj sati"
                />
            </Form.Item>
        </Form>
    );
};

export default SalaryForm;
