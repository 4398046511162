import React from "react";
import { Button, Flex, Form, Select, Input, Space, theme } from "antd";

import "./IraItemNameTemplateFilter.css";

const IraItemNameTemplateFilter = ({ form, onFormFilterSubmit }) => {
  const { token } = theme.useToken();

  const formStyle = {
    maxWidth: "none",
    background: token.colorFillAlter,
    borderRadius: token.borderRadiusLG,
    padding: 24,
    borderColor: "rgba(0, 0, 0, 0.03)",
    borderStyle: "solid",
    borderWidth: "thin",
  };

  return (
    <Form
      form={form}
      name="IraItemTemplateAdministrationPageFilter"
      style={formStyle}
      onFinish={onFormFilterSubmit}
      layout={"vertical"}
      initialValues={{
        projectId: "",
      }}
    >
      <Flex vertical>
        <Form.Item
            name={`projectId`}
            label={`Posao:`}
            rules={[
              {
                required: false,
                message: "Posao je obavezno polje!",
              },
            ]}
        >
          <Select
              options={[
                { label: "Prikaži sve", value: "" },
                { label: "Aktivan", value: true },
                { label: "Neaktivan", value: false },
              ].map((item) => ({ label: item.label, value: item.value }))}
          ></Select>
        </Form.Item>
   
        <Flex
          justify="end"
          align="center"
          className="button-container"
          gap={"4px"}
        >
          <Button
            type="primary"
            htmlType="submit"
            style={{ minWidth: "120px" }}
          >
            Pretraži
          </Button>
          <Button
            onClick={() => {
              form.resetFields();
            }}
            style={{ minWidth: "120px" }}
          >
            Poništi filtere
          </Button>
        </Flex>
      </Flex>
    </Form>
  );
};

export default IraItemNameTemplateFilter;
