import React, { useRef, useEffect } from "react";
import {
    Form,
    Input
} from "antd";

const EnergyRenewalSubForm = ({selectedSubProjectId}) => {

    // if subProject not selected
    if(!selectedSubProjectId){
        return <></>
    }

    // show only family houses and renewable sources subprojects
    if(selectedSubProjectId !== 14 && selectedSubProjectId !== 15){
        return <></>
    }

    return (<>
        <Form.Item
            name={["additionalFields", "odradjenTeren"]}
            label={`Odrađen teren`}
        >
            <Input placeholder="Odrađen teren" />
        </Form.Item>
        <Form.Item
            name={["additionalFields", "prijava"]}
            label={`Prijava`}
        >
            <Input placeholder="Prijava" />
        </Form.Item>
        <Form.Item
            name={["additionalFields", "nias"]}
            label={`NIAS`}
        >
            <Input placeholder="NIAS" />
        </Form.Item>
        <Form.Item
            name={["additionalFields", "certifikat"]}
            label={`Certifikat`}
        >
            <Input placeholder="Certifikat" />
        </Form.Item>
        <Form.Item
            name={["additionalFields", "glavniProjektFotonapon"]}
            label={`Glavni projekt fotonapon`}
        >
            <Input placeholder="Glavni projekt fotonapon" />
        </Form.Item>
        <Form.Item
            name={["additionalFields", "omp"]}
            label={`OMP`}
        >
            <Input placeholder="OMP" />
        </Form.Item>
        <Form.Item
            name={["additionalFields", "dozvola"]}
            label={`Dozvola`}
        >
            <Input placeholder="Dozvola" />
        </Form.Item>
        <Form.Item
            name={["additionalFields", "osobna"]}
            label={`Osobna`}
        >
            <Input placeholder="Osobna" />
        </Form.Item>
        <Form.Item
            name={["additionalFields", "fotodokumentacija"]}
            label={`Fotodokumentacija`}
        >
            <Input placeholder="DA / NE" />
        </Form.Item>
        <Form.Item
            name={["additionalFields", "vlasnickiList"]}
            label={`Vlasnički list`}
        >
            <Input placeholder="Vlasnički list" />
        </Form.Item>
        <Form.Item
            name={["additionalFields", "potvrdaKucniBroj"]}
            label={`Potvrda o kućnom broju`}
        >
            <Input placeholder="Potvrda o kućnom broju" />
        </Form.Item>
        {/* hide this section when renewable sources is selected */}
        { selectedSubProjectId === 14 ? 
        <>
            <Form.Item
                name={["additionalFields", "fasada"]}
                label={`Fasada`}
            >
                <Input placeholder="Fasada" />
            </Form.Item>
            <Form.Item
                name={["additionalFields", "stolarija"]}
                label={`Stolarija`}
            >
                <Input placeholder="Stolarija" />
            </Form.Item>
            <Form.Item
                name={["additionalFields", "tavan"]}
                label={`Tavan`}
            >
                <Input placeholder="Tavan" />
            </Form.Item>
            <Form.Item
                name={["additionalFields", "krov"]}
                label={`Krov`}
            >
                <Input placeholder="Krov" />
            </Form.Item>
        </> : <></>
        }
        <Form.Item
            name={["additionalFields", "grijanje"]}
            label={`Grijanje`}
        >
            <Input placeholder="Grijanje" />
        </Form.Item>
        <Form.Item
            name={["additionalFields", "fn"]}
            label={`FN`}
        >
            <Input placeholder="FN" />
        </Form.Item>
        <Form.Item
                name={["additionalFields", "zidPremaNegrijanom"]}
                label={`Zid prema negrijanom`}
            >
                <Input placeholder="Zid prema negrijanom" />
            </Form.Item>
    </>)
}

export default EnergyRenewalSubForm;