import React, {useEffect, useRef, useState} from "react";
import {
    MinusCircleOutlined,
    PlusOutlined,
    ExclamationCircleOutlined,
} from "@ant-design/icons";
import {Button, Form, Modal, Select, Row, Col, InputNumber, Input} from "antd";

import {axiosInstance} from "../../../api/axiosInstance";
import {toast} from "react-toastify";
import TextArea from "antd/es/input/TextArea";

const onFinish = (values) => {
};

const IraItemForm = ({
                         form,
                         iraItemFormInitialValues,
                         projects,
                         jm,
                         mode,
                         handleFieldsChange,
                         uraItemFormErrors,
                         setUraItemFormErrors,
                         forceUpdate
                     }) => {
    const {confirm} = Modal;

    const discountRef = useRef(null);

    const handleFocus = () => {
        if (discountRef.current) {
            discountRef.current.select();
        }
    };

    const validateFields = () => {
        try {
            form.validateFields();
        } catch (ex) {
            setUraItemFormErrors(ex.errorFields);
            console.log(ex.errorFields);
        }
    };

    useEffect(() => {
        const values = form.getFieldsValue();
        console.log('Updated values:', values);
    }, [forceUpdate]);

    useEffect(() => {
        form.setFieldsValue(iraItemFormInitialValues);
        form.resetFields();
    }, [iraItemFormInitialValues]);

    const showConfirm = (remove, fieldName, fields) => {
        const itemId =
            form.getFieldValue("items")[fields[fieldName]?.name]?.itemId;
        confirm({
            icon: <ExclamationCircleOutlined/>,
            centered: true,
            title: "Upozorenje",
            content: "Jeste li sigurni da želite obrisati stavku računa?",
            okText: "Potvrdi",
            cancelText: "Odustani",
            onOk() {
                if (itemId) {
                    axiosInstance
                        .delete(`/api/v1/ura/item/${itemId}`)
                        .then((res) => {
                            remove(fieldName);
                        })
                        .catch((err) => {
                            toast.error("Dogodila se greška prilikom brisanja polja.");
                        });
                } else {
                    remove(fieldName);
                }
            },
            onCancel() {
            },
        });
    };


    const twoPointDecimal = (number) => Number(number).toFixed(2);

    const Project = ({name, restField}) => {
        const errorMessage = uraItemFormErrors.filter(x => x.name[1] === name && x.name[2] === 'projectId')[0]?.errors[0]
        return (
            <Form.Item
                validateStatus={errorMessage ? "error" : ""}
                help={errorMessage ? errorMessage : ''}
                label={`Posao:`}
                {...restField}
                name={[name, "projectId"]}
                rules={[
                    {
                        required: true,
                        message: "Posao je obavezno polje",
                    },
                ]}
            >
                <Select
                    showSearch
                    dropdownRender={(menu) => (
                        <div>
                            {React.cloneElement(menu, {
                                style: {...menu.props.style}, // Set the minimum width as needed
                            })}
                        </div>
                    )}
                    placeholder="Posao"
                    popupMatchSelectWidth={false}
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                        (option?.label ?? "").includes(input)
                    }

                    options={projects}
                    disabled={mode === "preview"}
                    onChange={(value) => {
                        const projectPrice = projects.filter(x => x.value === value)[0]?.price;
                        form.setFieldValue(["items", name, "priceWithoutPdv"], projectPrice ? twoPointDecimal(projectPrice): twoPointDecimal(0));
                        validateFields();
                    }}
                />
            </Form.Item>
        );
    };

    const JM = ({name, restField}) => {
        const errorMessage = uraItemFormErrors.filter(x => x.name[1] === name && x.name[2] === 'jm')[0]?.errors[0]
        return (
            <Form.Item
                validateStatus={errorMessage ? "error" : ""}
                help={errorMessage ? errorMessage : ''}
                label={`JM:`}
                {...restField}
                name={[name, "jmId"]}
                rules={[
                    {
                        required: false,
                        message: "Jedinica mjere je obavezno polje",
                    },
                ]}
            >
                <Select
                    showSearch
                    dropdownRender={(menu) => (
                        <div>
                            {React.cloneElement(menu, {
                                style: {...menu.props.style}, // Set the minimum width as needed
                            })}
                        </div>
                    )}
                    placeholder="JM"
                    popupMatchSelectWidth={false}
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                        (option?.label ?? "").includes(input)
                    }
                    filterSort={(optionA, optionB) =>
                        (optionA?.label ?? "")
                            .toLowerCase()
                            .localeCompare((optionB?.label ?? "").toLowerCase())
                    }
                    options={jm}
                    disabled={mode === "preview"}
                    onChange={() => {
                        validateFields();
                    }}
                />
            </Form.Item>
        );
    };

    const ItemName = ({name, restField}) => {
        const errorMessage = uraItemFormErrors.filter(x => x.name[1] === name && x.name[2] === 'name')[0]?.errors[0]
        return (
            <Form.Item
                validateStatus={errorMessage ? "error" : ""}
                help={errorMessage ? errorMessage : ''}
                label={`Naziv:`}
                {...restField}
                name={[name, "name"]}
                rules={[
                    {
                        required: true,
                        message: "Naziv je obavezno polje",
                    },
                ]}
            >
                <TextArea placeholder="Naziv" size={"small"}/>
            </Form.Item>
        );
    };

    const Amount = ({name, restField}) => {
        const errorMessage = uraItemFormErrors.filter(x => x.name[1] === name && x.name[2] === 'amount')[0]?.errors[0]
        return (<Form.Item
                name={[name, "amount"]}
                {...restField}
                validateStatus={errorMessage ? "error" : ""}
                help={errorMessage ? errorMessage : ''}
                label={`Količina:`}
                rules={[
                    {
                        required: true,
                        message: "Količina je obavezno polje!",
                    },
                ]}
            >
                <InputNumber
                    step={1}
                    min={0}
                    style={{width: "100%"}}
                    placeholder="Iznos"
                />
            </Form.Item>
        );
    }

    const PriceWithoutPdv = ({name, restField}) => {
        const errorMessage = uraItemFormErrors.filter(x => x.name[1] === name && x.name[2] === 'priceWithoutPdv')[0]?.errors[0]
        return (<Form.Item
                name={[name, "priceWithoutPdv"]}
                {...restField}
                validateStatus={errorMessage ? "error" : ""}
                help={errorMessage ? errorMessage : ''}
                label={`Cijena (€):`}
                rules={[
                    {
                        required: true,
                        message: "Cijena je obavezno polje!",
                    },
                ]}
            >
                <InputNumber
                    precision={2}
                    step={0.1}
                    min={0}
                    style={{width: "100%"}}
                    placeholder="Cijena"
                />
            </Form.Item>
        );
    }

    const Rebate = ({name, restField}) => {
        const errorMessage = uraItemFormErrors.filter(x => x.name[1] === name && x.name[2] === 'rebate')[0]?.errors[0]
        return (<Form.Item
                name={[name, "rebate"]}
                {...restField}
                validateStatus={errorMessage ? "error" : ""}
                help={errorMessage ? errorMessage : ''}
                label={`Popust (€):`}
                rules={[
                    {
                        required: true,
                        message: "Popust je obavezno polje!",
                    }, ({getFieldValue}) => ({
                        validator(_, value) {
                            if (!value || getFieldValue(["items", name, "priceWithoutPdv"]) >= value) {
                                return Promise.resolve();
                            }
                            return Promise.reject(new Error("Popust ne može biti veći od cijene!"));
                        },
                    })
                ]}
            >
                <InputNumber
                    precision={2}
                    step={0.1}
                    min={0}
                    style={{width: "100%"}}
                    placeholder="Popust"
                    ref={discountRef} onFocus={handleFocus}
                />
            </Form.Item>
        );
    }

    const generateFormRow = (key, leftColumn, rightColumn) => {
        return (
            <Row gutter={16} key={key}>
                {rightColumn ? (
                    <>
                        <Col span={12} xs={24} sm={12}>
                            {leftColumn}
                        </Col>
                        <Col span={12} xs={24} sm={12}>
                            {rightColumn}
                        </Col>
                    </>
                ) : (
                    <Col span={24}>{leftColumn}</Col>
                )}
            </Row>
        );
    };

    const projectField = (name, restField, errors) => (
        <Project name={name} restField={restField} errors={errors}/>
    );
    const itemNameField = (name, restField, errors) => (
        <ItemName name={name} restField={restField} errors={errors}/>
    );

    const jmField = (name, restField, errors) => (
        <JM name={name} restField={restField} errors={errors}/>
    );
    const amountField = (name, restField, errors) => (
        <Amount name={name} restField={restField} errors={errors}/>
    );

    const priceField = (name, restField, errors) => (
        <PriceWithoutPdv name={name} restField={restField} errors={errors}/>
    );

    const rebateField = (name, restField, errors) => (
        <Rebate name={name} restField={restField} errors={errors}/>
    );

    return (
        <Form
            form={form}
            initialValues={iraItemFormInitialValues}
            name="iraItemForm"
            onFinish={onFinish}
            autoComplete="off"
            onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
            }}
            labelCol={{span: 24}}
            wrapperCol={{span: 24}}
            onValuesChange={(changedValues, allValues) => {
                handleFieldsChange("iraItemForm", changedValues, allValues);
            }}
        >
            <Form.List name="items">
                {(fields, {add, remove}, {errors}) => (
                    <>
                        {fields.map(({key, name, restField, getFieldValue}) => (
                            <div
                                key={key}
                                style={{
                                    marginTop: "16px",
                                    padding: "16px",
                                    background: "rgba(0, 0, 0, 0.02)",
                                }}
                            >
                                {generateFormRow(
                                    "1",
                                    projectField(name, restField, errors),
                                    itemNameField(name, restField, errors)
                                )}
                                {generateFormRow(
                                    "2",
                                    amountField(name, restField, errors),
                                    priceField(name, restField, errors)
                                )}
                                {generateFormRow(
                                    "3",
                                    rebateField(name, restField, errors),
                                    null
                                )}
                            </div>
                        ))}
                        <Form.Item>
                            <Button
                                type="dashed"
                                onClick={() => {
                                    add({projectId: '', jmId: 1, amount: 1, rebate: 0.00});
                                }}
                                block
                                icon={<PlusOutlined/>}
                                disabled={fields && fields.length >= 1}
                                style={{display: fields && fields.length >= 1 ? "none" : "block"}}
                            >
                                Dodaj novu stavku
                            </Button>
                        </Form.Item>
                    </>
                )}
            </Form.List>
        </Form>
    );
};

export default IraItemForm;
