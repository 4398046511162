import React, {useEffect, useState} from "react";
import {Button, Flex, Form, Select, Space, theme, DatePicker} from "antd";
import {DownOutlined} from "@ant-design/icons";

import "./UraFilter.css";
import {axiosInstance} from "../../../api/axiosInstance";

const UraFilter = ({
                       form,
                       onFormFilterSubmit,
                       owners,
                       suppliers,
                       allExpenseTypes,
                       expenseTypes,
                       expenseSubTypes,
                       setExpenseSubTypes,
                       expenseSubTypeDisabled,
                       setExpenseSubTypeDisabled,
                       supplierFilterFieldDisabled,
                       handleFieldsChange
                   }) => {
    const {token} = theme.useToken();
    const [expand, setExpand] = useState(false);

    const formStyle = {
        maxWidth: "none",
        background: token.colorFillAlter,
        borderRadius: token.borderRadiusLG,
        padding: 24,
        borderColor: "rgba(0, 0, 0, 0.03)",
        borderStyle: "solid",
        borderWidth: "thin",
    };


    return (
        <Form
            form={form}
            name="projectFilter"
            style={formStyle}
            onFinish={onFormFilterSubmit}
            onValuesChange={(changedValues, allValues) => {
                handleFieldsChange('filterForm', changedValues, allValues);
            }}
            layout={"vertical"}
            initialValues={{
                ownerId: '',
                expenseTypeId: '',
                expenseSubTypeId: '',
                supplierId: '',
                dateFrom: '',
                dateTo: '',
            }}
        >
            <Flex vertical>
                {expand ?
                    <React.Fragment>
                        <Form.Item
                            name={`ownerId`}
                            label={`Nositelj:`}
                            rules={[
                                {
                                    required: false,
                                    message: "Nositelj je obavezno polje!",
                                },
                            ]}
                        >
                            <Select
                                options={owners}
                                showSearch
                                optionLabelProp="label"
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    (option?.label ?? "").includes(input)
                                }
                                optionRender={(option) => <Space>{option.data.label}</Space>}
                            ></Select>
                        </Form.Item>
                        <Form.Item
                            name={`expenseTypeId`}
                            label={`Vrsta troška:`}
                            rules={[
                                {
                                    required: false,
                                    message: "Vrsta troška je obavezno polje!",
                                },
                            ]}
                        >
                            <Select
                                options={expenseTypes}
                                onChange={(value) => {
                                    if (!value) {
                                        setExpenseSubTypeDisabled(true);
                                    } else {
                                        const expenseSubTypes = allExpenseTypes.filter(x => x.parentId === value).map((x) => {
                                            return {value: x.id, label: `${x.name}`};
                                        });
                                        setExpenseSubTypes(expenseSubTypes);
                                        if (expenseSubTypes && expenseSubTypes.length > 0) {
                                            form.setFieldValue('expenseSubTypeId', expenseSubTypes[0].value)
                                            setExpenseSubTypeDisabled(false);
                                        } else {
                                            form.setFieldValue('expenseSubTypeId', '')
                                            setExpenseSubTypeDisabled(true);
                                        }
                                    }
                                }}
                            ></Select>
                        </Form.Item>
                        {/*    <Form.Item
                            name={`expenseSubTypeId`}
                            label={`Podvrsta troška:`}
                            rules={[
                                {
                                    required: false,
                                    message: "Podvrsta troška je obavezno polje!",
                                },
                            ]}
                        >
                            <Select options={expenseSubTypes} disabled={expenseSubTypeDisabled}></Select>
                        </Form.Item>*/}
                        <Form.Item
                            name={`supplierId`}
                            label={`Dobavljač:`}
                            rules={[
                                {
                                    required: false,
                                    message: "Dobavljač je obavezno polje!",
                                },
                            ]}
                        >
                            <Select
                                options={suppliers}
                                showSearch
                                optionLabelProp="label"
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    (option?.label ?? "").includes(input)
                                }
                                optionRender={(option) => <Space>{option.data.label}</Space>}
                                disabled={supplierFilterFieldDisabled}
                            ></Select>
                        </Form.Item>
                        <Form.Item
                            name={`dateFrom`}
                            label={`Datum od:`}
                            rules={[
                                {
                                    required: false,
                                    message: "Datum od je obavezno polje!",
                                },
                            ]}
                        >
                            <DatePicker onChange={(date) => {
                            }}
                                        style={{width: '100%'}}
                                        placeholder={"Datum od"}
                                        format={"DD.MM.YYYY."}
                            />
                        </Form.Item>
                        <Form.Item
                            name={`dateTo`}
                            label={`Datum do:`}
                            rules={[
                                {
                                    required: false,
                                    message: "Datum do je obavezno polje!",
                                },
                            ]}
                        >
                            <DatePicker onChange={(date) => {
                            }}
                                        style={{width: '100%'}}
                                        placeholder={"Datum do"}
                                        format={"DD.MM.YYYY."}
                            />
                        </Form.Item>
                    </React.Fragment>
                    : <Form.Item
                        name={`ownerId`}
                        label={`Nositelj:`}
                        rules={[
                            {
                                required: false,
                                message: "Nositelj je obavezno polje!",
                            },
                        ]}
                    >
                        <Select
                            options={owners}
                            showSearch
                            optionLabelProp="label"
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                                (option?.label ?? "").includes(input)
                            }
                            optionRender={(option) => <Space>{option.data.label}</Space>}
                        ></Select>
                    </Form.Item>
                }

                <Flex justify="end" align="center" style={{marginBottom: '32px'}}>
                    <a
                        style={{fontSize: 12}}
                        onClick={() => {
                            setExpand(!expand);
                        }}
                    >
                        <DownOutlined rotate={expand ? 180 : 0}/> {expand ? "Skupi filter" : "Proširi filter"}
                    </a>
                </Flex>
                <Flex
                    justify="end"
                    align="center"
                    className="button-container"
                    gap={"4px"}
                >
                    <Button
                        type="primary"
                        htmlType="submit"
                        style={{minWidth: "120px"}}
                    >
                        Pretraži
                    </Button>
                    <Button
                        onClick={() => {
                            form.resetFields();
                        }}
                        style={{minWidth: "120px"}}
                    >
                        Poništi filtere
                    </Button>
                </Flex>
            </Flex>
        </Form>
    );
};

export default UraFilter;
