import React, {useEffect, useMemo, useState} from "react";
import {Table, Button, Flex, Tooltip, Modal, Space, Empty} from "antd";
import {
    EditOutlined,
    SearchOutlined,
    DeleteOutlined,
    ExclamationCircleOutlined,
    PlusCircleOutlined,
    AccountBookOutlined
} from "@ant-design/icons";

import '../UraTable/UraTable.css'
import {axiosInstance} from "../../../api/axiosInstance"
import {toast} from "react-toastify";
import dayjs from 'dayjs';
import customParseFormat from "dayjs/plugin/customParseFormat";
import uraItemForm from "../UraItemForm/UraItemForm";

dayjs.extend(customParseFormat);


const UraTable = ({
                      userData,
                      tableData,
                      pageNumber,
                      pageSize,
                      totalRows,
                      onFilterChanged,
                      setMode,
                      isCombined,
                      setRowData,
                      setUraModalOpened,
                      allExpenseTypes,
                      setGeneralFormExpenseSubTypes,
                      uraItemFormInitialValues,
                      setUraItemFormInitialValues,
                      setSupplierFieldDisabled,
                      fetchSuppliers,
                      setSuppliers,
                      refreshData,
                      setFileList,
                  }) => {
    const [arrow, setArrow] = useState("Show");
    const [expandedRowKeys, setExpandedRowKeys] = useState([]);

    const [modal, contextHolder] = Modal.useModal();
    const isUserRole = userData?.authorities?.includes('ROLE_ZAPOSLENIK');
    const isAdminRole = userData?.authorities?.includes('ROLE_ADMINISTRATOR');

    useEffect(() => {
        setExpandedRowKeys([])
    }, [tableData])

    const fetchUraItems = async (id) => {
        try {
            const res = await axiosInstance.get(`api/v1/ura/items/${id}`)
            if (res) {
                setUraItemFormInitialValues({items: res.data});
            }
        } catch (ex) {
            console.log('Error while calling fetchSuppliersByExpenseTypeId().')
        }
    }

    const fetchUraFile = (id) => {
        if(!id){
            return;
        }
        try {
            axiosInstance.get(`/api/v1/files/ura/${id}`).then(res => {
                const file = res.data;
                if (file) {
                    setFileList(oldArray => [...oldArray, {
                        uuid: file.uuid,
                        name: file.name,
                        status: 'done'
                    }]);
                }
            })
        } catch (ex) {
            console.log('Error while calling fetchUraFile().')
        }
    }


    const confirm = (id, combined) => {
        modal.confirm({
            title: "Potvrda",
            icon: <ExclamationCircleOutlined/>,
            content: "Jeste li sigurni da želite obrisati račun?",
            okText: "Da",
            cancelText: "Ne",
            centered: true,
            onOk: () => {
                axiosInstance.delete(`/api/v1/ura/${id}?combined=${combined}`)
                    .then(res => {
                        toast.success("Račun uspješno obrisan!");
                        refreshData();
                    }).catch(ex => {
                    toast.error(`${ex.response.data.errorMessage}`);
                })
            },
        });
    };

    const mergedArrow = useMemo(() => {
        if (arrow === "Hide") {
            return false;
        }

        if (arrow === "Show") {
            return true;
        }

        return {
            pointAtCenter: true,
        };
    }, [arrow]);

    const tableColumns = () => {
        return [
            {
                title: "Oznaka URA",
                dataIndex: "name",
                key: "name"
            },
            {
                title: "Dobavljač",
                dataIndex: ["supplier", "name"],
                key: "supplier",
            },
            {
                title: "Nositelj",
                dataIndex: ["owner", "nameStr"],
                key: "owner",
            },
            {
                title: "Tvrtka",
                dataIndex: ["company", "name"],
                key: "company",
            },
            {
                title: "Vrsta troška",
                dataIndex: ["expenseType", "name"],
                key: "expenseType",
            },
            {
                title: "Iznos troška bez PDV-a",
                dataIndex: "priceWithoutPdv",
                key: "priceWithoutPdv",
                render: (text, record, index) => {
                    return <>{text + " €"}</>
                },
                sortDirections: ['ascend', 'descend'],
                sorter: true,
            },
            {
                title: "Iznos PDV-a",
                dataIndex: "pdvPrice",
                key: "pdvPrice",
                render: (text, record, index) => {
                    return <>{text + " €"}</>
                },
                sortDirections: ['ascend', 'descend'],
                sorter: true,
            },
            {
                title: "Iznos troška s PDV-om",
                dataIndex: "priceWithPdv",
                key: "priceWithPdv",
                render: (text, record, index) => {
                    return <>{text + " €"}</>
                },
                sortDirections: ['ascend', 'descend'],
                sorter: true,
            },
            {
                title: "",
                key: "operation",
                //fixed: "right",
                width: "200px",
                render: (text, record, index) => (
                    <Flex vertical={false} gap={"4px"} style={{justifyContent: 'center'}}>
                        <>
                            <Tooltip
                                placement="top"
                                title={<span>Uređivanje</span>}
                                arrow={mergedArrow}
                            >
                                <Button
                                    type="text"
                                    size="large"
                                    icon={<EditOutlined/>}
                                    onClick={async () => {
                                        await fetchUraItems(record.uraId)
                                        setMode("edit");

                                        const expenseSubTypeParentId = allExpenseTypes.filter(x => x.id === record.expenseType.id)[0].id;
                                        const expenseSubTypes = allExpenseTypes.filter(x => x.parentId === expenseSubTypeParentId).map((x) => {
                                            return {value: x.id, label: `${x.name}`};
                                        });

                                        setGeneralFormExpenseSubTypes(expenseSubTypes);

                                        if (record?.expenseType?.id) {
                                            const res = await fetchSuppliers(record?.expenseType?.id);
                                            const data = res?.data?.map((x) => {
                                                return {value: x.id, label: `${x.name}`};
                                            });
                                            setSuppliers([{value: "", label: "Prikaži sve"}].concat(data));
                                            setSupplierFieldDisabled(false);
                                        }

                                        setRowData({
                                            ...record,
                                            receiptCreationDate: dayjs(record?.creationDate),
                                            receiptPaymentDate: record.paymentDate ? dayjs(record?.paymentDate) : null,
                                            project: {
                                                ...record.project,
                                                id: record.project?.id ? record.project.id : ""
                                            },
                                            supplier: {
                                                ...record.supplier,
                                                id: record.supplier?.id ? record.supplier.id : ""
                                            }

                                        });

                                        fetchUraFile(record?.uraFileId);

                                        setUraModalOpened(true);
                                    }}
                                    disabled={userData?.authorities?.includes('ROLE_ZAPOSLENIK')}
                                />
                            </Tooltip>
                            <Tooltip
                                placement="top"
                                title={<span>Brisanje</span>}
                                arrow={mergedArrow}
                            >
                                <Space>
                                    <Button
                                        type="text"
                                        size="large"
                                        danger
                                        icon={<DeleteOutlined/>}
                                        onClick={() => confirm(record.id, isCombined)}
                                        disabled={userData?.authorities?.includes('ROLE_ZAPOSLENIK')}
                                    />
                                </Space>
                            </Tooltip>
                        </>
                    </Flex>
                ),
            },
        ].filter(item => !item.hidden);
    };
    const twoPointDecimal = (number) => Number(number).toFixed(2);

    const uraItemsTable = () => {
        return <Table
            size={"small"}
            bordered={true}
            style={{marginTop: '16px', marginBottom: '32px', marginRight: '32px'}}
            title={data => {
                return <p style={{marginLeft: '16px', marginTop: '4px', marginBottom: '4px'}}><b>Stavke računa</b></p>
            }}
            rowKey={"id"}
            columns={[
                {
                    title: "Rbr",
                    dataIndex: "id",
                    key: "id",
                    render: (id, record, index) => {
                        ++index;
                        return index;
                    },
                    align: "left"
                },
                {
                    title: "Posao",
                    dataIndex: "project",
                    key: "project",
                    render: (value, record, index) => {
                        return (<p>{value ? value : "-"}</p>);
                    },
                    align: "left"
                },
                {
                    title: "Podvrsta troška",
                    dataIndex: "expenseSubType",
                    key: "expenseSubType",
                    render: (value, record, index) => {
                        return (<p>{value ? value : "-"}</p>);
                    },
                    align: "left"
                },
                {
                    title: "PDV (%)",
                    dataIndex: "pdv",
                    key: "pdv",
                    render: value => {
                        return `${twoPointDecimal(value)} %`;
                    },
                    align: "left"
                },
                {
                    title: "Iznos",
                    dataIndex: "priceWithoutPdv",
                    key: "priceWithoutPdv",
                    render: value => {
                        return `${twoPointDecimal(value)} €`;
                    },
                    align: "left"
                }]
            }
            dataSource={uraItemFormInitialValues?.items}
            pagination={false}
        ></Table>;
    }

    return (
        <>
            <Table
                columns={tableColumns()}
                dataSource={tableData}
                onChange={(pagination, filters, sorter) =>
                    onFilterChanged(pagination, filters, sorter)
                }
                expandable={{
                    showExpandColumn: isCombined,
                    expandRowByClick: false,
                    expandedRowKeys: expandedRowKeys,
                    expandedRowRender: (record) => uraItemsTable(),
                    onExpand: (expanded, record) => {
                        const keys = [];
                        if (expanded) {
                            fetchUraItems(record.uraId).then(res => {
                                keys.push(record.id);
                                setExpandedRowKeys(keys);
                            })
                        } else {
                            setExpandedRowKeys(keys);
                        }
                    }
                }}
                style={{marginTop: "32px"}}
                pageNumber={pageNumber}
                pageSize={pageSize}
                pagination={{
                    defaultPageSize: 10,
                    showSizeChanger: true,
                    pageSizeOptions: ['10', '20', '30'],
                    total: totalRows,
                    showTotal: (total, range) => `Ukupan broj rezultata: ${totalRows}`
                }}
                bordered
                scroll={{x: 700}}
                locale={{emptyText: <Empty description="Nije pronađen niti jedan račun."/>}}
                summary={() => {
                    {
                        return tableData && tableData.length > 0 ?
                            <Table.Summary>
                                <Table.Summary.Row>
                                    <Table.Summary.Cell index={0} colSpan={!isCombined ? 5 : 6}>
                                        <b>Ukupan trošak</b>
                                    </Table.Summary.Cell>
                                    <Table.Summary.Cell index={6} colSpan={1}>
                                        {
                                            twoPointDecimal(tableData[0]?.totalSumWithoutVAT) + " €"
                                        }
                                    </Table.Summary.Cell>
                                    <Table.Summary.Cell index={7} colSpan={1}>
                                        {
                                            twoPointDecimal(tableData[0]?.totalSumVAT) + " €"
                                        }
                                    </Table.Summary.Cell>
                                    <Table.Summary.Cell index={8} colSpan={1}>
                                        {
                                            twoPointDecimal(tableData[0]?.totalSumWithVAT) + " €"
                                        }
                                    </Table.Summary.Cell>

                                </Table.Summary.Row>
                                <Table.Summary.Row>
                                    <Table.Summary.Cell index={0} colSpan={!isCombined ? 5 : 6}>
                                        <b>Porezno priznat trošak</b>
                                    </Table.Summary.Cell>
                                    <Table.Summary.Cell index={6} colSpan={1}>
                                        {
                                            twoPointDecimal(tableData[0]?.totalTaxDeductibleSum) + " €"
                                        }
                                    </Table.Summary.Cell>
                                    <Table.Summary.Cell index={7} colSpan={1}>
                                        {
                                            twoPointDecimal(tableData[0]?.totalTaxDeductibleVAT) + " €"
                                        }
                                    </Table.Summary.Cell>
                                    <Table.Summary.Cell index={8} colSpan={1}>
                                        {
                                            twoPointDecimal(tableData[0]?.totalTaxableSum) + " €"
                                        }
                                    </Table.Summary.Cell>

                                </Table.Summary.Row>
                            </Table.Summary> : <></>
                    }

                }}
            />
            {contextHolder}
        </>
    );
};
export default UraTable;
