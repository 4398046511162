import React, { useRef, useEffect } from "react";
import {
    Form,
    Input,
    InputNumber,
    Select
} from "antd";

const MainProjectSubForm = (props) => {
    return (<>
        <h3>Podaci o građevini</h3>
        <Form.Item
            name={["additionalFields", "nazivZahvata"]}
            label={`Naziv zahvata`}
        >
            <Input placeholder="Naziv zahvata" />
        </Form.Item>
        <Form.Item
            name={["additionalFields", "vrijednostInvesticije"]}
            label={`Vrijednost investicije`}
        >
            <InputNumber placeholder="Vrijednost investicije" style={{ width: '100%' }} />
        </Form.Item>
        <Form.Item
            name={["additionalFields", "vlasnikParcele"]}
            label={`Vlasnik parcele`}
        >
            <Input placeholder="Vlasnik parcele" />
        </Form.Item>
        <Form.Item
            name={["additionalFields", "postojiIzgradnjaNaParceli"]}
            label={`Postoji li izgradnja na parceli`}
        >
            <Input placeholder="Postoji li izgradnja na parceli" />
        </Form.Item>
        <Form.Item
            name={["additionalFields", "legalnostPostojecihZgrada"]}
            label={`Legalnost postojećih zgrada`}
        >
            <Input placeholder="Legalnost postojećih zgrada" />
        </Form.Item>
        <Form.Item
            name={["additionalFields", "geodet"]}
            label={`Geodet`}
        >
            <Input placeholder="Geodet" />
        </Form.Item>
        <Form.Item
            name={["additionalFields", "ogradaOkoParcele"]}
            label={`Ograda oko parcele`}
        >
            <Input placeholder="Ograda oko parcele" />
        </Form.Item>
        <Form.Item
            name={["additionalFields", "svijetlaVisinaGradjevine"]}
            label={`Svijetla visina građevine`}
        >
            <Input placeholder="Svijetla visina građevine" />
        </Form.Item>
        <Form.Item
            name={["additionalFields", "razlikaGotovPodTeren"]}
            label={`Razlika gotov pod - teren`}
        >
            <Input placeholder="Razlika gotov pod - teren" />
        </Form.Item>
        <h3>Priključci na komunalnu infrastrukturu</h3>
        <Form.Item
            name={["additionalFields", "strujaPostojecaPrikljucnaSnaga"]}
            label={`Struja (Postojeća priključna snaga)`}
        >
            <Input placeholder="Struja (Postojeća priključna snaga)" />
        </Form.Item>
        <Form.Item
            name={["additionalFields", "strujaNovaPrikljucnaSnaga"]}
            label={`Struja (Nova priključna snaga)`}
        >
            <Input placeholder="Struja (Nova priključna snaga)" />
        </Form.Item>
        <Form.Item
            name={["additionalFields", "voda"]}
            label={`Voda`}
        >
            <Input placeholder="Voda" />
        </Form.Item>
        <Form.Item
            name={["additionalFields", "polozajPostojecegVodomjernogOkna"]}
            label={`Položaj postojećeg vodomjernog okna`}
        >
            <Input placeholder="Položaj postojećeg vodomjernog okna" />
        </Form.Item>
        <Form.Item
            name={["additionalFields", "kanalizacija"]}
            label={`Kanalizacija`}
        >
            <Input placeholder="Kanalizacija" />
        </Form.Item>
        <Form.Item
            name={["additionalFields", "polozajPostojecegKanalizacijskogPriljucka"]}
            label={`Položaj postojećeg kanalizacijskog priključka`}
        >
            <Input placeholder="Položaj postojećeg kanalizacijskog priključka" />
        </Form.Item>
        <Form.Item
            name={["additionalFields", "plin"]}
            label={`Plin`}
        >
            <Input placeholder="Plin" />
        </Form.Item>
        <Form.Item
            name={["additionalFields", "polozajPostojecegPlinskogPrikljucka"]}
            label={`Položaj postojećeg plinskog priključka`}
        >
            <Input placeholder="Položaj postojećeg plinskog priključka" />
        </Form.Item>
        <Form.Item
            name={["additionalFields", "kolniPrilaz"]}
            label={`Kolni prilaz`}
        >
            <Input placeholder="Kolni prilaz" />
        </Form.Item>
        <Form.Item
            name={["additionalFields", "polozajPostojecegKolnogPrilaza"]}
            label={`Položaj postojećeg kolnog prilaza`}
        >
            <Input placeholder="Položaj postojećeg kolnog prilaza" />
        </Form.Item>
        <Form.Item
            name={["additionalFields", "brojParkirnihMjestaNaOtvorenom"]}
            label={`Broj parkirnih mjesta (na otvorenom)`}
        >
            <InputNumber placeholder="Broj parkirnih mjesta (na otvorenom)" style={{ width: '100%' }} />
        </Form.Item>
        <Form.Item
            name={["additionalFields", "brojParkirnihMjestGaraza"]}
            label={`Broj parkirnih mjesta (garaža)`}
        >
            <InputNumber placeholder="Broj parkirnih mjesta (garaža)" style={{ width: '100%' }} />
        </Form.Item>
        <Form.Item
            name={["additionalFields", "grijanje"]}
            label={`Grijanje`}
        >
            <Select
                defaultValue={''}
                options={[{ value: "", label: "Odaberite vrijednost" }].concat(["Podno", "Radijatorsko"]
                    .map((item) => {
                        return { value: item, label: item };
                    }))}
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                    (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
                }
            ></Select>
        </Form.Item>
        <Form.Item
            name={["additionalFields", "ptvRecirkulacija"]}
            label={`PTV (Recirkulacija)`}
        >
            <Select
               defaultValue={''}
               options={[{ value: "", label: "Odaberite vrijednost" }].concat(["DA", "NE"]
                    .map((item) => {
                        return { value: item, label: item };
                    }))}
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                    (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
                }
            ></Select>
        </Form.Item>
        <Form.Item
            name={["additionalFields", "ptvPriprema"]}
            label={`PTV (Priprema tople vode)`}
        >
            <Input placeholder="PTV (Priprema tople vode)" />
        </Form.Item>
        <Form.Item
            name={["additionalFields", "sabirnaJama"]}
            label={`Sabirna jama / akumulacijski sabirnik`}
        >
            <Input placeholder="Sabirna jama / akumulacijski sabirnik" />
        </Form.Item>
        <Form.Item
            name={["additionalFields", "planiraniBrojKorisnika"]}
            label={`Planirani broj korisnika`}
        >
            <InputNumber placeholder="Planirani broj korisnika" style={{ width: '100%' }} />
        </Form.Item>
        <Form.Item
            name={["additionalFields", "polozajPlinskogOrmarica"]}
            label={`Položaj plinskog ormarića`}
        >
            <Input placeholder="Položaj plinskog ormarića" />
        </Form.Item>
        <Form.Item
            name={["additionalFields", "PolozajElektroOrmara"]}
            label={`Položaj elektro ormara`}
        >
            <Input placeholder="Položaj elektro ormara" />
        </Form.Item>
        <Form.Item
            name={["additionalFields", "kolektoriZaPtv"]}
            label={`Kolektori za PTV`}
        >
            <Select
                defaultValue={''}
                options={[{ value: "", label: "Odaberite vrijednost" }].concat(["DA", "NE"]
                    .map((item) => {
                        return { value: item, label: item };
                    }))}
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                    (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
                }
            ></Select>
        </Form.Item>
        <Form.Item
            name={["additionalFields", "fotonaponskaElektrana"]}
            label={`Fotonaponska elektrana`}
        >
            <Select
                defaultValue={''}
                options={[{ value: "", label: "Odaberite vrijednost" }].concat(["DA", "NE"]
                    .map((item) => {
                        return { value: item, label: item };
                    }))}
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                    (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
                }
            ></Select>
        </Form.Item>
        <Form.Item
            name={["additionalFields", "dizalicaTopline"]}
            label={`Dizalica topline`}
        >
            <Select
                defaultValue={''}
                options={[{ value: "", label: "Odaberite vrijednost" }].concat(["DA", "NE"]
                    .map((item) => {
                        return { value: item, label: item };
                    }))}
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                    (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
                }
            ></Select>
        </Form.Item>
        <Form.Item
            name={["additionalFields", "klime"]}
            label={`Klime`}
        >
            <Select
                defaultValue={''}
                options={[{ value: "", label: "Odaberite vrijednost" }].concat(["DA", "NE"]
                    .map((item) => {
                        return { value: item, label: item };
                    }))}
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                    (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
                }
            ></Select>
        </Form.Item>
        <Form.Item
            name={["additionalFields", "uvjetizaNZeb"]}
            label={`Uvjeti za nZeb`}
        >
            <Input placeholder="Uvjeti za nZeb" />
        </Form.Item>
        <h3>Građevni dijelovi</h3>
        <Form.Item
            name={["additionalFields", "negrijaneProstorije"]}
            label={`Negrijane prostorije`}
        >
            <Input placeholder="Negrijane prostorije" />
        </Form.Item>
        <Form.Item
            name={["additionalFields", "pod"]}
            label={`Pod`}
        >
            <Input placeholder="Pod" />
        </Form.Item>
        <Form.Item
            name={["additionalFields", "krov"]}
            label={`Krov`}
        >
            <Input placeholder="Krov" />
        </Form.Item>
        <Form.Item
            name={["additionalFields", "zidovi"]}
            label={`Zidovi`}
        >
            <Input placeholder="Zidovi" />
        </Form.Item>
        <Form.Item
            name={["additionalFields", "medjukatnaKonstrukcija"]}
            label={`Međukatna konstrukcija`}
        >
            <Input placeholder="Međukatna konstrukcija" />
        </Form.Item>
        <Form.Item
            name={["additionalFields", "stolarija"]}
            label={`Stolarija`}
        >
            <Input placeholder="Stolarija" />
        </Form.Item>
        <h3>Ostalo</h3>
        <Form.Item
            name={["additionalFields", "vizalizacija"]}
            label={`Vizualizacija`}
        >
            <Input placeholder="Vizualizacija" />
        </Form.Item>
        <Form.Item
            name={["additionalFields", "troskovnik"]}
            label={`Troškovnik`}
        >
            <Select
                defaultValue={''}
                options={[{ value: "", label: "Odaberite vrijednost" }].concat(["DA", "NE"]
                    .map((item) => {
                        return { value: item, label: item };
                    }))}
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                    (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
                }
            ></Select>
        </Form.Item>
        <Form.Item
            name={["additionalFields", "iznosUTroskovniku"]}
            label={`Iznos u troškovniku`}
        >
            <InputNumber placeholder="Iznos u troškovniku" style={{ width: '100%' }} />
        </Form.Item>
        <Form.Item
            name={["additionalFields", "napomene"]}
            label={`Napomene`}
        >
            <Input placeholder="Napomene" />
        </Form.Item>
    </>)
}

export default MainProjectSubForm;