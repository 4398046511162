import React, { useRef, useEffect } from "react";
import {
  Form,
  Input,
  DatePicker
} from "antd";

const CertificatesSubForm = (props) => {
    return (<>
      <Form.Item
            name={["additionalFields", "datum"]}
            label={`Datum`}
          >
            <Input placeholder="Datum" />
          </Form.Item>
          <Form.Item
            name={["additionalFields", "vrstaObjekta"]}
            label={`Vrsta objekta`}
          >
            <Input placeholder="Vrsta objekta" />
          </Form.Item>
          <Form.Item
            name={["additionalFields", "datumPregleda"]}
            label={`Datum pregleda`}
          >
            <Input placeholder="Datum pregleda" />
          </Form.Item>
          <Form.Item
            name={["additionalFields", "terenObavio"]}
            label={`Teren obavio`}
          >
            <Input placeholder="Teren obavio" />
          </Form.Item>
          <Form.Item
            name={["additionalFields", "poslanoNaIzradu"]}
            label={`Poslano na izradu`}
          >
            <Input placeholder="Poslano na izradu" />
          </Form.Item>
          <Form.Item
            name={["additionalFields", "certifikatIzradio"]}
            label={`Certifikat izradio`}
          >
            <Input placeholder="Certifikat izradio" />
          </Form.Item>
          <Form.Item
            name={["additionalFields", "datumSlanjaPreuzimanja"]}
            label={`Datum slanja / preuzimanja`}
          >
            <Input placeholder="Datum slanja / preuzimanja" />
          </Form.Item>
          <Form.Item
            name={["additionalFields", "svrha"]}
            label={`Svrha`}
          >
            <Input placeholder="Svrha" />
          </Form.Item>
    </>)
}

export default CertificatesSubForm;