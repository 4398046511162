import React, {useEffect} from "react";
import {Table, Empty} from "antd";
import dayjs from "dayjs";
import {tab} from "@testing-library/user-event/dist/tab";

const localeData = require('dayjs/plugin/localeData');
const localizedFormat = require('dayjs/plugin/localizedFormat');

const hr = require('dayjs/locale/hr'); // Import Croatian locale

const EmployeeTimeTrackingTable = ({
                                       tableData,
                                       pageNumber,
                                       pageSize,
                                       totalRows,
                                       onFilterChanged
                                   }) => {
    dayjs.extend(localeData);
    dayjs.extend(localizedFormat);
    dayjs.locale(hr); // Set the locale to Croatian

    const twoPointDecimal = (number) => Number(number).toFixed(2);
    const capitalizeFirstLetter = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    const calculateRowspan = (dataSource, columnName) => {
        const rowSpanData = [];
        let count = 0;
        let lastName = null;

        dataSource.forEach((item, index) => {
            if(item[columnName] === lastName){
                count++;
            } else {
                if(count > 0){
                    rowSpanData.push({index: index - count - 1, count: count + 1});
                }
                lastName = item[columnName];
                count = 0;
            }
        });

        if(count > 0){
            rowSpanData.push({index: dataSource.length - count - 1, count: count + 1});
        }

        return rowSpanData;
    };

    const mergeCells = (data, rowIndex) => {
        let mergeInfo = data.find(item => item.index === rowIndex);
        if(!mergeInfo){
            let lastRowWithCellSpan = data.find(item => item.index < rowIndex);
            if(lastRowWithCellSpan){
                let rowsToDelete = [];
                for(let i = rowIndex; i < lastRowWithCellSpan.index + lastRowWithCellSpan.count; i++){
                    rowsToDelete.push(i);
                }
                return {rowSpan: rowsToDelete.includes(rowIndex) ? 0 : 1}
            } else {
                return {rowSpan: 1};
            }
        } else {
            return {rowSpan: mergeInfo.count}
        }
    }


    const monthRowSpanData = calculateRowspan(tableData, 'month').reverse();
    const projectNameRowSpanData = calculateRowspan(tableData, 'projectName').reverse();
    const investorRowSpanData = calculateRowspan(tableData, 'investor').reverse();

    const tableColumns = () => {
        return [
            {
                title: "Mjesec",
                dataIndex: "month",
                key: "month",
                render: (value => (<>{capitalizeFirstLetter(dayjs().month(value - 1).date(1).format('MMMM'))}</>)),
                onCell: (record, index) => {
                    return mergeCells(monthRowSpanData, index);
                }
            },
            {
                title: "Oznaka posla",
                dataIndex: "projectName",
                key: "projectName",
                render: (text) => text,
                onCell: (record, index) => {
                    return mergeCells(projectNameRowSpanData, index);
                }
            },
            {
                title: "Investitor",
                dataIndex: "investor",
                key: "investor",
                render: (text) => text,
                onCell: (record, index) => {
                    return mergeCells(investorRowSpanData, index);
                }
            },
            {
                title: "Djelatnik",
                dataIndex: "employeeName",
                key: "employeeName",
                render: (text) => (<>{text}</>),

            },
            {
                title: "Cijena radnog sata",
                dataIndex: "hourlyPrice",
                key: "hourlyPrice",
                render: (value => (<>{twoPointDecimal(value)} €</>)),
                sortDirections: ['ascend', 'descend'],
                sorter: true,
            },
            {
                title: "Utrošeni sati",
                dataIndex: "numberOfHours",
                key: "numberOfHours",
                render: (value => (<>{value} h</>)),
                sortDirections: ['ascend', 'descend'],
                sorter: true,
            },
            {
                title: "Ukupno",
                dataIndex: "totalPrice",
                key: "totalPrice",
                render: (value => (<>{twoPointDecimal(value)} €</>)),
                sortDirections: ['ascend', 'descend'],
                sorter: true,
            }
        ];
    };

    return (
        <>
            <Table
                columns={tableColumns()}
                dataSource={tableData}
                onChange={(pagination, filters, sorter) =>
                    onFilterChanged(pagination, filters, sorter)
                }
                style={{marginTop: "32px"}}
                pageNumber={pageNumber}
                pageSize={pageSize}
                pagination={{
                    defaultPageSize: 10,
                    showSizeChanger: true,
                    pageSizeOptions: ['10', '20', '30'],
                    total: totalRows,
                    showTotal: (total, range) => `Ukupan broj rezultata: ${totalRows}`
                }}
                bordered
                scroll={{x: 700}}
                locale={{emptyText: <Empty description="Nije pronađena niti jedna evidencija."/>}}
                summary={(data) => (
                    data && data.length > 0 ?
                        <Table.Summary>
                            <Table.Summary.Row>
                                <Table.Summary.Cell index={0} colSpan={5}>
                                    <b>UKUPNO</b>
                                </Table.Summary.Cell>
                                <Table.Summary.Cell index={5} colSpan={1}>
                                    {data[0]?.totalHoursMonthly + ' h'}
                                </Table.Summary.Cell>
                                <Table.Summary.Cell index={6} colSpan={1}>
                                    {twoPointDecimal(data[0]?.totalMonthly) + ' €'}
                                </Table.Summary.Cell>
                            </Table.Summary.Row>
                        </Table.Summary> : <></>
                )}
            />
        </>
    );
};
export default EmployeeTimeTrackingTable;
