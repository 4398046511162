import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'
import userDataReducer from '../slices/userDataSlice';
import { combineReducers } from "redux";
import {
    persistStore,
    persistReducer,
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
} from "redux-persist"
import storage from "redux-persist/lib/storage"
import {encryptTransform} from "redux-persist-transform-encrypt";

const rootReducer = combineReducers({
    userData: userDataReducer
})

const encryptor = encryptTransform({
    secretKey: '.2buPKnicVVey]5XV58&\'CN2bZr_tp',
    onError: function (error) {
        console.log(error);
    },
})


const persistConfig = {
    key: "root",
    version: 1,
    storage,
    transforms: [encryptor]
}
const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
    reducer: persistedReducer,
    middleware: getDefaultMiddleware=> getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
      }),
})
let persistor = persistStore(store)

export { store, persistor }