import React, { useEffect, useState } from "react";

import {axiosInstance} from "../../api/axiosInstance";

import { toast } from "react-toastify";

import { Button, Form, Flex, theme } from "antd";
import { PlusOutlined } from "@ant-design/icons";

import ModalComponent from "../../components/ModalComponent/ModalComponent";

import "./InvestorAdministrationPage.css";
import InvestorAdministrationFilter from "../../components/Administration/Investors/InvestorAdministrationFilter/InvestorAdministrationFilter";
import InvestorAdministrationTable from "../../components/Administration/Investors/InvestorAdministrationTable/InvestorAdministrationTable";
import InvestorAdministrationForm from "../../components/Administration/Investors/InvestorAdministrationForm/InvestorAdministrationForm";

const InvestorAdministrationPage = (props) => {
  const [mode, setMode] = useState("insert");

  const [filterForm] = Form.useForm();
  const [investorDataForm] = Form.useForm();
  const [rowData, setRowData] = useState(null);

  const [tableData, setTableData] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalRows, setTotalRows] = useState(0);

  const [loading, setLoading] = useState(false);
  const [
    investorAdministrationModalOpened,
    setInvestorAdministrationModalOpened,
  ] = useState(false);

  const { token } = theme.useToken();

  const [initialValues, setInitialValues] = useState({
    address: { city: ''}
  });

  const pageStyle = {
    maxWidth: "none",
    background: token.colorFillAlter,
    borderRadius: token.borderRadiusLG,
    padding: 24,
    height: "100%",
  };

  const fetchInvestors = async (values) => {
    const response = await axiosInstance.get(
      `/api/v1/investors/find-by?searchTerm=${values.searchTerm}&investorSortOrder=${values?.investorSortOrder}&page=${
        pageNumber - 1
      }&size=${pageSize}`
    ).catch(err => {
      console.log('Dogodio se problem kod povlačenja podataka za investitore.');
    });

    if(response){
      setTableData(
        response?.data?.content.map((element) => {
          return {
            ...element,
            key: element.id,
            addressStr: element.address.street
              ? `${element.address.street} ${
                  element.address.houseNumber ? element.address.houseNumber + "," : ""
                } ${
                  element.address.postNumber ? element.address.postNumber : ""
                } ${element.address.city ? element.address.city : ""}`
              : "N/A",
          };
        })
      );
      setTotalRows(response?.data?.totalElements);
    }
  };

  useEffect(() => {
    fetchInvestors({ searchTerm: "" });
  }, [pageNumber, pageSize]);

  const onFormFilterSubmit = async (values) => {
    fetchInvestors(values);
  };

  const onInvestorDataSubmit = (values) => {
    if (mode === "insert") {
      axiosInstance
        .post("/api/v1/investors", values)
        .then((res) => {
          setLoading(false);
          setInvestorAdministrationModalOpened(false);
          investorDataForm.resetFields();
          toast.success("Investitor uspješno spremljen!");
          fetchInvestors({ searchTerm: "" });
        })
        .catch((ex) => {
          toast.error("Došlo je do greške prilikom spremanja investitora!");
        });
    } else if (mode === "edit") {
      axiosInstance
        .patch("/api/v1/investors", values)
        .then((res) => {
          setLoading(false);
          setInvestorAdministrationModalOpened(false);
          investorDataForm.resetFields();
          toast.success("Investitor uspješno ažuriran!");
          fetchInvestors({ searchTerm: "" });
        })
        .catch((ex) => {
          toast.error("Došlo je do greške prilikom ažuriranja investitora!");
        });
    }
  };

  const onFilterChanged = async (pagination, filters, sorter) => {
    setPageNumber(pagination.current);
    setPageSize(pagination.pageSize);

      if (sorter?.columnKey === 'name') {
          fetchInvestors({
              ...filterForm.getFieldsValue(),
              investorSortOrder: sorter.order === 'descend' ? "DESC" : "ASC"
          })
      }

  };

  const handleSubmitInvestor = async () => {
    try {
      const validationResult = await investorDataForm.validateFields();
      if (!validationResult.errorFields) {
        investorDataForm.submit();
        setLoading(true);
      }
    } catch (ex) {
      console.log(ex);
    }
  };

  const investorDataFormTitle = () => {
    if (mode === "insert") {
      return "Novi investitor";
    } else if (mode === "edit") {
      return "Uređivanje investitora";
    } else {
      return "Pregled detalja investitora";
    }
  };

  return (
    <div className={"investor-page"} style={pageStyle}>
      <h3>Administracija investitora</h3>
      <InvestorAdministrationFilter
        form={filterForm}
        onFormFilterSubmit={onFormFilterSubmit}
      ></InvestorAdministrationFilter>
      <Flex vertical={false} justify="right" className="add-investor-wrapper">
        <Button
          type="dashed"
          icon={<PlusOutlined />}
          style={{ marginTop: "32px" }}
          onClick={() => {
            investorDataForm.resetFields();
            setMode("insert");
            setInvestorAdministrationModalOpened(true);
          }}
        >
          Novi investitor
        </Button>
      </Flex>
      <InvestorAdministrationTable
        tableData={tableData}
        onFilterChanged={(pagination, filters, sorter) =>
          onFilterChanged(pagination, filters, sorter)
        }
        setMode={setMode}
        setInvestorAdministrationModalOpened={
          setInvestorAdministrationModalOpened
        }
        setRowData={setRowData}
        style={{ marginTop: "32px" }}
        pageNumber={pageNumber}
        pageSize={pageSize}
        totalRows={totalRows}
        bordered
        refreshData={() => fetchInvestors({ searchTerm: "" })}
      />
      <ModalComponent
        title={investorDataFormTitle()}
        show={investorAdministrationModalOpened}
        onOk={handleSubmitInvestor}
        onCancel={() => {
          setInvestorAdministrationModalOpened(false);
        }}
        loading={loading}
        showFooter={mode !== "preview"}
        width={"580px"}
      >
        <InvestorAdministrationForm
          form={investorDataForm}
          mode={mode}
          onFinish={onInvestorDataSubmit}
          initialValues={mode === "insert" ? initialValues : rowData}
          disabled={mode === "preview"}
        ></InvestorAdministrationForm>
      </ModalComponent>
    </div>
  );
};

export default InvestorAdministrationPage;
