import React from 'react';
import PropTypes from 'prop-types';
import './Avatar.css'; // Import the CSS file for styling

const getInitials = (name) => {
    const initials = name.split(' ').map((word) => word[0]).join('');
    return initials.toUpperCase();
};

const getColor = (name) => {
    // Generate a color based on the user's name
    const colors = [
        '#e57373', '#f06292', '#ba68c8', '#9575cd', '#7986cb',
        '#64b5f6', '#4fc3f7', '#4db6ac', '#81c784', '#aed581',
        '#dce775', '#fff176', '#ffd54f', '#ffb74d', '#ff8a65',
        '#a1887f', '#e0e0e0', '#90a4ae',
    ];
    const hash = name.split('').reduce((acc, char) => char.charCodeAt(0) + acc, 0);
    return colors[hash % colors.length];
};

const Avatar = ({ name }) => {
    const initials = getInitials(name);
    const backgroundColor = getColor(name);

    return (
        <div className="avatar" style={{ backgroundColor }}>
            {initials}
        </div>
    );
};

Avatar.propTypes = {
    name: PropTypes.string.isRequired,
};

export default Avatar;
