import React, { useRef, useEffect } from "react";
import {
  Form,
  Input,
} from "antd";

const ElaboratesSubForm = (props) => {
    return (<>
      <Form.Item
            name={["additionalFields", "svrhaIzrade"]}
            label={`Svrha izrade`}
          >
            <Input placeholder="Svrha izrade" />
          </Form.Item>
    </>)
}

export default ElaboratesSubForm;