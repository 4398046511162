import React, { useRef, useEffect } from "react";
import { Flex, Form, Input, Select, theme, Row, Col } from "antd";

import { isOibValid } from "../../../../utils/validationUtil.js";
import "./UserAdministrationForm.css";
import { cities } from "../../../../constants/constants.js";

const UserAdministrationForm = ({
  form,
  mode,
  initialValues,
  onFinish,
  disabled,
}) => {
  const { token } = theme.useToken();

  useEffect(() => {
    form.setFieldsValue(initialValues);
  }, [initialValues]);

  const firstNameRef = useRef();
  const lastNameRef = useRef();
  const streetRef = useRef();
  const houseNumberRef = useRef();
  const postNumberRef = useRef();
  const cityRef = useRef();
  const usernameRef = useRef();
  const oibRef = useRef();
  const passwordRef = useRef();
  const confirmPasswordRef = useRef();
  const roleRef = useRef();
  const emailRef = useRef();
  const activityRef = useRef();

  // Add refs for other input fields as needed

  const focusNextInput = (ref) => {
    if (ref.current) {
      ref.current.focus();
    }
  };

  const handleKeyDown = (e, ref) => {
    if (e.key === "Tab") {
      e.preventDefault();
      focusNextInput(ref);
    }
  };

  const formStyle = {
    maxWidth: "none",
    background: token.colorFillAlter,
    borderRadius: token.borderRadiusLG,
    padding: 24,
  };

  useEffect(() => {
    if (mode === 'insert') {
      form.resetFields();
    }
  }, [mode]);

  const validateOIB = (rule, value, callback) => {
    if (isOibValid(value)) {
      callback(); // OIB is valid, no error
    } else {
      callback("Neispravan OIB"); // OIB is invalid, provide an error message
    }
  };

  const generateFormRow = (key, leftColumn, rightColumn) => {
    return (<Row gutter={16} key={key}>
      <Col span={12} xs={24} sm={12}>
        {leftColumn}
      </Col>
      <Col span={12} xs={24} sm={12}>
        {rightColumn}
      </Col>
    </Row>
    );
  }

  const firstNameField = (<Form.Item
    name={`firstName`}
    label={`Ime:`}
    rules={[
      {
        required: false,
        message: "Ime je obavezno polje!",
      },
    ]}
  >
    <Input
      placeholder="Ime zaposlenika"
      onKeyDown={(e) => handleKeyDown(e, lastNameRef)}
      ref={firstNameRef}
    />
  </Form.Item>);

  const lastNameField = (<Form.Item
    name={`lastName`}
    label={`Prezime:`}
    rules={[
      {
        required: false,
        message: "Prezime je obavezno polje!",
      },
    ]}
  >
    <Input
      placeholder="Prezime zaposlenika"
      ref={lastNameRef}
      onKeyDown={(e) => handleKeyDown(e, streetRef)}
    />
  </Form.Item>);

  const streetField = (<Form.Item
    name={["address", "street"]}
    label={`Ulica:`}
    rules={[
      {
        required: false,
        message: "Ulica je obavezno polje!",
      },
    ]}
  >
    <Input
      placeholder="Ulica zaposlenika"
      ref={streetRef}
      onKeyDown={(e) => handleKeyDown(e, houseNumberRef)}
    />
  </Form.Item>);

  const houseNumberField = (<Form.Item
    name={["address", "houseNumber"]}
    label={`Kućni broj:`}
    rules={[
      {
        required: false,
        message: "Kućni broj je obavezno polje!",
      },
    ]}
  >
    <Input
      placeholder="Kućni broj zaposlenika"
      ref={houseNumberRef}
      onKeyDown={(e) => handleKeyDown(e, postNumberRef)}
    />
  </Form.Item>);

  const postNumberField = (<Form.Item
    name={["address", "postNumber"]}
    label={`Poštanski broj:`}
    rules={[
      {
        required: false,
        message: "Poštanski broj je obavezno polje!",
      },
    ]}
  >
    <Input
      placeholder="Poštanski broj zaposlenika"
      ref={postNumberRef}
      onKeyDown={(e) => handleKeyDown(e, cityRef)}
    />
  </Form.Item>);

  const cityField = (<Form.Item
    name={["address", "city"]}
    label={`Grad:`}
    rules={[
      {
        required: false,
        message: "Grad je obavezno polje!",
      },
    ]}
  >
    <Select
      options={cities}
      showSearch
      optionFilterProp="children"
      filterOption={(input, option) =>
        (option?.label ?? "")
          .toLowerCase()
          .includes(input.toLowerCase())
      }
      onKeyDown={(e) => handleKeyDown(e, usernameRef)}
    ></Select>
  </Form.Item>);

  const usernameField = (<Form.Item
    hasFeedback={mode === "insert"}
    name={`username`}
    label={`Korisničko ime:`}
    rules={[
      {
        required: true,
        message: "Korisničko ime je obavezno polje!",
      },
    ]}
  >
    <Input
      placeholder="Korisničko ime"
      ref={usernameRef}
      onKeyDown={(e) => handleKeyDown(e, oibRef)}
    />
  </Form.Item>);


  const oibField = (<Form.Item
    hasFeedback={true}
    name={`oib`}
    label={`OIB:`}
    rules={[
      {
        required: true,
        message: "OIB je obavezno polje!",
      },
      {
        validator: validateOIB,
      },
    ]}
  >
    <Input
      placeholder="OIB zaposlenika"
      ref={oibRef}
      onKeyDown={(e) => handleKeyDown(e, passwordRef)}
      maxLength={11}
    />
  </Form.Item>);


  const passwordField = () => {
    if (mode == "insert") {
      return <Form.Item
        name={`password`}
        label={`Lozinka:`}
        rules={[
          {
            required: true,
            message: "Lozinka je obavezno polje!",
          },
        ]}
      >
        <Input.Password
          placeholder="Lozinka"
          ref={passwordRef}
          onKeyDown={(e) => handleKeyDown(e, confirmPasswordRef)}
        />
      </Form.Item>
    } else {
      return null;
    }
  }


  const confirmPasswordField = () => {
    if (mode == "insert") {
      return <Form.Item
        hasFeedback={mode === "insert"}
        name={`confirmPassword`}
        label={`Potvrda lozinke:`}
        dependencies={["password"]}
        rules={[
          {
            required: true,
            message: "Potvrda lozinke je obavezno polje!",
          },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue("password") === value) {
                return Promise.resolve();
              }
              return Promise.reject(new Error("Lozinka se razlikuje!"));
            },
          }),
        ]}
      >
        <Input.Password
          placeholder="Potvrda lozinke"
          ref={confirmPasswordRef}
          onKeyDown={(e) => handleKeyDown(e, roleRef)}
        />
      </Form.Item>
    } else {
      return null;
    }
  }

  const userRoleField = (<Form.Item
    name={["role", "id"]}
    label={`Korisnička rola`}
    rules={[
      {
        required: true,
        message: "Korisnička rola je obavezno polje!",
      },
    ]}
  >
    <Select
      options={[
        { label: "Odaberite korisničku rolu", value: "" },
        { label: "ADMINISTRATOR", value: 1 },
        { label: "ZAPOSLENIK", value: 2 },
      ].map((item) => ({ label: item.label, value: item.value }))}
      ref={roleRef}
      onKeyDown={(e) => handleKeyDown(e, emailRef)}
    ></Select>
  </Form.Item>);


  const emailField = (<Form.Item
    name={`email`}
    label={`E-mail:`}
    rules={[
      {
        required: true,
        message: "E-mail adresa je obavezno polje!",
      },
      {
        type: "email",
        message: "E-mail adresa je neispravna!",
      },
    ]}
  >
    <Input
      placeholder="E-mail adresa zaposlenika"
      ref={emailRef}
      onKeyDown={(e) => handleKeyDown(e, activityRef)}
    />
  </Form.Item>);

  const activityField = (<Form.Item
    name={`active`}
    label={`Aktivnost`}
    rules={[
      mode === "edit"
        ? {
          required: true,
          message: "Aktivnost je obavezno polje!",
        }
        : null,
    ]}
  >
    <Select
      disabled={mode === "insert" || mode === "preview"}
      ref={activityRef}
      onKeyDown={(e) => handleKeyDown(e, firstNameRef)}
      options={[
        { label: "AKTIVAN", value: true },
        { label: "NEAKTIVAN", value: false },
      ].map((item) => ({ label: item.label, value: item.value }))}
    ></Select>
  </Form.Item>);

  const jobTitleField = (<Form.Item
    name={`jobTitle`}
    label={`Radna pozicija`}
    rules={[
      {
        required: false,
        message: "Radna pozicija je obavezno polje!",
      }
    ]}
  >
    <Select
      ref={activityRef}
      onKeyDown={(e) => handleKeyDown(e, firstNameRef)}
      options={[
        { label: "Direktor", value: 'direktor' },
        { label: "Inženjer", value: 'inzenjer' },
        { label: "Uredski administrator", value: 'uredski administrator' },
      ].map((item) => ({ label: item.label, value: item.value }))}
    ></Select>
  </Form.Item>);

  return (
    <Form
      form={form}
      initialValues={initialValues}
      name="userAdministrationForm"
      style={formStyle}
      onFinish={(values) =>
        onFinish({
          ...values,
          id: initialValues?.id,
          address: { ...values.address, id: initialValues?.address?.id },
        })
      }
      layout={"vertical"}
      disabled={disabled}
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
      autoComplete="off"
    >
      {generateFormRow(1, firstNameField, lastNameField)}
      {generateFormRow(2, streetField, houseNumberField)}
      {generateFormRow(3, postNumberField, cityField)}
      {generateFormRow(4, usernameField, oibField)}
      {generateFormRow(5, passwordField(), confirmPasswordField())}
      {generateFormRow(6, userRoleField, jobTitleField)}
      {generateFormRow(7, emailField, activityField)}
    </Form>);
};

export default UserAdministrationForm;
