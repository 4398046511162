import React, {useState} from "react";
import {Button, Dropdown} from "antd";
import {axiosInstance} from "../../api/axiosInstance";
import moment from "moment";
import {navigate} from "react-big-calendar/lib/utils/constants";
import {Views} from "react-big-calendar";
import dayjs from "dayjs";
import {DownOutlined} from "@ant-design/icons";

const CustomToolbar = ({
                           selectedUser,
                           userData,
                           onNavigate,
                           label,
                           date,
                           setShowModal,
                           setMode,
                           setEvent,
                           fetchEventsByRange,
                           view,
                           setView,
                           fetchTimeTable
                       }) => {

    const [open, setOpen] = useState(false);
    const handleOpenChange = (nextOpen, info) => {
        if (info.source === 'trigger' || nextOpen) {
            setOpen(nextOpen);
        }
    };

    const handleNext = () => {
        // Handle the next button click
        onNavigate(navigate.NEXT);
    };

    const handlePrev = () => {
        // Handle the previous button click
        onNavigate(navigate.PREVIOUS);

    };

    const handleToday = () => {
        // Handle the today button click
        onNavigate(navigate.TODAY);
    };

    const handleChangeView = (view) => {

        setView(view);

        if (view === Views.MONTH) {

            var startOfMonth = moment().startOf('month').toDate();
            var endOfMonth = moment().endOf('month').toDate();

            fetchEventsByRange({userId: selectedUser, dateFrom: startOfMonth, dateTo: endOfMonth})
        } else if (view === Views.WEEK) {
            var startOfWeek = moment().startOf('week').toDate();
            var endOfWeek = moment().endOf('week').toDate();

            fetchEventsByRange({userId: selectedUser, dateFrom: startOfWeek, dateTo: endOfWeek})
        } else if (view === "agenda") {
            var startOfMonth = moment().startOf('month').toDate();
            var endOfMonth = moment().endOf('month').toDate();

            fetchEventsByRange({userId: selectedUser, dateFrom: startOfMonth, dateTo: endOfMonth})
        }
    }

    const handleMenuClick = (e) => {
        setOpen(false);
        if (e.key) {
            fetchTimeTable(selectedUser, e.key);
        }
    };


    return (<div className="rbc-toolbar" style={{marginTop: "32px"}}>
         <span className="rbc-btn-group">
            <Button type="primary" onClick={handlePrev} disabled={view === Views.AGENDA}>
              Prošli {view === Views.WEEK ? "tjedan" : "mjesec"}
            </Button>
            <Button type="primary" onClick={handleNext} disabled={view === Views.AGENDA}>
              Sljedeći {view === Views.WEEK ? "tjedan" : "mjesec"}
            </Button>
            <Button type="primary" onClick={handleToday} disabled={view === Views.AGENDA}>
              Danas
            </Button>
        </span>
            <span className="rbc-btn-group">
        <Button type="primary" onClick={() => {
            handleChangeView(Views.WEEK)
        }}>
          Tjedni prikaz
        </Button>
        <Button type="primary" onClick={() => {
            handleChangeView(Views.MONTH)
        }}>
          Mjesečni prikaz
        </Button>
        <Button type="primary" onClick={() => {
            handleChangeView("agenda")
        }}>
          Agenda
        </Button>
    </span>
            <span className="rbc-toolbar-label">{label}</span>
            <Button
                type="primary"
                onClick={() => {
                    setMode('insert')
                    setEvent({
                        id: 1,
                        allDay: true,
                        start: new Date(),
                        end: new Date(),
                        projectId: null,
                        regularHours: 0,
                        totalHours: 0,
                        overtimeHours: 0,
                        description: ''
                    })
                    setShowModal(true);
                }}
            >
                Upiši utrošak
            </Button>
            {userData?.authorities?.includes('ROLE_ADMINISTRATOR') ?
                <Dropdown.Button
                    icon={<DownOutlined/>}
                    menu={{
                        items: [
                            {key: 1, label: "Siječanj"},
                            {key: 2, label: "Veljača"},
                            {key: 3, label: "Ožujak"},
                            {key: 4, label: "Travanj"},
                            {key: 5, label: "Svibanj"},
                            {key: 6, label: "Lipanj"},
                            {key: 7, label: "Srpanj"},
                            {key: 8, label: "Kolovoz"},
                            {key: 9, label: "Rujan"},
                            {key: 10, label: "Listopad"},
                            {key: 11, label: "Studeni"},
                            {key: 12, label: "Prosinac"}
                        ],
                        onClick: handleMenuClick,
                    }}
                    onClick={() => fetchTimeTable(selectedUser, dayjs().month()+1)}
                    style={{marginLeft: '4px', display: "flex", width: "120px"}}
                    onOpenChange={handleOpenChange}
                >
                    Šihterica
                </Dropdown.Button> : <></>
            }
        </div>
    );
};

export default CustomToolbar;
