import React from "react";
import {
    Flex,
    Form,
    Input,
    InputNumber,
    DatePicker,
    Select,
    Space,
    Row,
    Col
} from "antd";
import dayjs from "dayjs";


const GeneralDataForm = ({ userData, mode, form, isMainProjectForm, disabled, formStyle, initialValues, handleFieldsChange, projectSubTypes, owners,
    locations, locationFieldDisabled, investors, companies, companyFieldDisabled, statuses, users }) => {


    // Add refs for other input fields as needed

    const focusNextInput = (ref) => {
        if (ref.current) {
            ref.current.focus();
        }
    };

    const handleKeyDown = (e, ref) => {
        if (e.key === "Tab") {
            e.preventDefault();
            focusNextInput(ref);
        }
    };

    const twoPointDecimal = (number) => Number(number).toFixed(2);

    const generateFormRow = (key, leftColumn, rightColumn) => {
        return (<Row gutter={16} key={key}>
            <Col span={12} xs={24} sm={12}>
                {leftColumn}
            </Col>
            <Col span={12} xs={24} sm={12}>
                {rightColumn}
            </Col>
        </Row>
        );
    }

    const isUserRole = userData?.authorities?.includes('ROLE_ZAPOSLENIK');
    const isAdminRole = userData?.authorities?.includes('ROLE_ADMINISTRATOR');

    const projectSubtypeField = (
        <Form.Item
            name={[`projectSubTypeId`]}
            label={`Podposao:`}
            rules={[
                {
                    required: true,
                    message: "Podposao je obavezno polje!",
                },
            ]}
        >
            <Select options={projectSubTypes} placeholder={"Podposao glavnog posla."} popupMatchSelectWidth={false}
            ></Select>
        </Form.Item>
    );

    const investorField = (<Form.Item
        name={["investor", "id"]}
        label={`Investitor`}
        rules={[
            {
                required: true,
                message: "Investitor je obavezno polje!",
            },
        ]}
    >
        <Select
            options={investors}
            showSearch
            optionFilterProp="children"
            popupMatchSelectWidth={false}
            filterOption={(input, option) =>
                (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }>

        </Select>
    </Form.Item>);

    const ownerField = (<Form.Item
        name={["owner", "id"]}
        label={`Nositelj`}
        rules={[
            {
                required: true,
                message: "Nositelj je obavezno polje!",
            },
        ]}
    >
        <Select options={owners} popupMatchSelectWidth={false}
        ></Select>
    </Form.Item>);

    const companyField = (<Form.Item
        name={["company", "id"]}
        label={`Tvrtka`}
        rules={[
            {
                required: true,
                message: "Tvrtka je obavezno polje!",
            },
        ]}
    >
        <Select
            options={companies}
            disabled={companyFieldDisabled}
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) =>
                (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
        ></Select>
    </Form.Item>);

    const locationField = (
        <Form.Item
            name={["location", "id"]}
            label={`Katastarska čestica i općina`}
            rules={[
                {
                    required: true,
                    message: "Katastarska čestica je obavezno polje!",
                },
            ]}
        >
            <Select
                options={locations}
                disabled={locationFieldDisabled}
                showSearch
                optionFilterProp="children"
                popupMatchSelectWidth={false}
                filterOption={(input, option) =>
                    (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
                }
                onChange={(value, option) =>
                {
                    //form.setFieldValue(["location", "cadastralMunicipality"], option?.cadastralMunicipality)
                }}
            >
            </Select>
        </Form.Item>
    );

    /*const locationInfoField = (
        <Form.Item
            name={["location", "cadastralMunicipality"]}
            label={`Katastarska općina`}
            rules={[
                {
                    required: false,
                    message: "Katastarska općina je obavezno polje!",
                },
            ]}
        >
            <Input
                disabled={true}
                >
            </Input>
        </Form.Item>
    );*/

    const statusField = (<Form.Item
        name={["status", "id"]}
        label={`Status posla`}
        rules={[
            {
                required: mode === "insert" ? false : true,
                message: "Status posla je obavezno polje!",
            },
        ]}
    >
        <Select options={statuses} popupMatchSelectWidth={false} disabled={mode === 'insert' || mode === 'preview'}
        ></Select>
    </Form.Item>);

    const projectNameField = (<Form.Item
        name={`name`}
        label={`Oznaka posla:`}
        rules={[
            {
                required: true,
                message: "Oznaka posla je obavezno polje!",
            },
        ]}
    >
        <Input placeholder="Oznaka posla" />
    </Form.Item>);

    const proposedDeadlineField = (<Form.Item
        name={"proposedDeadline"}
        label={`Predloženi rok:`}
        rules={[
            {
                required: false,
                message: "Predloženi rok je obavezno polje!",
            },
        ]}
    >
        <DatePicker
            style={{ width: "100%" }}
            getValueProps={(e) => ({
                value: e ? dayjs(e).add(1, "hour") : "",
            })}
            placeholder="Odaberite datum"
            format={"DD.MM.YYYY."}
            disabledTime={true}
            onChange={(value) => {
                form.setFieldsValue({ proposedDeadline: value });
            }}
        />
    </Form.Item>);

    const projectPriceField = (isAdminRole ? <Form.Item
        name={`price`}
        label={`Cijena:`}
        rules={[
            {
                required: false,
                message: "Cijena je obavezno polje!",
            },
        ]}
    >
        <InputNumber
            onChange={(value) => {
                form.setFieldsValue({
                    price: twoPointDecimal(value),
                });
            }}
            precision={2}
            step={0.1}
            style={{ width: "100%" }}
            placeholder="Cijena"
        />
    </Form.Item> : <></>
    );

    const remarkField = (<Form.Item
        name={`remark`}
        label={`Napomena:`}
        rules={[
            {
                required: false,
                message: "Napomena je obavezno polje!",
            },
        ]}
    >
        <Input placeholder="Napomena" />
    </Form.Item>);

    return (
        <Form
            form={form}
            name="generalDataForm"
            style={formStyle}
            onFinish={(values) => { }}
            layout={"vertical"}
            disabled={disabled}
            initialValues={initialValues}
            onClick={(e) => e.stopPropagation()}
            onValuesChange={(changedValues, allValues) => {
                handleFieldsChange('generalDataForm', changedValues, allValues);
            }}
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            autoComplete="off"
        >
            {generateFormRow(1, projectSubtypeField, investorField)}
            {generateFormRow(2, ownerField, companyField)}
            {generateFormRow(3, locationField, statusField)}
            {generateFormRow(4, projectNameField, proposedDeadlineField)}
            {generateFormRow(5, projectPriceField, remarkField)}

            {!isMainProjectForm ?
                <Flex vertical={false} span={24}>
                    <Form.Item
                        name={"usersOnProject"}
                        label={"Zaposlenici"}
                        style={{ width: "100%" }}
                        rules={[
                            {
                                required: false,
                                message: "Zaposlenici je obavezno polje!",
                            },
                        ]}
                    >
                        <Select
                            mode="multiple"
                            allowClear
                            style={{
                                width: "100%",
                            }}
                            placeholder="Dodajte zaposlenika"
                            onChange={(value) => {
                                if (value && value.includes(-1)) {
                                    const allUsers = [...users].filter(x => x.value !== -1).map(item => item.value);
                                    form.setFieldsValue({ usersOnProject: allUsers });
                                } else {
                                    form.setFieldsValue({ usersOnProject: value });
                                }
                            }}
                            optionLabelProp="label"
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                                (option?.label ?? "").includes(input)
                            }
                            options={users}
                            optionRender={(option) => <Space>{option.data.label}</Space>}
                        />
                    </Form.Item>
                </Flex>
                : <></>}
        </Form>
    );
}

export default GeneralDataForm;