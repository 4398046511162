import React, { useEffect } from "react";
import { Table } from "antd";

const InvestorContactTable = ({ investorContactTableData }) => {
  return (
    <span onClick={(e) => { e.preventDefault(); e.stopPropagation() }}  >
      <Table
        bordered
        style={{
          wordBreak: "break-all",
          width: "100%",
          tableLayout: "fixed",
        }}
        pagination={false}
        columns={[
          {
            title: "Naziv",
            dataIndex: "name",
          },
          {
            title: "OIB",
            dataIndex: "oib",
          },
          {
            title: "Adresa",
            dataIndex: "address",
            render: (value) => {
              return (<>{value.street} {value.houseNumber} {value.postNumber} {value.city}</>);
            }
          },
          {
            title: "Telefon",
            dataIndex: "phoneNumber",
          },
          {
            title: "Email",
            dataIndex: "email",
          },
        ]}
        dataSource={investorContactTableData}
        size="small"
        rowKey="id"
        scroll={{x: 700}}
      />
    </span>
  );
}

export default InvestorContactTable;