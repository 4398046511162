import React, {useEffect, useState} from 'react';
import {Button, Form, Input, List, Card, Dropdown, Tag} from 'antd';
import {StarOutlined, StarFilled, MessageOutlined, MoreOutlined} from '@ant-design/icons';
import {Comment} from '@ant-design/compatible';
import Avatar from "../../Avatar/Avatar";
import dayjs from "dayjs";
import {axiosInstance} from "../../../api/axiosInstance";
import {useSelector} from "react-redux";
import './PostWithComments.css'
import {useDispatch} from "react-redux";
import {setUnreadPostCount} from "../../../slices/userDataSlice";

const {TextArea} = Input;

const PostWithComments = ({
                              likeableId,
                              likeType,
                              post,
                              comments,
                              setPosts,
                              userId,
                              message,
                              username,
                              numberOfComments,
                              createdAt,
                              fetchComments,
                              handlePostDelete,
                              previewMode,
                              expandComments = false,
                              selectedProject,
                          }) => {
    const [submitting, setSubmitting] = useState(false);
    const [value, setValue] = useState('');
    const [postLiked, setPostLiked] = useState(false);
    const [commentsLiked, setCommentsLiked] = useState(new Map());
    const [showComments, setShowComments] = useState(expandComments);
    const [showAddComment, setShowAddComment] = useState(false);

    const [commentCount, setCommentCount] = useState(numberOfComments);
    const dispatch = useDispatch();

    const userData = useSelector((state) => state.userData);


    useEffect(() => {
        setPostLiked(post.liked);
    }, [post])


    useEffect(() => {
        const map = new Map();
        comments.forEach(comment => {
            map.set(comment.id, comment.liked);
        })
        setCommentsLiked(map);
    }, [comments])

    //useEffect(() => {
    //    setCommentCount(numberOfComments);
    //    fetchComments(post.id);
    //}, [numberOfComments])

    const handleSubmit = async () => {
        if (!value) return;
        setSubmitting(true);
        axiosInstance.post("/api/v1/noticeboard/comment", {postId: post.id, message: value})
            .then(async res => {
                fetchComments(post.id);
                setCommentCount(commentCount + 1);
                setValue("");
            })
        setSubmitting(false);
    };

    const handleLike = (objType, commentId) => {
        if (objType === 'post') {
            setPostLiked(!postLiked);
            if (!postLiked) {
                axiosInstance.post("/api/v1/task", {likeableId: post.id, likeType: 'OBJAVA'})
            } else {
                axiosInstance.patch("/api/v1/task", {likeableId: post.id, likeType: 'OBJAVA'})
            }
            makeSeen(true)
        } else if (objType === 'comment') {
            const commentLiked = commentsLiked.get(commentId);
            if (!commentLiked) {
                axiosInstance.post("/api/v1/task", {likeableId: commentId, likeType: 'KOMENTAR'})
            } else {
                axiosInstance.patch("/api/v1/task", {likeableId: commentId, likeType: 'KOMENTAR'})
            }
            toggleMapCopy(commentId)
        }
    };

    const makeSeen = (isLiked = false) => {
        let seenByArr = post.seenBy?.split(", ");
        if (!seenByArr) {
            seenByArr = [];
        }
        if (seenByArr && !seenByArr.includes(userData.firstAndLastName)) {
            axiosInstance.post(`/api/v1/noticeboard/seen/${post.id}`)
                .then(res => {
                        setPosts((prevValue) => {
                            return prevValue.map((item, itemIndex) => {
                                if (item.id === post.id) {
                                    let newValue = item.seenBy ? item.seenBy + ", " + userData.firstAndLastName : userData.firstAndLastName;
                                    return {...item, seenBy: newValue, seen: true, liked: isLiked};
                                } else {
                                    return item;
                                }
                            })
                        })
                        //fetchPosts(post?.project?.id);
                        if (userData.unreadPostIds.includes(post.id)) {
                            dispatch(setUnreadPostCount({
                                unreadPostCount: userData.unreadPostCount - 1,
                                unreadPostIds: userData.unreadPostIds.filter(x => x !== post.id)
                            }));
                            console.log(userData.unreadPostCount - 1, 'userData.unreadPostCount - 1')
                        }
                    }
                )
        }
    }

    const handleShowCommentsClicked = async () => {
        setShowAddComment(false);
        if (!showComments) {
            fetchComments(post.id);
        }
        setShowComments(!showComments);
        if (showAddComment) {
            setShowAddComment(false);
            setShowComments(true);
        }
        makeSeen();
    }

    const handleAddCommentClicked = async () => {
        if (!showComments) {
            setShowAddComment(true);
            fetchComments(post.id);
        }
        setShowComments(!showComments);
        if (!showAddComment) {
            setShowAddComment(true);
            setShowComments(true);
        }
        makeSeen();
    }
    const toggleMapCopy = (name) => {
        const mapCopy = new Map(commentsLiked);
        const oldValue = mapCopy.get(name);
        if (!oldValue) {
            mapCopy.set(name, true);
        } else {
            mapCopy.set(name, !oldValue);
        }
        setCommentsLiked(mapCopy);
    }


    const items = [
        {
            label: <div onClick={() => {
                handlePostDelete(post.id)
            }}>Obriši objavu</div>,
            key: '0',
        },
    ];

    const getCommentCountTitle = (commentCount) => {

        if (commentCount === undefined)
            return showComments ? "Sakrij odgovore" : "Prikaži odgovore";

        if (commentCount === 0) {
            return `${commentCount} Odgovora`
        } else if (commentCount === 1) {
            return `${commentCount} Odgovor`
        } else {
            return `${commentCount} Odgovora`
        }
    }

    const getPostAction = (actionName, postLiked) => {
        if (previewMode) {
            return <></>
        }

        if (actionName === 'setPostAsTask') {
            if (postLiked) {
                return <span onClick={() => handleLike("post")}>Ukloni zadatak<StarFilled
                    style={{marginLeft: '8px'}}/></span>;
            } else {
                return <span onClick={() => handleLike("post")}>Postavi kao zadatak<StarOutlined
                    style={{marginLeft: '8px'}}/></span>;
            }
        }
        if (actionName === 'comments') {
            return <span onClick={() => handleAddCommentClicked()}>Komentari<MessageOutlined
                style={{marginLeft: '8px'}}/></span>;
        }
    }

    const getCommentActions = (actionName, commentLiked, commentId) => {
        if (previewMode) {
            return [];
        }

        if (actionName === 'setCommentAsTask') {
            if (commentLiked) {
                return [<span onClick={() => handleLike("comment", commentId)}>Ukloni zadatak<StarFilled
                    style={{marginLeft: '4px'}}/></span>];
            } else {
                return [<span
                    onClick={() => handleLike("comment", commentId)}>Postavi kao zadatak<StarOutlined
                    style={{marginLeft: '4px'}}/></span>];
            }
        }
    }

    const renderProjectNameTag = (post) => {
        if(selectedProject === -1){
            if(post?.project?.name){
                return (<Tag color={"blue"} style={{margin: 0}}>{post.project.name} - {post?.project?.investor?.name}</Tag>)
            } else {
                if(previewMode){
                    return <></>
                }
                return (<Tag color={"yellow"} style={{margin: 0}}>Javno</Tag>)
            }
        } else {
            return <></>
        }
    }


    return (
        <Card
            title={""}
            className={"post-with-comments"}
            key={post.id}
            actions={[
                !previewMode ? getPostAction('setPostAsTask', postLiked) : null,
                <span onClick={async () => {
                    handleShowCommentsClicked()
                }}>{getCommentCountTitle(commentCount)}</span>,
                !previewMode ? getPostAction('comments') : null,
            ]}
            size={"small"}
        >
            <Card.Meta
                avatar={<Avatar name={username}/>}
                title={
                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                    }}>
                        <div className={"card-name-date-wrapper"}
                             style={{
                                 flexGrow: 1,
                                 display: 'flex',
                                 flexDirection: 'row',
                                 justifyContent: 'space-between',
                             }}>
                            <div>
                                <div>
                                    {username}
                                </div>
                            </div>
                            <div style={{
                                fontSize: '12px',
                                color: 'lightgray',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'space-between',
                                gap: '8px',
                                alignItems: 'flex-end',
                                backgroundColor: 'red,'
                            }}>
                                <div className={"post-with-comments-date"}>
                                    {dayjs(createdAt).format("ddd DD. MMMM YYYY. u HH:mm") + " h"}
                                </div>
                                <div style={{display: 'flex', flexDirection: 'row', gap: '4px'}}
                                     className={"post-with-comments-tags"}>
                                    <div>
                                        {renderProjectNameTag(post)}
                                    </div>
                                    {!post.seen ?
                                        <div>
                                            <Tag color="error" style={{margin: 0}}>Nepročitano</Tag>
                                        </div> : <></>
                                    }
                                </div>
                            </div>

                        </div>
                        <div>
                            {userData.userId === userId && !previewMode ?
                                <div style={{color: 'gray'}}>
                                    <Dropdown
                                        menu={{
                                            items,
                                        }}
                                        trigger={['click']}
                                    >
                                        <MoreOutlined/>
                                    </Dropdown>

                                </div> : <></>}
                        </div>
                    </div>
                }
                description={<p style={{color: 'black'}}>{message}</p>}
            />
            {showComments ?
                <div>
                    {!previewMode && showAddComment ?

                        <>
                            <Comment
                                avatar={<Avatar name={userData?.firstAndLastName}/>}
                                content={
                                    <Form.Item>
                                        <TextArea rows={4} onChange={(e) => setValue(e.target.value)} value={value}/>
                                    </Form.Item>
                                }
                                datetime={dayjs(new Date()).format("DD.MM.YYYY. HH:mm:ss")}
                            />
                            <Form.Item style={{display: 'flex', justifyContent: 'flex-end'}}>
                                <Button htmlType="submit" loading={submitting} onClick={handleSubmit} type="primary">
                                    Dodaj komentar
                                </Button>
                            </Form.Item>
                        </>
                        : <></>
                    }
                    <List
                        dataSource={comments}
                        header={
                            <div style={{display: 'flex', justifyContent: 'flex-start'}}>
                                <p style={{fontStyle: 'italic', margin: '0px'}}>Objavu vidjeli: {post?.seenBy}</p>
                            </div>
                        }
                        itemLayout="horizontal"
                        renderItem={(props) =>
                            <Comment
                                style={{
                                    border: likeableId === props?.id && likeType === 'KOMENTAR' ? "1px dashed" : "none",
                                    padding: likeableId === props?.id && likeType === 'KOMENTAR' ? "8px" : "",
                                    borderColor: likeableId === props?.id && likeType === 'KOMENTAR' ? 'red' : "",
                                    borderRadius: likeableId === props?.id && likeType === 'KOMENTAR' ? "12px" : ""
                                }}
                                key={props.id}
                                author={props?.user?.nameStr}
                                avatar={<Avatar name={props?.user?.nameStr}/>}
                                content={props.message}
                                datetime={dayjs(props.tstamp).format("DD.MM.YYYY. HH:mm:ss")}
                                actions={!previewMode ? getCommentActions('setCommentAsTask', !!commentsLiked.get(props.id), props.id) : null}
                            />
                        }
                    />
                </div> : <></>
            }
        </Card>
    );
};

export default PostWithComments;
