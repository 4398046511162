import React, { useRef, useEffect } from "react";
import {
  Form,
  Input,
} from "antd";

const ProfessionalSupervisionSubForm = (props) => {
    return (<>
      <Form.Item
            name={["additionalFields", "sudionikUGradnji"]}
            label={`Sudionik u gradnji`}
          >
            <Input placeholder="Sudionik u gradnji" />
          </Form.Item>
          <Form.Item
            name={["additionalFields", "vrstaRadova"]}
            label={`Vrsta radova`}
          >
            <Input placeholder="Vrsta radova" />
          </Form.Item>
          <Form.Item
            name={["additionalFields", "kontaktImePrezime"]}
            label={`Kontakt - Ime i prezime`}
          >
            <Input placeholder="Ime i prezime" />
          </Form.Item>
          <Form.Item
            name={["additionalFields", "kontaktSvojstvo"]}
            label={`Kontakt - Svojstvo`}
          >
            <Input placeholder="Svojstvo" />
          </Form.Item>
          <Form.Item
            name={["additionalFields", "kontaktBrTel"]}
            label={`Kontakt - Broj telefona:`}
          >
            <Input placeholder="Broj telefona" />
          </Form.Item>
          <Form.Item
            name={["additionalFields", "kontaktMail"]}
            label={`Kontakt - Mail adresa:`}
          >
            <Input placeholder="Mail adresa" />
          </Form.Item>
    </>)
}

export default ProfessionalSupervisionSubForm;